import {
  IonCard,
  IonCardHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonButtons,
  IonIcon,
  IonModal,
  IonText,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { RearPendulum, RearPendulumModalGraph } from '../Graphs';
import { informationCircleOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { ORYXCardHeader, ORYXModalHeader, ORYXSmallCardHeader } from '../../../../components/componentHeaders';
import { ZoomModalRearPendulum } from '../ModalsRun/ZoomModalRearPendulum';
import { ModalHipExtensionTerminalStance } from '../ModalsWalk';
import { SwiperSlide } from 'swiper/react';
import { ORYXVerticalSwiper } from '../../../../components/swiper';

interface ORYXGraphCardProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  zoom: boolean;
  info: boolean;
  zoomModalContent?: any;
  infoModalContent?: React.ReactNode;
}

export const ORYXGraphCard = ({
  title,
  subtitle,
  children,
  zoomModalContent,
  zoom,
  info,
  infoModalContent,
}: ORYXGraphCardProps) => {
  console.log('zoomModalContent', zoomModalContent);
  const [showZoomModal, setShowZoomModal] = useState({
    show: false,
  });
  const [showInfoModal, setShowInfoModal] = useState({
    show: false,
  });
  console.log('title: ', title);
  const cleanUp = () => {
    setShowZoomModal({ show: false });
  };

  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <>
      <IonModal
        isOpen={showZoomModal.show}
        className='ORYX_Modal_Large'
        onDidDismiss={() => setShowZoomModal({ show: false })}>
        {title === 'Pendulum Left' && <ZoomModalRearPendulum setShowModal={setShowZoomModal} data={zoomModalContent} />}
        {title === 'Pendulum Right' && (
          <ZoomModalRearPendulum setShowModal={setShowZoomModal} data={zoomModalContent} />
        )}
      </IonModal>
      <IonModal
        isOpen={showInfoModal.show}
        className='ORYX_Modal_Large'
        onDidDismiss={() => setShowInfoModal({ show: false })}>
        {infoModalContent}
      </IonModal>

      <IonCard className='ORYX_GraphCard'>
        <ORYXSmallCardHeader
          title={title}
          subtitle={subtitle}
          info={info}
          zoom={zoom}
          setShowZoom={setShowZoomModal}
          setShowInfo={setShowInfoModal}
        />
        <IonCardContent className='' style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
          {children}
        </IonCardContent>
      </IonCard>
    </>
  );
};
