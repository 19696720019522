import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import { colors } from '../CustomGraphComponents';
import { title } from 'process';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';

type JointCouplingGraphProps = {
  data: any;
  side: string;
};

export const WalkJointCouplingGraph = ({ data, side }: JointCouplingGraphProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);
  const markerLabels = ['HO', 'TO', 'MSw', 'TSw', 'IC', 'MSt'];
  // create marker array so that only the scatter has markers
  const markerSizes = [];
  const markerColors = [];
  for (const value of Object.values(data)) {
    const Value = value as unknown as any;
    const type = Value.type;
    if (type === 'scatter') {
      markerSizes.push(3);
      markerColors.push('#E81A1A');
    } else {
      markerSizes.push(0);
      markerColors.push('#E81A1A');
    }
  }

  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [data.length - 1],
      formatter: function (val: number, { seriesIndex, dataPointIndex, w }) {
        return markerLabels[dataPointIndex];
      },
      textAnchor: 'middle',
      offsetY: -5,
      background: {
        enabled: false,
      },
      style: {
        fontSize: '8px',
        fontFamily: 'Montserrat',
        colors: ['#707070'],
      },
    },
    xaxis: {
      range: 60,
      max: 40,
      tickAmount: 6,
      labels: {
        formatter: function (value: string) {
          const Value = value as unknown as number;
          return Value.toFixed(0);
        },
        show: true,
        style: {
          fontSize: '8px',
        },
        offsetY: -5,
      },
      title: {
        text: 'Hip Angle (deg)',
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
      min: -20,
      max: 80,
      tickAmount: 6,
      forceNiceScale: true,
      title: {
        text: 'Knee Angle (deg)',
        style: {
          fontSize: '8px',
        },
        offsetX: 5,
      },
    },
    title: {
      text: `${side}`,
      align: 'center',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: '#2066FA',
      },
    },
    stroke: {
      width: 1,
    },
    colors: colors,
    markers: {
      size: markerSizes,
      colors: markerColors,
      strokeWidth: 1,
      showNullDataPoints: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className='full_height' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      <Chart options={options} series={data} type='line' width='100%' height='100%' />
    </div>
  );
};
