/** Squat analysis report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonCardContent,
  IonThumbnail,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { SquatReportData } from '../../../model';

import singleLegSquatAvatar from '../../../assets/avatars/squat/singleLegSquatAvatar.png';
import pelvisSagittalMovement from '../../../assets/icons/kinematicsIcons/pelvisSagittalMovement.png';
import hipSagittalMovement from '../../../assets/icons/kinematicsIcons/hipSagittalMovement.png';
import hipTransversalMovement from '../../../assets/icons/kinematicsIcons/hipTransversalMovement.png';
import hipCoronalMovement from '../../../assets/icons/kinematicsIcons/hipCoronalMovement.png';
import kneeSagittalMovement from '../../../assets/icons/kinematicsIcons/kneeSagittalMovement.png';
import kneeCoronalMovement from '../../../assets/icons/kinematicsIcons/kneeCoronalMovement.png';
import ankleSagittalMovement from '../../../assets/icons/kinematicsIcons/ankleSagittalMovement.png';
import './SingleLegSquatsAnalysisReportTab.css';
import { transpose, transposeToStatusDot } from '../../../hooks/useReports';
import { StatusDot_Centered } from '../components/Fields';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';

type SLSquatReportProps = RouteComponentProps & {
  report: SquatReportData;
};

const varusValgusLabels = [['Ok', 'Fair', 'Poor', 'No Varus/Valgus']];

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const SingleLegSquatAnalysisReportTab = ({ report }: SLSquatReportProps) => {
  const data = report;
  const repTime_Left = data?.reportId.repetitionTime_SLS.col0[0].toFixed(2);
  const stdRepTime_Left = data?.reportId.repetitionTime_SLS.col0[1].toFixed(2);
  const repTime_Right = data?.reportId.repetitionTime_SLS.col1[0].toFixed(2);
  const stdRepTime_Right = data?.reportId.repetitionTime_SLS.col1[1].toFixed(2);
  const romData: number[][] = transpose(data?.reportId.rom_Squats);
  const qualityData: number[][] = transposeToStatusDot(data?.reportId.keyPerfomanceIndicators_SLS);
  function Label(labels: string[], kpi: number) {
    return labels[kpi - 1];
  }

  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = React.useState('small');
  const [tableSize, setTableSize] = useState('');

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
        setTableSize('ORYX_Table_Large');
      } else {
        setWindowSize('small');
        setTableSize('ORYX_Table_Small');
      }
    }
  }, [width]);
  if (process.env.NODE_ENV === 'development' && width && height) {
    console.log('screen ratio: ', width / height);
  }

  const gradeLabel = (col: any) => {
    if (col[4] === 1) {
      return 'Excellent';
    } else if (col[4] === 2) {
      document.getElementById('showQualityLabel')?.classList.add('warning');
      return 'Good';
    } else if (col[4] === 3) {
      document.getElementById('showQualityLabel')?.classList.add('warning');
      return 'Fair';
    } else {
      document.getElementById('showQualityLabel')?.classList.add('danger');
      return 'Poor';
    }
  };

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid className=''>
            <IonRow className='ion-align-items-center ion-justify-content-center remaining_height'>
              <IonCard className='ORYX_DashboardCard'>
                <IonRow className='border_Bottom ion-padding-vertical'>
                  <IonCol size='6' className='border_Right'>
                    <IonRow className='ion-justify-content-center'>
                      <IonText className='ORYX_GraphTitle'>Left Leg</IonText>
                    </IonRow>
                    <IonRow className='ion-justify-content-center'>
                      <IonCol size='6' className='ion-text-center'>
                        <b>Rep Time:</b> {repTime_Left} (± {stdRepTime_Left}) sec.
                      </IonCol>
                      <IonCol size='6' className='ion-text-center'>
                        <b>Q-Score:</b>{' '}
                        <span className='QualityLabel' id='showQualityLabel'>
                          {gradeLabel(data?.reportId.repetitionTime_SLS.col0)}
                        </span>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol size='6' className='ion-align-self-center'>
                    <IonRow className='ion-justify-content-center'>
                      <IonText className='ORYX_GraphTitle'>Right Leg</IonText>
                    </IonRow>
                    <IonRow className='ion-justify-content-center'>
                      <IonCol size='6' className='ion-text-center'>
                        <b>Rep Time:</b> {repTime_Right} (± {stdRepTime_Right}) sec.
                      </IonCol>
                      <IonCol size='6' className='ion-text-center'>
                        <b>Q-Score:</b>{' '}
                        <span className='QualityLabel' id='showQualityLabel'>
                          {gradeLabel(data?.reportId.repetitionTime_SLS.col1)}
                        </span>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonCardContent className='content-container'>
                  <IonRow className='ion-align-items-center ion-justify-content-center'>
                    <IonCol size='5' className=''>
                      <IonImg
                        src={singleLegSquatAvatar}
                        class='SquatsAnalysisReportTab_Avatar'
                        style={{ width: windowSize === 'large' ? '50%' : '50%' }}
                      />
                    </IonCol>
                    <IonCol size='7' className='ion-no-padding'>
                      <table className={tableSize} style={{ margin: 0 }}>
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th>Left</th>
                            <th>Right</th>
                            <th>Symmetry</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='ORYX_Table_Head'>
                            <td> Pelvis</td>
                            <td>
                              {romData[0][0].toFixed(1) + '°'}
                              <br /> ± {romData[0][1].toFixed(1) + '°'}
                            </td>
                            <td>
                              {romData[0][2].toFixed(1) + '°'}
                              <br /> ± {romData[0][3].toFixed(1) + '°'}
                            </td>
                            <td>{romData[0][4].toFixed(0) + '%'}</td>
                            <td>
                              <IonThumbnail
                                className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}>
                                <img alt='Pelvis Sagittal Movement' src={pelvisSagittalMovement} />
                              </IonThumbnail>
                            </td>
                          </tr>
                          <tr className='ORYX_Table_Head'>
                            <td> Hip</td>
                            <td>
                              {romData[1][0].toFixed(1) + '°'}
                              <br /> ± {romData[1][1].toFixed(1) + '°'}
                            </td>
                            <td>
                              {romData[1][2].toFixed(1) + '°'}
                              <br /> ± {romData[1][3].toFixed(1) + '°'}
                            </td>
                            <td>{romData[1][4].toFixed(0) + '%'}</td>
                            <td>
                              <IonThumbnail
                                className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}>
                                <img alt='Hip Sagittal Movement' src={hipSagittalMovement} />
                              </IonThumbnail>
                            </td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Large_Indent'>External rotation</td>
                            <td>
                              <StatusDot_Centered status={qualityData[2][0]} />
                            </td>
                            <td>
                              <StatusDot_Centered status={qualityData[2][1]} />
                            </td>
                            <td className='No_Border_Bottom'></td>
                            <td rowSpan={2}>
                              <IonThumbnail
                                className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}>
                                <img alt='Hip Transversal Movement' src={hipTransversalMovement} />
                              </IonThumbnail>
                            </td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Large_Indent'>Internal rotation</td>
                            <td className='ion-text-center'>
                              <StatusDot_Centered status={qualityData[3][0]} />
                            </td>
                            <td>
                              <StatusDot_Centered status={qualityData[3][1]} />
                            </td>
                            <td className='No_Border_Top'></td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Large_Indent'>Abduction</td>
                            <td>
                              <StatusDot_Centered status={qualityData[4][0]} />
                            </td>
                            <td>
                              <StatusDot_Centered status={qualityData[4][1]} />
                            </td>
                            <td className='No_Border_Bottom'></td>
                            <td rowSpan={2}>
                              <IonThumbnail
                                className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}>
                                <img alt='Hip Transversal Movement' src={hipCoronalMovement} />
                              </IonThumbnail>
                            </td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Large_Indent'>Adduction</td>
                            <td className='ion-text-center'>
                              <StatusDot_Centered status={qualityData[5][0]} />
                            </td>
                            <td>
                              <StatusDot_Centered status={qualityData[5][1]} />
                            </td>
                            <td className='No_Border_Top'></td>
                          </tr>

                          <tr className='ORYX_Table_Head'>
                            <td>Knee</td>
                            <td>
                              {romData[2][0].toFixed(1) + '°'}
                              <br /> ± {romData[2][1].toFixed(1) + '°'}
                            </td>
                            <td>
                              {romData[2][2].toFixed(1) + '°'}
                              <br /> ± {romData[2][3].toFixed(1) + '°'}
                            </td>
                            <td>{romData[2][4].toFixed(0) + '%'}</td>
                            <td>
                              <IonThumbnail
                                className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}>
                                <img alt='Knee Sagittal Movement' src={kneeSagittalMovement} />
                              </IonThumbnail>
                            </td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Large_Indent No_Border_Bottom'>Varus / Valgus</td>
                            <td className='No_Border_Bottom'>
                              <StatusDot_Centered status={qualityData[6][0]} />
                            </td>
                            <td className='No_Border_Bottom'>
                              <StatusDot_Centered status={qualityData[6][1]} />
                            </td>
                            <td className='No_Border_Bottom'></td>
                            <td rowSpan={2}>
                              <IonThumbnail
                                className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}>
                                <img alt='Knee Coronal Movement' src={kneeCoronalMovement} />
                              </IonThumbnail>
                            </td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Large_Indent No_Border_Top'></td>
                            <td className='No_Border_Top'>
                              {Label(varusValgusLabels[0], data?.reportId.keyPerfomanceIndicators_SLS.col0[7])}
                            </td>
                            <td className='No_Border_Top'>
                              {Label(varusValgusLabels[0], data?.reportId.keyPerfomanceIndicators_SLS.col1[7])}
                            </td>

                            <td className='No_Border_Top'></td>
                          </tr>
                          <tr className='ORYX_Table_Head'>
                            <td>Ankle</td>
                            <td>
                              {romData[3][0].toFixed(1) + '°'}
                              <br /> ± {romData[3][1].toFixed(1) + '°'}
                            </td>
                            <td>
                              {romData[3][2].toFixed(1) + '°'}
                              <br /> ± {romData[3][3].toFixed(1) + '°'}
                            </td>
                            <td>{romData[3][4].toFixed(0) + '%'}</td>
                            <td rowSpan={2}>
                              <IonThumbnail
                                className={windowSize === 'Large' ? 'ion-thumbnail-medium' : 'ion-thumbnail-small'}>
                                <img alt='Ankle Sagittal Movement' src={ankleSagittalMovement} />
                              </IonThumbnail>
                            </td>
                          </tr>
                          <tr>
                            <td className='ORYX_Table_Large_Indent'>Early heel raise</td>
                            <td>
                              <StatusDot_Centered status={qualityData[0][0]} />
                            </td>
                            <td>
                              <StatusDot_Centered status={qualityData[0][1]} />
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
