import React from 'react';
import { IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react';
import './modalindex.css';
import IBimagesSwinglegrecovery1 from './assets/IBimagesSwinglegrecovery1.png';
import IBimagesSwinglegrecovery2 from './assets/IBimagesSwinglegrecovery2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import '@ionic/react/css/ionic-swiper.css';

export const ModalSwingLegRecovery: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Swing-leg recovery</IonCardTitle>
        </div>
      </IonCardHeader>

      <Swiper modules={[Pagination]} pagination={true} initialSlide={0} speed={400} direction='vertical'>
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimagesSwinglegrecovery1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  After toe off, the leg should be brought in front of the body as fast as possible to ensure sufficient
                  time for it to prepare for landing.
                  <br></br>
                  <br></br>A linear deployment of the rear pendulum by simultaneous hip and knee flexion is an important
                  pre-condition for fast leg recovery
                  <br></br>
                  <br></br>
                  If executed this way, the knee will already be traveling forward with increasing velocity (&#120011;
                  <sub>𝑥</sub>) while the foot’s altitude increases.
                  <br></br>
                  <br></br>
                  This action is closely connected with the leg in front. Together they form a scissors movement. The
                  quality of scissors and swing-leg retraction primarily depend on how the recovery of the rear leg is
                  performed.
                  <br></br>
                  <br></br>
                  In fact this factor is dominant; the leg in front ‘listens’ to the rear leg and responds to it. A
                  rapid swing-leg recovery produces a rapid swing-leg retraction.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimagesSwinglegrecovery2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  If deployment of the recovery is too ‘round’ (also see: knee extension at pre-swing), the knee will
                  already be bent quite far before hip flexion starts.
                  <br></br>
                  <br></br>
                  This will result in a poor knee velocity, delaying the recovery. As a result the leg in front will
                  have to ‘wait’ on the rear leg to pull up and will be forced to land passively and softly. A lot of
                  energy which normally would be released will be lost and there will be no leg stiffness, compromising
                  foot plant and projection.
                  <br></br>
                  <br></br>
                  The ankle remaining in a plantar flexion for a long period of time after toe off, can typically be
                  observed when the recovery is executed roundly. A fast return to neutral position seems to have a good
                  synergy with a linear execution of swing leg recovery.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
