import React from 'react';
import { IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react';
import './modalindex.css';
import IBimageFootplantprojection1 from './assets/IBimageFootplantprojection1.png';
import IBimageFootplantprojection2 from './assets/IBimageFootplantprojection2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import '@ionic/react/css/ionic-swiper.css';

export const ModalProjectionFootplant: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Footplant projection</IonCardTitle>
        </div>
      </IonCardHeader>

      <Swiper modules={[Pagination]} pagination={true} initialSlide={0} speed={400} direction='vertical'>
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageFootplantprojection1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  At midstance the body must respond to the maximum amount of forces it is exposed to throughout the
                  gait cycle. The best way to deal with these forces, is when the leg is directly under the body (or
                  slightly behind);
                  <br></br>
                  <br></br>
                  The resultant vector of the leg position and ground reaction force (GRF) point in the same direction
                  of the leg, optimizing the conditions for generating maximum forward propulsion force.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageFootplantprojection2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  A forward pointing leg at midstance has some far-reaching consequences. In this scenario the hip is
                  not extending. This means that the <i>m. gluteus maximus</i> is inactive. Without this anchor there
                  will not be enough tension in the knees or hips and load on these joints will increase.
                  <br></br>
                  <br></br>
                  In addition the resultant vector of the leg position and GRF will point forward. This results in a
                  breaking effect.
                  <br></br>
                  <br></br>
                  The combination of less protective tension and greater impinging force inevitably leads to less
                  control, loss of performance and increased risk of injury.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
