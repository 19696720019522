/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 */
import {
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonToolbar,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonImg,
  IonToggle,
  IonSpinner,
  IonText,
  IonModal,
  IonHeader,
  IonButton,
  IonIcon,
  IonItem,
  IonList,
} from '@ionic/react';
import * as React from 'react';
import { ORYXHeader } from '../../components/menuItems/Header';
import Sensor from '../../assets/sensor.svg';
import './index.css';
import { useState } from 'react';
import { closeCircleOutline, powerOutline, searchOutline } from 'ionicons/icons';

const SensorCard_Idle = () => {
  const [checked, setChecked] = useState(false);
  return (
    <IonCol size='12'>
      <IonCard className=''>
        <IonCardContent className='sensorCard_Content'>
          <IonRow>
            <IonCol size='3' className='ion-align-items-center'>
              <IonImg src={Sensor} alt='' className='sensorCard_Image_Idle' />
            </IonCol>
            <IonCol size='6' className='ion-align-items-center ion-align-self-center'>
              <IonRow className=''>
                <IonText className='sensorCard_Title'>Macadres</IonText>
              </IonRow>
              <IonRow className='sensorCard_Subtitle'>
                <IonText>Serial Number</IonText>
              </IonRow>
            </IonCol>
            <IonCol size='3' className='ion-align-items-center ion-align-self-center'>
              <IonToggle checked={checked} className='sensorCard_Connect_Toggle' />
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};

const SensorCard = () => {
  const [checked, setChecked] = useState(true);
  const [showSensorInfoModal, setShowSensorInfoModal] = useState(false);
  return (
    <>
      <IonModal isOpen={showSensorInfoModal} id='gatewayCard-modal' onDidDismiss={() => setShowSensorInfoModal(false)}>
        <IonHeader class='ion-no-border'>
          <IonTitle className='gatewayCard-modal-header'>Sensor Information</IonTitle>
        </IonHeader>
        <IonContent className='userInfo-modal-content' scrollEvents={false} scrollY={false}>
          <IonGrid fixed>
            <IonRow className='ion-align-items-center ion-justify-content-center userInfo-modal-row'>
              <IonCol size='12'>
                <IonList lines='none' mode='ios'>
                  <IonItem className='sensorInfo-modal-listItem'>
                    <IonCol size='10'>Hardware version</IonCol>
                    <IonCol size='2' className='align_center ion-align-items-center ion-align-self-center'>
                      v2.0
                    </IonCol>
                  </IonItem>
                  <IonItem className='sensorInfo-modal-listItem'>
                    <IonCol size='10'>Firmware version</IonCol>
                    <IonCol size='2' className='align_center ion-align-items-center ion-align-self-center'>
                      2.2.1
                    </IonCol>
                  </IonItem>
                  <IonItem className='sensorInfo-modal-listItem'>
                    <IonCol size='10'>Battery</IonCol>
                    <IonCol size='2' className='align_center ion-align-items-center ion-align-self-center'>
                      20%
                    </IonCol>
                  </IonItem>
                  <IonItem className='sensorInfo-modal-listItem'>
                    <IonCol size='10'>Identify</IonCol>
                    <IonCol
                      size='2'
                      className='align_center sensorInfo_Modal_Icon ion-align-items-center ion-align-self-center'>
                      <IonIcon src={searchOutline} color='primary' />
                    </IonCol>
                  </IonItem>
                  <IonItem className='sensorInfo-modal-listItem'>
                    <IonCol size='10'>Power off</IonCol>
                    <IonCol
                      size='2'
                      className='align_center sensorInfo_Modal_Icon ion-align-items-center ion-align-self-center'>
                      <IonIcon src={powerOutline} color='primary' />
                    </IonCol>
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonButton
          slot='icon-only'
          onClick={() => setShowSensorInfoModal(false)}
          fill='clear'
          className='gatewayCard-Modal-close'>
          <IonIcon icon={closeCircleOutline} />
        </IonButton>
      </IonModal>

      <IonCol size='12'>
        <IonCard className='' button onClick={() => setShowSensorInfoModal(true)}>
          <IonCardContent className='sensorCard_Content'>
            <IonRow>
              <IonCol size='3' className='ion-align-items-center'>
                <IonImg src={Sensor} alt='' className='sensorCard_Image' />
              </IonCol>
              <IonCol size='6' className='ion-align-items-center ion-align-self-center'>
                <IonRow className=''>
                  <IonText className='sensorCard_Title'>Macadres</IonText>
                </IonRow>
                <IonRow className='sensorCard_Subtitle'>
                  <IonText>Serial Number</IonText>
                </IonRow>
              </IonCol>
              <IonCol size='3' className='ion-align-items-center ion-align-self-center'>
                <IonToggle checked={checked} className='sensorCard_Connect_Toggle' />
              </IonCol>
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonCol>
    </>
  );
};

export const DeviceManagementPage = () => {
  const [scanning, setScanning] = useState(false);

  return (
    <IonPage>
      <ORYXHeader />
      <IonContent className='ion-padding' id='main' color='light'>
        <IonGrid fixed>
          <IonRow>
            <IonCol size='12' class='ion-justify-content-center' className='dashboard_Title'>
              <IonTitle>Device Management Page</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow className='ion-align-items-center ion-justify-content-center'>
            <IonCol size='6'>
              <IonCard className='deviceManagement_Card'>
                <IonCardHeader className='deviceManagement_Card_Header'>
                  <IonCardTitle className='cardTitle'>Sensors</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonRow className='Test deviceManagement_Card_Container ion-align-items-center ion-justify-content-center'>
                    {scanning ? (
                      <IonSpinner />
                    ) : (
                      <>
                        <SensorCard_Idle />
                        <SensorCard />
                      </>
                    )}
                  </IonRow>
                </IonCardContent>
                <IonToolbar className='ion-align-items-center ion-justify-content-center'>
                  <IonSegment mode='md' className='deviceManagement_Card_Segment'>
                    <IonSegmentButton value='mfm' className='segment-button'>
                      <IonLabel className='ion-text-wrap'>Magnetic Field Mapper</IonLabel>
                    </IonSegmentButton>
                    <span className='deviceManagement_Card_Segment_Separator'></span>
                    <IonSegmentButton value='firmware' className='segment-button'>
                      <IonLabel className='ion-text-wrap'>Firmware update</IonLabel>
                    </IonSegmentButton>
                    <span className='deviceManagement_Card_Segment_Separator'></span>
                    <IonSegmentButton value='settings' className='segment-button'>
                      <IonLabel className='ion-text-wrap'>Settings</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </IonToolbar>
              </IonCard>
            </IonCol>

            <IonCol size='6'>
              <IonCard className='deviceManagement_Card'>
                <IonCardHeader className='deviceManagement_Card_Header'>
                  <IonCardTitle className='cardTitle'>Gateways</IonCardTitle>
                </IonCardHeader>
                <IonCardContent></IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
