import {
  IonModal,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButtons,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from '@ionic/react';
import { closeCircleOutline, informationCircleOutline } from 'ionicons/icons';
import { useState } from 'react';

import './cards.css';
import { ModalMobilityStatus } from '../ModalsWalk';
import { ORYXCardHeader } from '../../../../components/componentHeaders';

type DashboardCardProps = {
  title?: string;
  subtitle?: string;
  info?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

/** Card in the analysis page to show the avatar and attractor field
 * @param img - The avatar image
 * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
 */
export const DashboardCard = ({ title, subtitle, info, children, className }: DashboardCardProps) => {
  const [showModal, setShowModal] = useState({
    show: false,
  });
  const showHeader = title || subtitle || info;

  return (
    <>
      <IonModal isOpen={showModal.show} className='ORYX_Modal_Large' onDidDismiss={() => setShowModal({ show: false })}>
        {info === 'Mobility Status' && <ModalMobilityStatus buttons={true} setShowModal={setShowModal} />}
      </IonModal>

      <IonCard className='ORYX_DashboardCard'>
        {showHeader && <ORYXCardHeader title={title} subtitle={subtitle} setShowInfo={setShowModal} />}

        <IonCardContent className='full_height ion-no-padding'>
          <IonGrid class='' fixed>
            {children}
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};
