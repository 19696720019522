/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Homepage.
 */

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import { barChartOutline, homeOutline, informationCircleOutline } from 'ionicons/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ORYXHeader } from '../../components/menuItems/Header';
import './index.css';
import Walking from '../../assets/icons/Walking.png';
import Running from '../../assets/icons/Running.png';
import Squats from '../../assets/icons/Squat.png';
import SingleLegSquat from '../../assets/icons/SingleLegSquat.png';
import { useHardwareContext } from '../../components/hardwareContext';
import { get } from 'http';
import { useAuthContext } from '../../components/authContext';

interface Test {
  id: number;
  Name: string;
  Icon: string;
}

interface Measurements {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  status: string;
  availableOn: string;
  Tests: Test[];
}

const availableTests: Measurements[] = [
  {
    title: 'ORYX Movement Analysis',
    url: '/measurements/ORYX/',
    iosIcon: homeOutline,
    mdIcon: homeOutline,
    status: 'active',
    availableOn: 'capacitor',
    Tests: [
      {
        id: 1,
        Name: 'Walking',
        Icon: Walking,
      },
      {
        id: 2,
        Name: 'Running',
        Icon: Running,
      },
      {
        id: 3,
        Name: 'Squat',
        Icon: Squats,
      },
      {
        id: 4,
        Name: 'Single Leg Squat',
        Icon: SingleLegSquat,
      },
    ],
  },
  {
    title: 'Cycling',
    url: '',
    iosIcon: barChartOutline,
    mdIcon: barChartOutline,
    status: 'active',
    availableOn: 'capacitor',
    Tests: [],
  },
];

type RouterInfoObject = {
  name: string;
  hardwareId: string;
};

export const MeasurementsPage: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [present] = useIonAlert();
  const platform = isPlatform('capacitor') ? 'capacitor' : 'web';
  const userId = useAuthContext().state.userState?.user.uid;

  // Check for hardware in the database & use this to redirect directly to the /:hardwareId page if only 1 hardware is available
  const [availableNetworks, setAvailableNetworks] = useState<RouterInfoObject[]>([]);
  const [routerURL, setRouterURL] = useState<string>('');
  // const [loading, setLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const { ORYXGOCollection, isLoading, isError, errorMessage, clearError, getAvailableORYXGOSystems } =
    useHardwareContext();

  useEffect(() => {
    if (userId) getAvailableORYXGOSystems(userId);
  }, []);

  useEffect(() => {
    if (isError) {
      present({
        header: 'Error',
        message: errorMessage,
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              clearError();
            },
          },
        ],
      });
    }
  }, [isError]);

  useEffect(() => {
    setTimeout(() => {
      if (ORYXGOCollection) {
        console.log('ORYXGOCollection useEffect: ');
        Object.entries(ORYXGOCollection).forEach(([key, value]) => {
          console.log('key: ', key, 'value: ', value);
          setAvailableNetworks((prevValue) => [...prevValue, { hardwareId: key, name: '' }]);
        });
      }
    }, 2000);
  }, [isLoading]);

  useEffect(() => {
    if (availableNetworks.length !== 0) {
      if (availableNetworks.length === 1) {
        console.log('Available networks === 1');
        setRouterURL(`/measurements/ORYX/${availableNetworks[0].hardwareId}`);
      } else {
        console.log('Available networks !== 1');
        setRouterURL('/measurements/ORYX/selectNetwork');
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [availableNetworks]);

  //   console.log('hardwareContext: ', Object.entries(hardwareContext.ORYXGOCollection));
  //   Object.entries(hardwareContext.ORYXGOCollection).forEach(([key, value]) => {
  //     setAvailableNetworks((prevValue) => [...prevValue, { hardwareId: key, name: '' }]);
  //   });
  //   setTimeout(() => {
  //     console.log('availableNetworks: ', availableNetworks);
  //     if (availableNetworks.length === 1) {
  //       setRouterURL(`/measurements/ORYX/${availableNetworks[0].hardwareId}`);
  //     } else {
  //       setRouterURL('/measurements/ORYX/selectNetwork');
  //     }
  //     setLoading(false);
  //   }, 1000);
  // }, [hardwareContext]);

  return (
    <IonPage id='main'>
      <ORYXHeader backTarget='/dashboard' />
      <IonContent className='ion-padding' id='main' fullscreen color='light'>
        <IonGrid>
          <IonRow>
            {availableTests.map((Measurement, _index) => {
              return (
                <>
                  <IonCol class='ion-padding' size='6'>
                    <IonModal
                      key={`modal_${Measurement.title}`}
                      isOpen={showModal}
                      onDidDismiss={() => setShowModal(false)}>
                      <p>Under construction, coming soon...</p>
                      <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                    </IonModal>

                    <IonCard key={Measurement.title} className='measurementCard'>
                      <IonCardHeader className='attractorCard_Header' mode='md'>
                        <IonGrid fixed className='ion-no-padding'>
                          <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
                            <IonCol size='10' className=''>
                              <IonCardTitle className='attractorCard_Title'>{Measurement.title}</IonCardTitle>
                            </IonCol>
                            <IonCol size='2' className='ion-justify-content-center'>
                              <IonButtons className='ion-justify-content-center'>
                                <IonButton
                                  slot='icon-only'
                                  fill='clear'
                                  className='attractorCard_Header_Buttons'
                                  onClick={() => setShowModal(true)}>
                                  <IonIcon icon={informationCircleOutline} />
                                </IonButton>
                              </IonButtons>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCardHeader>
                      {isLoading || loading ? (
                        <IonCardContent className='loading_Content'>
                          <IonRow className='ion-justify-content-center ion-align-items-center'>
                            <IonCol size='12' className='ion-justify-content-center ion-align-items-center'>
                              <IonSpinner color='primary' name='bubbles' />
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      ) : (
                        <IonCardContent
                          className='loaded_Content'
                          onClick={() => {
                            //for dev
                            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                              if (Measurement.title === 'ORYX Movement Analysis') {
                                history.push(routerURL, { from: '/measurements' });
                                return;
                              } else {
                                history.push(Measurement.url);
                                return;
                              }
                              // history.push(routerURL);
                              // return;
                            }

                            // if (platform != Measurement.availableOn) {
                            //   present({
                            //     cssClass: '',
                            //     header: 'Not available',
                            //     message: 'This test is only available on an iPad',
                            //     buttons: [{ text: 'Ok' }],
                            //   });
                            // } else {
                            if (Measurement.title === 'ORYX Movement Analysis') {
                              history.push(routerURL, { from: '/measurements' });
                            }
                            // }
                          }}>
                          {Measurement.Tests.map((Test) => (
                            <IonItem lines='none' detail={false} key={Test.Name}>
                              <IonAvatar slot='start'>
                                <img src={Test.Icon} alt={Test.Name} />
                              </IonAvatar>
                              <IonLabel>{Test.Name}</IonLabel>
                            </IonItem>
                          ))}
                        </IonCardContent>
                      )}
                    </IonCard>
                  </IonCol>
                </>
              );
            })}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
