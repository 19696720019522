/** Kinematic data upload page */
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToast,
  useIonAlert,
} from '@ionic/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../components/authContext';
import { ErrorToast } from '../components/ErrorToast';
import { FileInput } from '../components/FileInput';
import { ORYXHeader } from '../components/menuItems/Header';
import { FileData, reportOption, useFirebaseUpload } from '../hooks/useFirebaseUpload';
import { informationCircleOutline } from 'ionicons/icons';
import { title } from 'process';
import { UploadArea } from '../components/FileInput/uploadArea';

/** Kinematic data upload page
 * Using the useFirebaseUpload hook to handle the actual upload to cloud storage
 */
export const UploadPage: React.FC = () => {
  // setting up the hook to upload file and track its progress
  const { dataResponse, isLoading, isError, errorMessage, progress, setUploadData, clearError } = useFirebaseUpload();
  const user = useAuthContext().state.userState?.user;
  const [userConfirmedReportType, setUserConfirmedReportType] = useState<reportOption>('undefined');
  const [isTouched, setIsTouched] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [detectedOption, setDetectedOption] = useState('');

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [option, setOption] = useState<reportOption>('undefined');

  const [tags, setTags] = useState('');

  const [file, setFile] = useState<FileData>();
  const markTouched = () => {
    setIsTouched(true);
  };

  function handleReportType(reportType: reportOption, file: any) {
    setOption(reportType);
    setFile(file);
    setDetectedOption(reportType);
    setDisabled(false);
  }

  function handleUndefined(reportType: reportOption, file: any) {
    setOption(reportType);
    setFile(file);
  }

  const handleFileSelect = (e: any) => {
    const startIndex = e.target.files[0].name.indexOf('- ') + 2;
    const endIndex = e.target.files[0].name.indexOf('.csv');
    const indexIndex = e.target.files[0].name.indexOf(e.target.files[0].name.match(/\(+\d+\)+/g));

    if (indexIndex < 0) {
      const reportType = e.target.files[0].name.substring(startIndex, endIndex);

      if (reportType === 'Walk') handleReportType(reportType, e.target.files[0]);
      else if (reportType === 'Run') handleReportType(reportType, e.target.files[0]);
      else if (reportType === 'Squat') handleReportType(reportType, e.target.files[0]);
      else if (reportType === 'Single Leg Squat') handleReportType('SLSquat', e.target.files[0]);
      else handleUndefined('undefined', e.target.files[0]);

      // setOption(e.target.files[0].name.substring(startIndex, endIndex));
    } else {
      const reportType = e.target.files[0].name.substring(startIndex, indexIndex);

      if (reportType === 'Walk') handleReportType(reportType, e.target.files[0]);
      else if (reportType === 'Run') handleReportType(reportType, e.target.files[0]);
      else if (reportType === 'Squat') handleReportType(reportType, e.target.files[0]);
      else if (reportType === 'Single Leg Squat') handleReportType('SLSquat', e.target.files[0]);
      else handleUndefined('undefined', e.target.files[0]);
      // setOption(e.target.files[0].name.substring(startIndex, indexIndex));
    }
    // e.target.files.length > 0 && setFile(e.target.files[0]);
  };

  const handleTagsSelect = (e: any) => {
    setTags(e.detail.value);
  };

  const handleOptionChange = (ev: Event) => {
    setUserConfirmedReportType((ev.target as HTMLInputElement).value as reportOption);
    setOption((ev.target as HTMLInputElement).value as reportOption);
    setDisabled(false);
  };

  const handleFileRemove = () => {
    setFile(undefined);
    setOption('undefined');
    setDisabled(true);
  };

  // The actual file upload handler once you click Submit in the form
  const upload = async (e: React.FormEvent) => {
    e.preventDefault();

    setFormSubmitted(true);

    if (!!user && !!file) {
      const user_id = user.uid;

      // Perform the actual file upload using the hook
      await setUploadData({ user_id, tags, option, file });
      setFile(undefined);
      setOption('undefined');
      setUserConfirmedReportType('undefined');
      setDisabled(true);
    }
  };

  return (
    <IonPage>
      <ORYXHeader backTarget='/reports' loading={isLoading && !!progress} progress={progress?.value} />

      <IonContent className='ion-padding' id='main' color='light'>
        <IonToast
          isOpen={dataResponse ? true : false}
          message={dataResponse && 'Kinematics uploaded to the portal'}
          position='bottom'
          color='success'
          duration={2500}
        />

        <form noValidate onSubmit={upload}>
          <IonGrid fixed className=''>
            <IonRow className='ion-padding-vertical border_Bottom'>
              <IonCol size='12'>
                <IonText className='ion-text-center'>
                  <h4>Data upload</h4>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className='ion-padding-top ion-margin-top'>
              <IonCol size='6'>
                <IonRow>
                  <IonCol size='12'>
                    <UploadArea onChange={handleFileSelect} onFileRemove={handleFileRemove} />
                    {!formSubmitted && file === undefined && !disabled && (
                      <IonText>
                        <p className='ion-padding-start errorText ion-text-center'>Data file is required</p>
                      </IonText>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow className=''>
                  <IonList lines='none' mode='ios' className='tagsList'>
                    <IonItem className={`create_ListItem ${isTouched && 'ion-touched'}`}>
                      <IonLabel className='create_Label'>Tags</IonLabel>
                      <IonInput
                        className='create_ListInput'
                        name='tags'
                        type='text'
                        value={tags}
                        spellCheck={false}
                        autocapitalize='off'
                        onIonChange={handleTagsSelect}
                        onIonBlur={() => markTouched()}></IonInput>

                      {/* <IonNote mode='ios' slot='end'>
                        <p>Separate multiple tags by a comma.</p>
                      </IonNote> */}
                    </IonItem>
                    <IonText className='ion-text-center'>
                      <p>Separate multiple tags by a comma.</p>
                    </IonText>
                  </IonList>
                </IonRow>
              </IonCol>
              {file && file !== undefined && (
                <IonCol size='6'>
                  {option === 'undefined' && (
                    <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-vertical'>
                      <IonText className='ion-text-center'>
                        <p>
                          We could not detect your reporttype. Please select the correct report type from the list
                          below.
                        </p>
                      </IonText>
                    </IonRow>
                  )}
                  {option !== 'undefined' && (
                    <>
                      <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-vertical'>
                        <IonText className='ion-text-center'>
                          <p>
                            We have detected that you are uploading a{' '}
                            <strong> {detectedOption ? detectedOption : option} </strong> report. Is this correct?
                          </p>
                        </IonText>
                      </IonRow>
                      <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-vertical'>
                        <IonText className='ion-text-center'>
                          <p>If not please select the correct report type from the list below.</p>
                        </IonText>
                      </IonRow>
                    </>
                  )}
                  <IonRow className='ion-justify-content-center ion-margin-vertical'>
                    <IonList lines='none'>
                      <IonItem>
                        <IonRadioGroup
                          value={userConfirmedReportType != 'undefined' ? userConfirmedReportType : option}
                          onIonChange={handleOptionChange}>
                          <IonItem>
                            <IonLabel>Walk</IonLabel>
                            <IonRadio slot='end' value='Walk'></IonRadio>
                          </IonItem>
                          <IonItem>
                            <IonLabel>Run</IonLabel>
                            <IonRadio slot='end' value='Run'></IonRadio>
                          </IonItem>
                          <IonItem>
                            <IonLabel>Double leg squat</IonLabel>
                            <IonRadio slot='end' value='Squat'></IonRadio>
                          </IonItem>
                          <IonItem>
                            <IonLabel>Single leg squat</IonLabel>
                            <IonRadio slot='end' value='SLSquat'></IonRadio>
                          </IonItem>
                        </IonRadioGroup>
                      </IonItem>
                    </IonList>
                  </IonRow>
                </IonCol>
              )}
            </IonRow>

            <IonRow className='ion-justify-content-center'>
              <IonCol size='6'>
                <IonButton type='submit' expand='block' className='primary' disabled={disabled}>
                  Submit
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>

        {/* Debugging: show metadata from the upload
    <pre style={{ fontSize: "smaller" }}>
      {JSON.stringify(dataResponse, null, 2)}
    </pre>
    */}
      </IonContent>
      <ErrorToast message={isError ? errorMessage : ''} clearError={() => clearError()} />
    </IonPage>
  );
};
