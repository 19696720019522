import {
  IonCardHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardSubtitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';

interface ORYXModalHeaderProps {
  title: string;
  subtitle?: string;
  setShowInfo?: () => void;
  buttons?: boolean;
}

export const ORYXModalHeader = ({ title, subtitle, setShowInfo, buttons }: ORYXModalHeaderProps) => {
  return (
    <IonCardHeader className='ORYX_Modal_Header'>
      <IonGrid className='ion-no-padding' style={{ width: '100%' }}>
        <IonRow className='ion-no-padding ion-align-items-center ORYX_Modal_Header_Row'>
          <IonCol size='11' className='ion-padding-start'>
            <IonCardTitle className='ORYX_Modal_Title'>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className='dashboardCard_Subtitle'>{subtitle}</IonCardSubtitle>}
          </IonCol>
          {buttons && setShowInfo && (
            <IonCol size='1' className='ion-justify-content-center'>
              <IonButtons className='ion-justify-content-end ion-padding-end'>
                <IonButton
                  slot='icon-only'
                  fill='clear'
                  className='ORYX_Modal_Header_Buttons'
                  onClick={() => setShowInfo()}>
                  <IonIcon icon={closeCircleOutline} />
                </IonButton>
              </IonButtons>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonCardHeader>
  );
};
