/** Walk analysis report tab */
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, IonText } from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import hipExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalStance.png';
import hipExtensionAtTerminalSwing from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalSwing.png';

import kneeFlexionAtMidstance from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidstance.png';
import kneeFlexionAtMidswing from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidswing.png';
import kneeExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/KneeExtensionAtTerminalStance.png';
import kneeStabilityAtLoadingResponse from '../../../assets/avatars/walk/analysis/KneeStabilityAtLoadingResponse.png';
import pelvicObliquity from '../../../assets/avatars/walk/analysis/PelvicObliquity.png';

import varusValgus from '../../../assets/avatars/walk/analysis/VarusValgus.png';
import { transpose, toMatrix } from '../../../hooks/useReports';
import { WalkReportData } from '../../../model';
import { StatusDotField } from '../components/Fields';

import './WalkAnalysisReportTab.css';
import {
  ModalHipExtensionTerminalStance,
  ModalKneeFlexionMidstance,
  ModalKneeFlexionMidswing,
  ModalKneeStabilityLoadingResponse,
  ModalPelvicObliquity,
  ModalVarusValgus,
} from '../components/ModalsWalk';

import { ModalHipExtensionTerminalSwing } from '../components/ModalsWalk/ModalHipExtensionTerminalSwing';
import { ModalKneeExtensionTerminalStance } from '../components/ModalsWalk/ModalKneeExtensionTerminalStance';

import { AttractorCard } from '../../ReportV2/components/Cards';

const kpiLabels = [
  ['Ok', 'Fair', 'Poor'],
  ['Hike', 'Ok', 'Drop'],
  ['Ok', 'Fair', 'Poor', 'No extension'],
];

type WalkReportProps = RouteComponentProps & {
  report: WalkReportData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const WalkAnalysisReportTab = ({ report }: WalkReportProps) => {
  const data = report;

  const romData: number[][] = transpose(data?.reportId.rom);
  const kpiData = transpose(data?.reportId.keyPerformanceIndicators);
  const tableData: number[][] = toMatrix(data?.reportId.spatioTemporal);
  const varusValgusLabel_Left = romData[6][0] < 0 ? 'Valgus' : 'Varus';
  const varusValgusLabel_Right = romData[6][1] < 0 ? 'Valgus' : 'Varus';

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip extension'
                  subtitle='Terminal stance'
                  img={hipExtensionAtTerminalStance}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[1]}
                  info={'Hip extension at terminal stance'}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee extension'
                  subtitle='Terminal stance'
                  img={kneeExtensionAtTerminalStance}
                  kpiLabels={kpiLabels[2]}
                  attractorRow={kpiData[0]}
                  info={'Knee extension at terminal stance'}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midswing'
                  img={kneeFlexionAtMidswing}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[6]}
                  info={'Knee flexion at midswing'}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip extension'
                  subtitle='Terminal swing'
                  img={hipExtensionAtTerminalSwing}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[2]}
                  info={'Hip extension at terminal swing'}
                  type='Walk'
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee stability'
                  subtitle='Loading response'
                  img={kneeStabilityAtLoadingResponse}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[3]}
                  info={'Knee stability at loading response'}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midstance'
                  img={kneeFlexionAtMidstance}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[5]}
                  info={'Knee flexion at midstance'}
                  type='Walk'
                />
              </IonCol>

              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Varus / Valgus'
                  subtitle='Midstance'
                  img={varusValgus}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[7]}
                  info={'Varus Valgus'}
                  varusValgus={[varusValgusLabel_Left, varusValgusLabel_Right]}
                  type='Walk'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Pelvic obliquity'
                  subtitle='Midstance'
                  img={pelvicObliquity}
                  kpiLabels={kpiLabels[1]}
                  attractorRow={kpiData[4]}
                  info={'Pelvic obliquity'}
                  type='Walk'
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
