/** Walk analysis report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonItem,
  IonIcon,
  IonChip,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonModal,
  IonList,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import ankle from '../../../assets/avatars/walk/analysis/Ankle.png';
import hip from '../../../assets/avatars/walk/analysis/Hip.png';
import hipExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalStance.png';
import hipExtensionAtTerminalSwing from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalSwing.png';
import knee from '../../../assets/avatars/walk/analysis/Knee.png';
import kneeFlexionAtMidstance from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidstance.png';
import kneeFlexionAtMidswing from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidswing.png';
import kneeExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/KneeExtensionAtTerminalStance.png';
import kneeStabilityAtLoadingResponse from '../../../assets/avatars/walk/analysis/KneeStabilityAtLoadingResponse.png';
import pelvicObliquity from '../../../assets/avatars/walk/analysis/PelvicObliquity.png';
import pelvis from '../../../assets/avatars/walk/analysis/Pelvis.png';
import varusValgus from '../../../assets/avatars/walk/analysis/VarusValgus.png';
import { transpose, toMatrix } from '../../../hooks/useReports';
import { Report, WalkReportData } from '../../../model';
import { NameField, StatusDotField } from '../components/Fields';
import { ReportSection } from '../components/ReportSection';
import './WalkAnalysisReportTab.css';
import {
  ModalHipExtensionTerminalStance,
  ModalKneeFlexionMidstance,
  ModalKneeFlexionMidswing,
  ModalKneeStabilityLoadingResponse,
  ModalPelvicObliquity,
  ModalVarusValgus,
  ModalMobilityStatus,
} from '../components/ModalsWalk';
import { closeCircleOutline, informationCircleOutline, personOutline, pricetagsOutline } from 'ionicons/icons';
import { ModalHipExtensionTerminalSwing } from '../components/ModalsWalk/ModalHipExtensionTerminalSwing';
import { ModalKneeExtensionTerminalStance } from '../components/ModalsWalk/ModalKneeExtensionTerminalStance';
import { useState } from 'react';
import { DashboardCard } from '../components/dashboardCard';
import { AttractorCard } from '../../ReportV2/components/Cards';
import { NameField_v2 } from '../../ReportV2/components/Fields';

type MobilityProps = {
  title: string;
  img: string;
  romRow: number[];
  statusRow: number[];
};

/** Mobility report card content, showing avatar, left/right status and symmetry fields
 * @param img - The avatar
 * @param romRow - list of ROM data values
 * @param statusRow - list of status field tuples for (Left, Right)
 */
const Mobility = ({ title, img, romRow, statusRow }: MobilityProps) => {
  return (
    <IonGrid className=''>
      <IonRow>
        <IonCol className='ion-no-padding'>
          <IonImg src={img} className='reportAvatar' />
          <IonText className='mobilityReportCard_Title'>{title}</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <StatusDotField name='Left' value={romRow[0].toFixed(0) + '°'} status={statusRow[0]} />
        </IonCol>
        <IonCol>
          <StatusDotField name='Right' value={romRow[1].toFixed(0) + '°'} status={statusRow[1]} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

type CardProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  img: string;
  imgCss?: string;
  cssClass?: string;
};

const kpiLabels = [
  ['Ok', 'Fair', 'Poor'],
  ['Hike', 'Ok', 'Drop'],
  ['Ok', 'Fair', 'Poor', 'No extension'],
];

type KpiCardProps = CardProps & {
  kpiRow: number[];
  labels: string[];
  id: string;
};

/** Report card for kpi data
 * @param img - The avatar
 * @param kpiRow - Tuple of (Left, Right) kpi values
 * @param labels - rows of kpi labels, row 0 is Left, row 1 is Right
 */
const KpiCard = ({ title, subtitle, info, img, imgCss, kpiRow, labels }: KpiCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const error = kpiRow[0] === 3 || kpiRow[1] === 3 ? 'error' : '';
  const cardClassName = error ? 'reportCard_Small_Error' : 'reportCard_Small';
  const kpiLabel = (labels: string[], kpi: number) => {
    return labels[kpi - 1];
  };

  return (
    <>
      <IonModal isOpen={showModal} className='reportInfo_Modal' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton slot='icon-only' onClick={() => setShowModal(false)} fill='clear' className='reportInfo_Modal_Close'>
          <IonIcon icon={closeCircleOutline} />
        </IonButton>
      </IonModal>

      <IonCard className={cardClassName}>
        <IonCardHeader className='reportCard_Header'>
          <IonCardTitle className='reportCard_Title'>{title}</IonCardTitle>
          {subtitle && <IonCardSubtitle className='reportCard_Subtitle'>{subtitle}</IonCardSubtitle>}

          <IonButtons className='reportCard_Small_Buttons'>
            <IonButton color='dark' slot='end' className='reportCard_Infobutton' onClick={() => setShowModal(true)}>
              <IonIcon icon={informationCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonCardHeader>
        <IonCardContent className='reportCard_Content'>
          <IonGrid fixed className='ion-no-padding'>
            <IonRow className='full_width ion-justify-content-center'>
              <IonCol size='12' className=''>
                <IonImg src={img} className={imgCss} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonGrid>
                  <IonRow align-items-center>
                    <IonCol size='6'>
                      <NameField name='Left' value={kpiLabel(labels, kpiRow[0])} />
                    </IonCol>
                    <IonCol size='6'>
                      <NameField name='Right' value={kpiLabel(labels, kpiRow[1])} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};

type RomCardProps = CardProps & {
  romRow: number[];
  id: string;
};

/** Report card for ROM data
 * @param img - The avatar
 * @param romRow - list of ROM values for Left, Right, Symmetry
 */
const RomCard = ({ title, subtitle, info, img, imgCss, romRow }: RomCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const error = romRow[0] > 10 || romRow[1] > 10 || romRow[0] < -10 || romRow[1] < -10 ? 'error' : '';
  const cardClassName = error ? 'attractorCard_Error' : 'attractorCard';

  return (
    <>
      <IonModal isOpen={showModal} className='ORYX_Modal_Large' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton slot='icon-only' onClick={() => setShowModal(false)} fill='clear' className='ORYX_ModalButton_Close'>
          <IonIcon icon={closeCircleOutline} />
        </IonButton>
      </IonModal>

      <IonCard className={cardClassName}>
        <IonCardHeader className='attractorCard_Header'>
          <IonGrid fixed className='ion-no-padding'>
            <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
              <IonCol size='9' className=''>
                <IonCardTitle className='attractorCard_Title'>{title}</IonCardTitle>
                {subtitle && <IonCardSubtitle className='attractorCard_Subtitle'>{subtitle}</IonCardSubtitle>}
              </IonCol>

              <IonCol size='3' className='ion-justify-content-center'>
                <IonButtons className='ion-justify-content-center'>
                  <IonButton
                    slot='icon-only'
                    fill='clear'
                    className='attractorCard_Header_Buttons'
                    onClick={() => setShowModal(true)}>
                    <IonIcon icon={informationCircleOutline} />
                  </IonButton>
                </IonButtons>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>

        <IonCardContent className='full_height'>
          <IonGrid class='ion-no-padding' fixed>
            <IonRow className='full_width ion-justify-content-center'>
              <IonCol>
                <IonImg src={img} class='attractorCard_Avatar_Run' />
              </IonCol>
            </IonRow>

            <IonRow className='full_width ion-justify-content-center'>
              <IonCol size='6' className='border_Right'>
                <NameField_v2 name='Left' value={romRow[0].toFixed(0) + '°'} />
              </IonCol>
              <IonCol size='6'>
                <NameField_v2 name='Right' value={romRow[1].toFixed(0) + '°'} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};

type WalkReportProps = RouteComponentProps & {
  report: Report;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const WalkAnalysisReportTab = ({ report }: WalkReportProps) => {
  const data = report.data as WalkReportData | undefined;

  if (!data) {
    return <IonText>Data not found.</IonText>;
  }

  // TODO: useEffect hook to avoid recalculating
  const romData = transpose(data.rom);
  const kpiData = transpose(data.keyPerformanceIndicators);
  const statusData = transpose(data.mobilityStatus);
  const tableData: number[][] = toMatrix(data.spatioTemporal);
  const speedLabel = () => {
    if (tableData[0][4] === 1) {
      return 'Ok';
    } else if (tableData[0][4] === 2) {
      document.getElementById('showSpeedLabel')?.classList.add('warning');
      return 'Slow';
    } else if (tableData[0][4] === 3) {
      document.getElementById('showSpeedLabel')?.classList.add('danger');
      return 'Too slow';
    } else {
      document.getElementById('showSpeedLabel')?.classList.add('danger');
      return 'Too fast';
    }
  };

  // get the name of the person
  const indexToSlice = report.filename.indexOf('-');
  const name = report.filename.slice(0, indexToSlice - 1);

  return (
    <IonPage>
      <IonContent className='ion-padding' color='light'>
        {data && (
          <IonGrid fixed>
            <IonRow className=''>
              <DashboardCard title='Mobility Status' info={<ModalMobilityStatus />}>
                <IonRow>
                  <IonCol size='12'>
                    <IonItem lines='none'>
                      Stride Duration: {tableData[0][0].toFixed(2)}s.
                      <span className='SpeedLabel' id='showSpeedLabel'>
                        {' '}
                        {speedLabel()}{' '}
                      </span>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-padding-bottom'>
                  <IonCol size='3'>
                    <Mobility title='Pelvis' img={pelvis} romRow={romData[0]} statusRow={statusData[0]} />
                  </IonCol>
                  <IonCol size='3'>
                    <Mobility title='Hips' img={hip} romRow={romData[1]} statusRow={statusData[1]} />
                  </IonCol>
                  <IonCol size='3'>
                    <Mobility title='Knees' img={knee} romRow={romData[4]} statusRow={statusData[2]} />
                  </IonCol>
                  <IonCol size='3'>
                    <Mobility title='Ankles' img={ankle} romRow={romData[7]} statusRow={statusData[3]} />
                  </IonCol>
                </IonRow>
              </DashboardCard>
            </IonRow>

            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip extension'
                  subtitle='Terminal stance'
                  img={hipExtensionAtTerminalStance}
                  labels={kpiLabels[0]}
                  attractorRow={kpiData[1]}
                  info={<ModalHipExtensionTerminalStance />}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee extension'
                  subtitle='Terminal stance'
                  img={kneeExtensionAtTerminalStance}
                  labels={kpiLabels[2]}
                  attractorRow={kpiData[0]}
                  info={<ModalKneeExtensionTerminalStance />}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midswing'
                  img={kneeFlexionAtMidswing}
                  labels={kpiLabels[0]}
                  attractorRow={kpiData[6]}
                  info={<ModalKneeFlexionMidswing />}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip extension'
                  subtitle='Terminal swing'
                  img={hipExtensionAtTerminalSwing}
                  labels={kpiLabels[0]}
                  attractorRow={kpiData[2]}
                  info={<ModalHipExtensionTerminalSwing />}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee stability'
                  subtitle='Loading response'
                  img={kneeStabilityAtLoadingResponse}
                  labels={kpiLabels[0]}
                  attractorRow={kpiData[3]}
                  info={<ModalKneeStabilityLoadingResponse />}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midstance'
                  img={kneeFlexionAtMidstance}
                  labels={kpiLabels[0]}
                  attractorRow={kpiData[5]}
                  info={<ModalKneeFlexionMidstance />}
                />
              </IonCol>

              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <RomCard
                  id='Attractorcard'
                  title='Varus / Valgus'
                  subtitle='Midstance'
                  img={varusValgus}
                  romRow={romData[6]}
                  info={<ModalVarusValgus />}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Pelvic obliquity'
                  subtitle='Midstance'
                  img={pelvicObliquity}
                  labels={kpiLabels[1]}
                  attractorRow={kpiData[4]}
                  info={<ModalPelvicObliquity />}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};
