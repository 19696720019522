import React from 'react';
import { useHardware } from '../hooks/useHardware/useHardware';
import { HardwareCollection, ORYXGOCollection, UserId } from '../model';
import { useAuthContext } from './authContext';

type HardwareContextProps =
  | {
      ORYXGOCollection: ORYXGOCollection;
      isLoading: boolean;
      isError: Error | undefined;
      errorMessage: string;
      clearError: () => void;
      getAvailableORYXGOSystems: (userId: UserId) => Promise<void>;
      getAssignmentsFile: (systemId: string) => Promise<string>;
    }
  | undefined;

const HardwareContext = React.createContext<HardwareContextProps>(undefined);

interface HardwareProviderProps {
  children: React.ReactNode;
}
export const HardwareProvider = ({ children }: HardwareProviderProps) => {
  const userId = useAuthContext().state.userState?.user.uid;

  let value: HardwareContextProps = undefined;
  if (userId) {
    const hardwareHook = useHardware(userId);

    const isLoading = hardwareHook.isLoading;
    const isError = hardwareHook.isError;
    const errorMessage = hardwareHook.errorMessage;
    const clearError = () => {
      hardwareHook.clearError();
    };

    value = {
      ORYXGOCollection: hardwareHook.ORYXGOCollection,
      isLoading: isLoading,
      isError: isError,
      errorMessage: errorMessage,
      clearError: clearError,
      getAvailableORYXGOSystems: hardwareHook.getAvailableORYXGOSystems,
      getAssignmentsFile: hardwareHook.getAssignmentsFile,
    };
  }

  return <HardwareContext.Provider value={value}>{children}</HardwareContext.Provider>;
};

export const useHardwareContext = () => {
  const context = React.useContext(HardwareContext);
  if (context === undefined) {
    throw new Error(
      'useHardwareContext() is not being used within the proper scope. It can only be used within GateWayCard component',
    );
  }
  return context;
};
