interface SidebarProps {
  onSidebarLinkClick: (sectionId: string) => void;
}

export const KnowledgeBaseSidebar = ({ onSidebarLinkClick }: SidebarProps) => {
  const handleLinkClick = (sectionId: string) => {
    onSidebarLinkClick(sectionId);
  };

  return (
    <div id='docs-sidebar'>
      {/* Sidebar content */}
      <button onClick={() => handleLinkClick('section1')}>Section 1</button>
      <button onClick={() => handleLinkClick('section2')}>Section 2</button>
      {/* Add more sidebar links as needed */}
    </div>
  );
};
