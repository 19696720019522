import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { ORYXSmallCardHeader } from '../../../../components/componentHeaders';
import { colorLeft, colorRight } from '../CustomGraphComponents';

type ScissorsColumnChartProps = {
  title: string;
  subtitle?: string;
  data: any;
};

export const ScissorsColumnChart = ({ title, subtitle, data }: ScissorsColumnChartProps) => {
  console.log('data', data);
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: 0,
      height: '100%',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
        radius: 50,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      offsetY: 10,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        columnWidth: '55%',
        borderRadius: 4,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(0) + ' m/s';
      },
      offsetY: -20,
      style: {
        fontSize: '10px',
        colors: ['#304758'],
      },
    },
    xaxis: {
      type: 'category',
      categories: ['Left', 'Right'],
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    stroke: {
      show: false,
    },
    // title: {
    //   text: title,
    //   align: 'center',
    //   style: {
    //     fontSize: '14px',
    //     fontWeight: 'bold',
    //     fontFamily: 'Montserrat',
    //     color: '#2066FA',
    //   },
    // },
    // subtitle: {
    //   text: subtitle,
    //   align: 'center',
    //   style: {
    //     fontSize: '10px',
    //     fontFamily: 'Montserrat',
    //   },
    //   offsetY: 10,
    // },
    colors: ['#2066fa', colorRight],
    tooltip: {
      enabled: false,
    },
  };

  return (
    <IonCard className='ORYX_GraphCard'>
      <ORYXSmallCardHeader
        title={title}
        subtitle={subtitle}
        info={false}
        zoom={false}
        setShowZoom={undefined}
        setShowInfo={undefined}
      />

      <IonCardContent className='' style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
        <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Chart options={options} series={data} type='bar' width='100%' height={windowSize == 'small' ? 125 : 175} />
        </div>
      </IonCardContent>
    </IonCard>
  );
};
