/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { RearPendulumPointsOfInterests_Left, RunReportData, StridesData } from '../../../model';

import {
  calculateErrorData,
  calculateMean,
  combinePointsOfInterest,
  flattenData,
  getfootplantCoordinates,
  toGraphData,
  toJointCouplingData,
  toScatterData,
  toScissorsData,
  xyToScissorsData,
} from '../components/DataFunctions';
import {
  ProjectionFootPlantGraph,
  RearPendulum,
  RearPendulumGraph,
  RespectTheSagittalPlaneGraph,
} from '../components/Graphs';

import { colorCS, colorLeft, colorRight, colorSS, colors } from '../components/CustomGraphComponents';
import { transpose } from '../../../hooks/useReports/transform';
import { ApexOptions } from 'apexcharts';

import { DashboardCard, ORYXGraphCard } from '../components/Cards';
import { useEffect, useMemo, useState } from 'react';
import { informationCircleOutline } from 'ionicons/icons';
import { title } from 'process';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { ZoomModalRearPendulum } from '../components/ModalsRun/ZoomModalRearPendulum';

type AttractorsReportProps = RouteComponentProps & {
  report: RunReportData;
};

// Change the data from the rearPendulum toe offs to the same format as the scissors ([x,y])
function combinePointsOfInterestTest(points: RearPendulumPointsOfInterests_Left, xIndex: number, yIndex: number) {
  const numCycles = Object.keys(points).length;
  const scatterData = [];

  for (let i = 0; i < numCycles; i++) {
    scatterData.push([points[`col${i}`][xIndex], points[`col${i}`][yIndex]]);
  }

  return scatterData;
}

function toScissorsData_V2(data: any) {
  if (process.env.NODE_ENV !== 'production') console.log('in scissors data', data);
  if (!data) {
    return [];
  }
  const numCycles = Object.keys(data).length;
  const graphData: { x: number; y: number | null }[][] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number | null }[] = [];

    cycleData.push({ x: data[i][0], y: data[i][1] });

    graphData.push(cycleData);
  }

  return graphData;
}

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunningStatusReportTab = ({ report }: AttractorsReportProps) => {
  const data = report as RunReportData | undefined;
  const rearPendulumDataX_Left = data?.reportId.rearPendulumXPositions_Left;
  const rearPendulumDataZ_Left = data?.reportId.rearPendulumZPositions_Left;
  const rearPendulumDataX_Right = data?.reportId.rearPendulumXPositions_Right;
  const rearPendulumDataZ_Right = data?.reportId.rearPendulumZPositions_Right;
  const pointsOfInterest_Left = data?.reportId.rearPendulumPointsOfInterest_Left;
  const pointsOfInterest_Right = data?.reportId.rearPendulumPointsOfInterest_Right;
  const runningStatusData: number[][] = transpose(data?.reportId.runningStatusTableData);
  const romData: number[][] = transpose(data?.reportId.rom);

  //#region Rear Pendulum

  const rearPendulumData_Left = useMemo(
    () => toJointCouplingData(rearPendulumDataZ_Left, rearPendulumDataX_Left),
    [rearPendulumDataX_Left, rearPendulumDataZ_Left],
  );

  // For the label in the graph
  const rearPendulumData_ToeOff_Left = useMemo(
    () => combinePointsOfInterest(pointsOfInterest_Left, 0, 1),
    [pointsOfInterest_Left],
  );

  const rearPendulum_ToeOff_Left = useMemo(
    () => calculateMean(rearPendulumData_ToeOff_Left),
    [rearPendulumData_ToeOff_Left],
  );

  const toeOffs_Left = useMemo(() => combinePointsOfInterestTest(pointsOfInterest_Left, 0, 1), [data]);
  const midSwings_Left = useMemo(() => combinePointsOfInterestTest(pointsOfInterest_Left, 2, 3), [data]);
  const leftToeOffsMoments = useMemo(() => toScissorsData_V2(toeOffs_Left), [data]);
  const leftMidSwingsMoments = useMemo(() => toScissorsData_V2(midSwings_Left), [data]);
  const seriesLeftToeOff = [{ type: 'scatter', name: 'TO', data: flattenData(leftToeOffsMoments), color: colorCS }];

  const seriesLeftMidSwing = [
    { type: 'scatter', name: 'MSw', data: flattenData(leftMidSwingsMoments), color: colorSS },
  ];

  // For the label in the graph
  const rearPendulumData_MidSwing_Left = useMemo(
    () => combinePointsOfInterest(pointsOfInterest_Left, 2, 3),
    [pointsOfInterest_Left],
  );
  const rearPendulum_MidSwing_Left = useMemo(
    () => calculateMean(rearPendulumData_MidSwing_Left),
    [rearPendulumData_MidSwing_Left],
  );

  // Variance label
  const rearPendulumVariance_ToeOff_Left = data?.reportId.variance.col0[0].toFixed(5);
  const rearPendulumVariance_MidSwing_Left = data?.reportId.variance.col0[1].toFixed(5);

  const rearPendulumGraphData_Left = [...seriesLeftToeOff, ...seriesLeftMidSwing, ...rearPendulumData_Left];

  //! ====================================== Right Side ====================================== !//
  const rearPendulumData_Right = useMemo(
    () => toJointCouplingData(rearPendulumDataZ_Right, rearPendulumDataX_Right),
    [rearPendulumDataX_Right, rearPendulumDataZ_Right],
  );

  // For the label in the graph
  const rearPendulumData_ToeOff_Right = useMemo(
    () => combinePointsOfInterest(pointsOfInterest_Right, 0, 1),
    [pointsOfInterest_Right],
  );

  const rearPendulum_ToeOff_Right = useMemo(
    () => calculateMean(rearPendulumData_ToeOff_Right),
    [rearPendulumData_ToeOff_Right],
  );

  const toeOffs_Right = useMemo(() => combinePointsOfInterestTest(pointsOfInterest_Right, 0, 1), [data]);
  const midSwings_Right = useMemo(() => combinePointsOfInterestTest(pointsOfInterest_Right, 2, 3), [data]);
  const rightToeOffsMoments = useMemo(() => toScissorsData_V2(toeOffs_Right), [data]);
  const rightMidSwingsMoments = useMemo(() => toScissorsData_V2(midSwings_Right), [data]);
  const seriesRightToeOff = [{ type: 'scatter', name: 'TO', data: flattenData(rightToeOffsMoments), color: colorCS }];

  const seriesRightMidSwing = [
    { type: 'scatter', name: 'MSw', data: flattenData(rightMidSwingsMoments), color: colorSS },
  ];

  // For the label in the graph
  const rearPendulumData_MidSwing_Right = useMemo(
    () => combinePointsOfInterest(pointsOfInterest_Right, 2, 3),
    [pointsOfInterest_Right],
  );
  const rearPendulum_MidSwing_Right = useMemo(
    () => calculateMean(rearPendulumData_MidSwing_Right),
    [rearPendulumData_MidSwing_Right],
  );

  // Variance label
  const rearPendulumVariance_ToeOff_Right = data?.reportId.variance.col1[0].toFixed(5);
  const rearPendulumVariance_MidSwing_Right = data?.reportId.variance.col1[1].toFixed(5);

  const rearPendulumGraphData_Right = [...seriesRightToeOff, ...seriesRightMidSwing, ...rearPendulumData_Right];
  //#endregion Rear Pendulum

  //#region Footplant
  const footplantData_InitialContact_Left = useMemo(
    () => getfootplantCoordinates(data?.reportId.projectionsFootPlant_Left, 0),
    [data?.reportId.projectionsFootPlant_Left],
  );
  const footplant_InitialContact_Left = calculateErrorData(
    footplantData_InitialContact_Left,
    data?.reportId.projectionFootPlant.col1[0],
  );

  const footplantData_InitialContact_Right = useMemo(
    () => getfootplantCoordinates(data?.reportId.projectionsFootPlant_Right, 0),
    [data?.reportId.projectionsFootPlant_Right],
  );
  const footplant_InitialContact_Right = calculateErrorData(
    footplantData_InitialContact_Right,
    data?.reportId.projectionFootPlant.col3[0],
  );

  const footplantData_MidStance_Left = useMemo(
    () => getfootplantCoordinates(data?.reportId.projectionsFootPlant_Left, 1),
    [data?.reportId.projectionsFootPlant_Left],
  );
  const footplant_MidStance_Left = calculateErrorData(
    footplantData_MidStance_Left,
    data?.reportId.projectionFootPlant.col1[1],
  );

  const footplantData_MidStance_Right = useMemo(
    () => getfootplantCoordinates(data?.reportId.projectionsFootPlant_Right, 1),
    [data?.reportId.projectionsFootPlant_Right],
  );
  const footplant_MidStance_Right = calculateErrorData(
    footplantData_MidStance_Right,
    data?.reportId.projectionFootPlant.col3[1],
  );

  const footplantData_HeelOff_Left = useMemo(
    () => getfootplantCoordinates(data?.reportId.projectionsFootPlant_Left, 2),
    [data?.reportId.projectionsFootPlant_Left],
  );
  const footplant_HeelOff_Left = calculateErrorData(
    footplantData_HeelOff_Left,
    data?.reportId.projectionFootPlant.col1[2],
  );

  const footplantData_HeelOff_Right = useMemo(
    () => getfootplantCoordinates(data?.reportId.projectionsFootPlant_Right, 2),
    [data?.reportId.projectionsFootPlant_Right],
  );
  const footplant_HeelOff_Right = calculateErrorData(
    footplantData_HeelOff_Right,
    data?.reportId.projectionFootPlant.col3[2],
  );

  const footplantAtInitialContactSeries_Left = [
    {
      type: 'scatter',
      name: 'IC - Left',
      data: footplantData_InitialContact_Left,
    },
  ];

  const footplantAtMidStanceSeries_Left = [
    {
      type: 'scatter',
      name: 'MSt - Left',
      data: footplantData_MidStance_Left,
    },
  ];

  const footplantAtHeelOffSeries_Left = [
    {
      type: 'scatter',
      name: 'HO - Left',
      data: footplantData_HeelOff_Left,
    },
  ];

  const footplantAtInitialContactSeries_Right = [
    {
      type: 'scatter',
      name: 'IC - Right',
      data: footplantData_InitialContact_Right,
    },
  ];

  const footplantAtMidStanceSeries_Right = [
    {
      type: 'scatter',
      name: 'MSt - Right',
      data: footplantData_MidStance_Right,
    },
  ];

  const footplantAtHeelOffSeries_Right = [
    {
      type: 'scatter',
      name: 'HO - Right',
      data: footplantData_HeelOff_Right,
    },
  ];
  //#endregion Footplant

  //#region Respect the Sagittal Plane
  const leftAnklePositionsSeriesData = useMemo(
    () => toGraphData(data?.stridesLeft.respectTheSagittalPlaneAnkle_Left),
    [data?.stridesLeft.respectTheSagittalPlaneAnkle_Left],
  );
  const rightAnklePositionsSeriesData = useMemo(
    () => toGraphData(data?.stridesRight.respectTheSagittalPlaneAnkle_Right),
    [data?.stridesRight.respectTheSagittalPlaneAnkle_Right],
  );

  const leftAnklePositionsSeries = [
    {
      type: 'line',
      name: 'Left Ankle',
      data: flattenData(leftAnklePositionsSeriesData),
      color: colorLeft,
    },
  ];

  const rightAnklePositionsSeries = [
    {
      type: 'line',
      name: 'Right Ankle',
      data: flattenData(rightAnklePositionsSeriesData),
      color: colorRight,
    },
  ];

  const respectTheSagittalPlaneSeries = [...leftAnklePositionsSeries, ...rightAnklePositionsSeries];
  //#endregion Respect the Sagittal Plane

  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');
  const [projectionFootPlantRowHeight, setProjectionFootPlantRowHeight] = useState(105);

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
        setProjectionFootPlantRowHeight(150);
      } else {
        setWindowSize('small');
        setProjectionFootPlantRowHeight(105);
      }
      if (process.env.NODE_ENV !== 'production') console.log('width', height);
    }
  }, [width]);

  const rearPendulumZoomModalContent = {
    rearPendulumGraphData_Left,
    rearPendulum_ToeOff_Left,
    rearPendulum_MidSwing_Left,
    rearPendulumVariance_ToeOff_Left,
    rearPendulumVariance_MidSwing_Left,
    rearPendulumGraphData_Right,
    rearPendulum_ToeOff_Right,
    rearPendulum_MidSwing_Right,
    rearPendulumVariance_ToeOff_Right,
    rearPendulumVariance_MidSwing_Right,
  };

  const [showZoomModal, setShowZoomModal] = useState({
    show: false,
  });
  const [showInfoModal, setShowInfoModal] = useState({
    show: false,
  });

  const annotations = data?.reportId.pointsOfInterests.col0;

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed className='full_height'>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '40%' }}>
              <IonCol size='4' className=''>
                <ORYXGraphCard
                  title='Pendulum Left'
                  zoomModalContent={rearPendulumZoomModalContent}
                  info={false}
                  zoom={true}>
                  <IonRow>
                    <RearPendulum
                      data={rearPendulumGraphData_Left}
                      TOLocation={rearPendulum_ToeOff_Left}
                      MSwLocation={rearPendulum_MidSwing_Left}
                    />
                  </IonRow>
                  <IonRow className=''>
                    <IonCol size='6' className='ion-no-padding border_Right ion-text-center'>
                      <small> &sigma; TO: {rearPendulumVariance_ToeOff_Left}</small>
                    </IonCol>
                    <IonCol size='6' className='ion-no-padding ion-text-center'>
                      <small> &sigma; MSw: {rearPendulumVariance_MidSwing_Left}</small>
                    </IonCol>
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
              <IonCol size='4' className=''>
                <ORYXGraphCard
                  title='Pendulum Right'
                  zoomModalContent={rearPendulumZoomModalContent}
                  info={false}
                  zoom={true}>
                  <IonRow>
                    <RearPendulum
                      data={rearPendulumGraphData_Right}
                      TOLocation={rearPendulum_ToeOff_Right}
                      MSwLocation={rearPendulum_MidSwing_Right}
                    />
                  </IonRow>
                  <IonRow className=''>
                    <IonCol size='6' className='ion-no-padding border_Right ion-text-center'>
                      <small> &sigma; TO: {rearPendulumVariance_ToeOff_Right}</small>
                    </IonCol>
                    <IonCol size='6' className='ion-no-padding ion-text-center'>
                      <small> &sigma; MSw: {rearPendulumVariance_MidSwing_Right}</small>
                    </IonCol>
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
              <IonCol size='4' className=''>
                <ORYXGraphCard
                  title='Respect the sagittal plane'
                  zoomModalContent={undefined}
                  info={false}
                  zoom={false}>
                  <IonRow>
                    <RespectTheSagittalPlaneGraph data={respectTheSagittalPlaneSeries} annotations={annotations} />
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
            </IonRow>

            <IonRow className='ion-align-items-stretch ion-justify-content-center' style={{ height: '60%' }}>
              <IonCol size='4' className=''>
                <ORYXGraphCard
                  title='Projection footplant - Left'
                  zoomModalContent={undefined}
                  info={false}
                  zoom={false}>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph data={footplantAtInitialContactSeries_Left} subtitle='Initial Contact' />
                  </IonRow>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph data={footplantAtMidStanceSeries_Left} subtitle='MidStance' />
                  </IonRow>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph data={footplantAtHeelOffSeries_Left} subtitle='Heel Off' />
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
              <IonCol size='4'>
                <ORYXGraphCard
                  title='Projection footplant - Right'
                  zoomModalContent={undefined}
                  info={false}
                  zoom={false}>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph data={footplantAtInitialContactSeries_Right} subtitle='Initial Contact' />
                  </IonRow>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph data={footplantAtMidStanceSeries_Right} subtitle='MidStance' />
                  </IonRow>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph data={footplantAtHeelOffSeries_Right} subtitle='Heel Off' />
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
              <IonCol size='4' className='full_height '>
                <ORYXGraphCard title='Info' zoomModalContent={undefined} info={false} zoom={false}>
                  <IonRow className='ion-no-padding'>
                    <table className='ORYX_List'>
                      <tbody>
                        <tr>
                          <td>Stride duration</td>
                          <td>{runningStatusData[0][0].toFixed(2) + ' s'}</td>
                        </tr>
                        <tr>
                          <td>Cadence</td>
                          <td colSpan={3}>{(runningStatusData[2][0] / 2).toFixed(0) + ' strides/min'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </IonRow>
                  <IonRow className='ion-no-padding'>
                    <table className='ORYX_Table'>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>Left</th>
                          <th>Right</th>
                          <th>Symmetry</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Ground contact time</td>
                          <td>{runningStatusData[1][0].toFixed(2) + ' s'}</td>
                          <td>{runningStatusData[1][1].toFixed(2) + ' s'}</td>
                          <td>{runningStatusData[1][2].toFixed(2) + ' %'}</td>
                        </tr>
                        <tr>
                          <td>Propulsive velocity</td>
                          <td>{runningStatusData[4][0].toFixed(0) + ' m/s'}</td>
                          <td>{runningStatusData[4][1].toFixed(0) + ' s'}</td>
                          <td>{runningStatusData[4][2].toFixed(2) + ' %'}</td>
                        </tr>

                        <tr className='ORYX_Table_Head'>
                          <td> Pelvis</td>
                          <td>{romData[0][0].toFixed(1) + '°'}</td>
                          <td>{romData[0][1].toFixed(1) + '°'}</td>
                          <td>{romData[0][2].toFixed(0) + '%'}</td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td> Hip</td>
                          <td>{romData[1][0].toFixed(1) + '°'}</td>
                          <td>{romData[1][1].toFixed(1) + '°'}</td>
                          <td>{romData[1][2].toFixed(0) + '%'}</td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Flexion</td>
                          <td>{romData[2][0].toFixed(1) + '°'}</td>
                          <td>{romData[2][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Extension</td>
                          <td>{romData[3][0].toFixed(1) + '°'}</td>
                          <td>{romData[3][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td>Knee</td>
                          <td>{romData[4][0].toFixed(1) + '°'}</td>
                          <td>{romData[4][1].toFixed(1) + '°'}</td>
                          <td>{romData[4][2].toFixed(0) + '%'}</td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'> Stance flexion</td>
                          <td>{romData[5][0].toFixed(1) + '°'}</td>
                          <td>{romData[5][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Varus Valgus</td>
                          <td>{romData[6][0].toFixed(1) + '°'}</td>
                          <td>{romData[6][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td>Ankle</td>
                          <td>{romData[7][0].toFixed(1) + '°'}</td>
                          <td>{romData[7][1].toFixed(1) + '°'}</td>
                          <td>{romData[7][2].toFixed(0) + '%'}</td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Dorsal flexion</td>
                          <td>{romData[8][0].toFixed(1) + '°'}</td>
                          <td>{romData[8][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Plantar flexion</td>
                          <td>{romData[9][0].toFixed(1) + '°'}</td>
                          <td>{romData[9][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
