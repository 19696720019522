/** Walk analysis report tab */
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, IonText, IonItem } from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import ankle from '../../../assets/avatars/walk/analysis/Ankle.png';
import hip from '../../../assets/avatars/walk/analysis/Hip.png';
import knee from '../../../assets/avatars/walk/analysis/Knee.png';

import pelvis from '../../../assets/avatars/walk/analysis/Pelvis.png';

import { transpose, toMatrix } from '../../../hooks/useReports';
import { WalkReportData } from '../../../model';
import { StatusDotField } from '../components/Fields';

import './WalkAnalysisReportTab.css';
import { ModalMobilityStatus } from '../components/ModalsWalk';

import { useEffect, useState } from 'react';

import { DashboardCard } from '../../Report/components/dashboardCard';

import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

type MobilityProps = {
  title: string;
  img: string;
  romRow: number[];
  statusRow: number[];
};

/** Mobility report card content, showing avatar, left/right status and symmetry fields
 * @param img - The avatar
 * @param romRow - list of ROM data values
 * @param statusRow - list of status field tuples for (Left, Right)
 */
const Mobility = ({ title, img, romRow, statusRow }: MobilityProps) => {
  return (
    <IonGrid className=''>
      <IonRow>
        <IonCol className='ion-no-padding'>
          <IonImg src={img} className='reportAvatar' />
          <IonText className='mobilityReportCard_Title'>{title}</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <StatusDotField name='Left' value={romRow[0].toFixed(0) + '°'} status={statusRow[0]} />
        </IonCol>
        <IonCol>
          <StatusDotField name='Right' value={romRow[1].toFixed(0) + '°'} status={statusRow[1]} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

type WalkReportProps = RouteComponentProps & {
  report: WalkReportData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const WalkMobilityReportTab = ({ report }: WalkReportProps) => {
  const data = report;
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);
  const romData: number[][] = transpose(data?.reportId.rom);
  const statusData = transpose(data?.reportId.mobilityStatus);
  const tableData: number[][] = toMatrix(data?.reportId.spatioTemporal);

  const speedLabel = () => {
    if (tableData[0][4] === 1) {
      return 'Ok';
    } else if (tableData[0][4] === 2) {
      document.getElementById('showSpeedLabel')?.classList.add('warning');
      return 'Slow';
    } else if (tableData[0][4] === 3) {
      document.getElementById('showSpeedLabel')?.classList.add('danger');
      return 'Too slow';
    } else {
      document.getElementById('showSpeedLabel')?.classList.add('danger');
      return 'Too fast';
    }
  };

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed className='full_height'>
            <IonRow className='ion-no-padding full_height'>
              <DashboardCard title='Mobility Status' info={'Mobility Status'}>
                <div
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}>
                  <IonRow>
                    <IonCol size='12'>
                      <IonItem lines='none'>
                        <strong>
                          Stride Duration: {tableData[0][0].toFixed(2)}s.
                          <span className='SpeedLabel' id='showSpeedLabel'>
                            {' '}
                            {speedLabel()}{' '}
                          </span>
                        </strong>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-padding-bottom'>
                    <IonCol size='3'>
                      <Mobility title='Pelvis' img={pelvis} romRow={romData[0]} statusRow={statusData[0]} />
                    </IonCol>
                    <IonCol size='3'>
                      <Mobility title='Hip' img={hip} romRow={romData[1]} statusRow={statusData[1]} />
                    </IonCol>
                    <IonCol size='3'>
                      <Mobility title='Knee' img={knee} romRow={romData[4]} statusRow={statusData[2]} />
                    </IonCol>
                    <IonCol size='3'>
                      <Mobility title='Ankle' img={ankle} romRow={romData[7]} statusRow={statusData[3]} />
                    </IonCol>
                  </IonRow>
                </div>
              </DashboardCard>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
