import React from 'react';
import { IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonImg, IonIcon, IonCardSubtitle } from '@ionic/react';
import './modalindex.css';
import IBimageKneeanglemidstance1 from './assets/IBimageKneeanglemidstance1.png';
import IBimageKneeanglemidstance2 from './assets/IBimageKneeanglemidstance2.png';

import { ORYXModalHeader } from '../../../../components/componentHeaders';
import { ORYXVerticalSwiper } from '../../../../components/swiper';
import { SwiperSlide } from 'swiper/react';

interface ModalProps {
  buttons?: boolean;
  setShowModal: any;
}

export const ModalKneeFlexionMidstanceRun = ({ buttons, setShowModal }: ModalProps) => {
  const cleanUp = () => {
    setShowModal({ show: false });
  };
  return (
    <>
      <ORYXModalHeader title='Knee flexion' subtitle='Midstance' setShowInfo={cleanUp} buttons={true} />

      <ORYXVerticalSwiper>
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimageKneeanglemidstance1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  During running, the COM will perform a wave movement, with its highest point at the middle of the
                  floating phase and lowest at midstance.
                  <br></br>
                  <br></br>
                  Due to the lowering COM after landing, the body must absorb massive ground reaction forces. Accurate
                  response to this sudden loading requires sufficient leg stiffness (also see: swing leg retraction).
                  <br></br>
                  <br></br>
                  The kinetic energy released is converted into elastic energy as much as possible, which is being
                  stored in elastic components such as tendons and fascia for re-use after midstance.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimageKneeanglemidstance2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  The knee is a key hub for transport of energy from proximal to distal, in which the muscle effort arms
                  change along with the knee angle and hence also the joint torque. Therefore the knee angle at
                  midstance is an important parameter to check economic running.
                  <br></br>
                  <br></br>
                  The right (maximum) knee angle in stance phase is critical for running at high speed; slightly larger
                  angles lead to a deeper ‘sit’, resulting in loss of performance.
                  <br></br>
                  <br></br>
                  Lack of stiffness typically forces the knee to ‘collapse’. This results in larger knee angles which
                  disable the possibility to store elastic energy.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </ORYXVerticalSwiper>
    </>
  );
};
