/** Squat analysis report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonIcon,
  IonButton,
  IonButtons,
  IonCard,
  IonModal,
  IonList,
  IonPopover,
  IonLoading,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SquatReportData } from '../../../model';

import { useEffect, useState } from 'react';
import { FunctionalMovementVariabilityGraph } from '../components/Graphs';
import { ApexOptions } from 'apexcharts';
import {
  colorSS,
  colorST,
  colorCT,
  colorCS,
  variabilityAll_Left,
  variabilityAll_Right,
  VariabilityGraphLegenda,
  colorTC,
} from '../components/CustomGraphComponents';
import { toGraphData, flattenData, toVariabilityData, createLegend } from '../components/DataFunctions';
import ORYX_MagnifyingGlassIcon from '../../../assets/icons/ORYX_MagnifyingGlassIcon.svg';
import { uuid } from 'uuidv4';
import { VariabilityModalContent } from '../components/Modals';

type SquatsHipVariabilityReportProps = RouteComponentProps & {
  report: SquatReportData;
};

interface LegendaItem {
  name: string;
  color: string;
  id: string;
  side?: string;
}

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const SingleLegSquatsVariabilityReportTab = ({ report }: SquatsHipVariabilityReportProps) => {
  const data = report as SquatReportData;
  const [loading, setLoading] = useState(true);

  const [showMoreInfoModal, setShowMoreInfoModal] = useState({
    show: false,
    joint: '',
  });
  const [pelvisLegenda, setPelvisLegenda] = useState<LegendaItem[]>([]);
  const [kneeLegenda, setKneeLegenda] = useState<LegendaItem[]>([]);
  const [ankleLegenda, setAnkleLegenda] = useState<LegendaItem[]>([]);

  const [crpSeriesPelvisThigh_Left, setCrpSeriesPelvisThigh_Left] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewHip_Left, setCoordinativeVariabilityOverviewHip_Left] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesHip_Left, setCoordinativeVariabilitySeriesHip_Left] = useState<
    ApexOptions['series']
  >([]);
  const [crpSeriesPelvisThigh_Right, setCrpSeriesPelvisThigh_Right] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewHip_Right, setCoordinativeVariabilityOverviewHip_Right] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesHip_Right, setCoordinativeVariabilitySeriesHip_Right] = useState<
    ApexOptions['series']
  >([]);

  const [crpSeriesThighCalf_Left, setCrpSeriesThighCalf_Left] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewKnee_Left, setCoordinativeVariabilityOverviewKnee_Left] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesKnee_Left, setCoordinativeVariabilitySeriesKnee_Left] = useState<
    ApexOptions['series']
  >([]);
  const [crpSeriesThighCalf_Right, setCrpSeriesThighCalf_Right] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewKnee_Right, setCoordinativeVariabilityOverviewKnee_Right] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesKnee_Right, setCoordinativeVariabilitySeriesKnee_Right] = useState<
    ApexOptions['series']
  >([]);

  const [crpSeriesCalfFoot_Left, setCrpSeriesCalfFoot_Left] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewAnkle_Left, setCoordinativeVariabilityOverviewAnkle_Left] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesAnkle_Left, setCoordinativeVariabilitySeriesAnkle_Left] = useState<
    ApexOptions['series']
  >([]);
  const [crpSeriesCalfFoot_Right, setCrpSeriesCalfFoot_Right] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewAnkle_Right, setCoordinativeVariabilityOverviewAnkle_Right] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesAnkle_Right, setCoordinativeVariabilitySeriesAnkle_Right] = useState<
    ApexOptions['series']
  >([]);

  useEffect(() => {
    const crpDataPelvisThigh_Left = toGraphData(data?.crpHipLeft.crpPelvisThigh_Left);
    const crpSeriesDataPelvisThigh_Left = [
      { type: 'line', name: 'Pelvis (s) / Thigh (s)', data: flattenData(crpDataPelvisThigh_Left), color: colorSS },
    ];
    const crpDataPelvisThighT_Left = toGraphData(data?.crpHipLeft.crpPelvisThighT_Left);
    const crpSeriesDataPelvisThighT_Left = [
      { type: 'line', name: 'Pelvis (s) / Thigh (t)', data: flattenData(crpDataPelvisThighT_Left), color: colorST },
    ];

    const crpDataPelvisCThighT_Left = toGraphData(data?.crpHipLeft.crpPelvisCThighT_Left);
    const crpSeriesDataPelvisCThighT_Left = [
      { type: 'line', name: 'Pelvis (c) / Thigh (t)', data: flattenData(crpDataPelvisCThighT_Left), color: colorCT },
    ];

    const crpDataPelvisCThigh_Left = toGraphData(data?.crpHipLeft.crpPelvisCThigh_Left);
    const crpSeriesDataPelvisCThigh_Left = [
      { type: 'line', name: 'Pelvis (c) / Thigh (s)', data: flattenData(crpDataPelvisCThigh_Left), color: colorCS },
    ];

    setCrpSeriesPelvisThigh_Left([
      ...crpSeriesDataPelvisThighT_Left,
      ...crpSeriesDataPelvisCThighT_Left,
      ...crpSeriesDataPelvisCThigh_Left,
      ...crpSeriesDataPelvisThigh_Left,
    ]);

    const coordinativeVariabilityDataAllHip_Left = toVariabilityData(
      data?.coordinativeVariability.coordinativeVariabilityDataHip_Left.col0,
    );
    const coordinativeVariabilitySeriesAllHip_Left = [
      { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllHip_Left, color: variabilityAll_Left },
    ];

    const coordinativeVariabilityDataPelvisThigh_Left = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityPelvisThigh_Left.col0,
    );
    const coordinativeVariabilitySeriesPelvisThigh_Left = [
      {
        type: 'area',
        name: 'Pelvis (s) / Thigh (s)',
        data: coordinativeVariabilityDataPelvisThigh_Left,
        color: colorSS,
      },
    ];

    const coordinativeVariabilityDataPelvisThighT_Left = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityPelvisThigh_Left.col2,
    );
    const coordinativeVariabilitySeriesPelvisThighT_Left = [
      {
        type: 'area',
        name: 'Pelvis (s) / Thigh (t)',
        data: coordinativeVariabilityDataPelvisThighT_Left,
        color: colorST,
      },
    ];

    const coordinativeVariabilityDataPelvisCThighT_Left = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityPelvisThigh_Left.col3,
    );

    const coordinativeVariabilitySeriesPelvisCThighT_Left = [
      {
        type: 'area',
        name: 'Pelvis (c) / Thigh (t)',
        data: coordinativeVariabilityDataPelvisCThighT_Left,
        color: colorCT,
      },
    ];

    const coordinativeVariabilityDataPelvisCThigh_Left = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityPelvisThigh_Left.col1,
    );

    const coordinativeVariabilitySeriesPelvisCThigh_Left = [
      {
        type: 'area',
        name: 'Pelvis (c) / Thigh (s)',
        data: coordinativeVariabilityDataPelvisCThigh_Left,
        color: colorCS,
      },
    ];

    setCoordinativeVariabilityOverviewHip_Left([...coordinativeVariabilitySeriesAllHip_Left]);

    setCoordinativeVariabilitySeriesHip_Left([
      ...coordinativeVariabilitySeriesAllHip_Left,
      ...coordinativeVariabilitySeriesPelvisThigh_Left,
      ...coordinativeVariabilitySeriesPelvisThighT_Left,
      ...coordinativeVariabilitySeriesPelvisCThighT_Left,
      ...coordinativeVariabilitySeriesPelvisCThigh_Left,
    ]);

    // !! Right
    const crpDataPelvisThigh_Right = toGraphData(data?.crpHipRight.crpPelvisThigh_Right);
    const crpSeriesDataPelvisThigh_Right = [
      {
        type: 'line',
        name: 'Pelvis (s) / Thigh (s)',
        data: flattenData(crpDataPelvisThigh_Right),
        color: colorSS,
      },
    ];
    const crpDataPelvisThighT_Right = toGraphData(data?.crpHipRight.crpPelvisThighT_Right);
    const crpSeriesDataPelvisThighT_Right = [
      {
        type: 'line',
        name: 'Pelvis (s) / Thigh (t)',
        data: flattenData(crpDataPelvisThighT_Right),
        color: colorST,
      },
    ];

    const crpDataPelvisCThighT_Right = toGraphData(data?.crpHipRight.crpPelvisCThighT_Right);
    const crpSeriesDataPelvisCThighT_Right = [
      {
        type: 'line',
        name: 'Pelvis (c) / Thigh (t)',
        data: flattenData(crpDataPelvisCThighT_Right),
        color: colorCT,
      },
    ];

    const crpDataPelvisCThigh_Right = toGraphData(data?.crpHipRight.crpPelvisCThigh_Right);
    const crpSeriesDataPelvisCThigh_Right = [
      { type: 'line', name: 'Pelvis (c) / Thigh (s)', data: flattenData(crpDataPelvisCThigh_Right), color: colorCS },
    ];

    setCrpSeriesPelvisThigh_Right([
      ...crpSeriesDataPelvisThighT_Right,
      ...crpSeriesDataPelvisCThighT_Right,
      ...crpSeriesDataPelvisCThigh_Right,
      ...crpSeriesDataPelvisThigh_Right,
    ]);

    const coordinativeVariabilityDataAllHip_Right = toVariabilityData(
      data?.coordinativeVariability.coordinativeVariabilityDataHip_Right.col0,
    );

    const coordinativeVariabilitySeriesAllHip_Right = [
      { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllHip_Right, color: variabilityAll_Right },
    ];

    const coordinativeVariabilityDataPelvisThigh_Right = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityPelvisThigh_Right.col0,
    );
    const coordinativeVariabilitySeriesPelvisThigh_Right = [
      {
        type: 'area',
        name: 'Pelvis (s) / Thigh (s)',
        data: coordinativeVariabilityDataPelvisThigh_Right,
        color: colorSS,
      },
    ];

    const coordinativeVariabilityDataPelvisThighT_Right = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityPelvisThigh_Right.col2,
    );
    const coordinativeVariabilitySeriesPelvisThighT_Right = [
      {
        type: 'area',
        name: 'Pelvis (s) / Thigh (t)',
        data: coordinativeVariabilityDataPelvisThighT_Right,
        color: colorST,
      },
    ];

    const coordinativeVariabilityDataPelvisCThighT_Right = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityPelvisThigh_Right.col3,
    );

    const coordinativeVariabilitySeriesPelvisCThighT_Right = [
      {
        type: 'area',
        name: 'Pelvis (c) / Thigh (t)',
        data: coordinativeVariabilityDataPelvisCThighT_Right,
        color: colorCT,
      },
    ];

    const coordinativeVariabilityDataPelvisCThigh_Right = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityPelvisThigh_Right.col1,
    );

    const coordinativeVariabilitySeriesPelvisCThigh_Right = [
      {
        type: 'area',
        name: 'Pelvis (c) / Thigh (s)',
        data: coordinativeVariabilityDataPelvisCThigh_Right,
        color: colorCS,
      },
    ];

    setCoordinativeVariabilityOverviewHip_Right([...coordinativeVariabilitySeriesAllHip_Right]);

    setCoordinativeVariabilitySeriesHip_Right([
      ...coordinativeVariabilitySeriesAllHip_Right,
      ...coordinativeVariabilitySeriesPelvisThigh_Right,
      ...coordinativeVariabilitySeriesPelvisThighT_Right,
      ...coordinativeVariabilitySeriesPelvisCThighT_Right,
      ...coordinativeVariabilitySeriesPelvisCThigh_Right,
    ]);
    // !! Knee //
    const crpDataThighCalf_Left = toGraphData(data?.crpKneeLeft.crpThighCalf_Left);
    const crpSeriesDataThighCalf_Left = [
      { type: 'line', name: 'Thigh (s) / Calf (s)', data: flattenData(crpDataThighCalf_Left), color: colorSS },
    ];

    const crpDataThighCalfT_Left = toGraphData(data?.crpKneeLeft.crpThighCalfT_Left);
    const crpSeriesDataThighCalfT_Left = [
      { type: 'line', name: 'Thigh (s) / Calf (t)', data: flattenData(crpDataThighCalfT_Left), color: colorST },
    ];

    const crpDataThighCCalfT_Left = toGraphData(data?.crpKneeLeft.crpThighCCalfT_Left);
    const crpSeriesDataThighCCalfT_Left = [
      { type: 'line', name: 'Thigh (c) / Calf (t)', data: flattenData(crpDataThighCCalfT_Left), color: colorCT },
    ];

    setCrpSeriesThighCalf_Left([
      ...crpSeriesDataThighCalfT_Left,
      ...crpSeriesDataThighCCalfT_Left,
      ...crpSeriesDataThighCalf_Left,
    ]);

    const coordinativeVariabilityDataAllKnee_Left = toVariabilityData(
      data?.coordinativeVariability.coordinativeVariabilityDataKnee_Left.col0,
    );
    const coordinativeVariabilitySeriesAllKnee_Left = [
      { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllKnee_Left, color: variabilityAll_Left },
    ];

    const coordinativeVariabilityDataThighCalf_Left = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityThighCalf_Left.col0,
    );
    const coordinativeVariabilitySeriesThighCalf_Left = [
      {
        type: 'area',
        name: 'Thigh (s) / Calf (s)',
        data: coordinativeVariabilityDataThighCalf_Left,
        color: colorSS,
      },
    ];

    const coordinativeVariabilityDataThighCalfT_Left = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityThighCalf_Left.col1,
    );
    const coordinativeVariabilitySeriesThighCalfT_Left = [
      {
        type: 'area',
        name: 'Thigh (s) / Calf (t)',
        data: coordinativeVariabilityDataThighCalfT_Left,
        color: colorST,
      },
    ];

    const coordinativeVariabilityDataThighCCalfT_Left = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityThighCalf_Left.col2,
    );
    const coordinativeVariabilitySeriesThighCCalfT_Left = [
      {
        type: 'area',
        name: 'Thigh (c) / Calf (t)',
        data: coordinativeVariabilityDataThighCCalfT_Left,
        color: colorCT,
      },
    ];

    setCoordinativeVariabilityOverviewKnee_Left([...coordinativeVariabilitySeriesAllKnee_Left]);

    setCoordinativeVariabilitySeriesKnee_Left([
      ...coordinativeVariabilitySeriesAllKnee_Left,
      ...coordinativeVariabilitySeriesThighCalf_Left,
      ...coordinativeVariabilitySeriesThighCalfT_Left,
      ...coordinativeVariabilitySeriesThighCCalfT_Left,
    ]);

    const crpDataThighCalf_Right = toGraphData(data?.crpKneeRight.crpThighCalf_Right);
    const crpSeriesDataThighCalf_Right = [
      { type: 'line', name: 'Thigh (s) / Calf (s)', data: flattenData(crpDataThighCalf_Right), color: colorSS },
    ];

    const crpDataThighCalfT_Right = toGraphData(data?.crpKneeRight.crpThighCalfT_Right);
    const crpSeriesDataThighCalfT_Right = [
      { type: 'line', name: 'Thigh (s) / Calf (t)', data: flattenData(crpDataThighCalfT_Right), color: colorST },
    ];

    const crpDataThighCCalfT_Right = toGraphData(data?.crpKneeRight.crpThighCCalfT_Right);
    const crpSeriesDataThighCCalfT_Right = [
      { type: 'line', name: 'Thigh (c) / Calf (t)', data: flattenData(crpDataThighCCalfT_Right), color: colorCT },
    ];

    setCrpSeriesThighCalf_Right([
      ...crpSeriesDataThighCalfT_Right,
      ...crpSeriesDataThighCCalfT_Right,
      ...crpSeriesDataThighCalf_Right,
    ]);

    const coordinativeVariabilityDataAllKnee_Right = toVariabilityData(
      data?.coordinativeVariability.coordinativeVariabilityDataKnee_Right.col0,
    );
    const coordinativeVariabilitySeriesAllKnee_Right = [
      { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllKnee_Right, color: variabilityAll_Right },
    ];

    const coordinativeVariabilityDataThighCalf_Right = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityThighCalf_Right.col0,
    );
    const coordinativeVariabilitySeriesThighCalf_Right = [
      {
        type: 'area',
        name: 'Thigh (s) / Calf (s)',
        data: coordinativeVariabilityDataThighCalf_Right,
        color: colorSS,
      },
    ];

    const coordinativeVariabilityDataThighCalfT_Right = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityThighCalf_Right.col1,
    );
    const coordinativeVariabilitySeriesThighCalfT_Right = [
      {
        type: 'area',
        name: 'Thigh (s) / Calf (t)',
        data: coordinativeVariabilityDataThighCalfT_Right,
        color: colorST,
      },
    ];

    const coordinativeVariabilityDataThighCCalfT_Right = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityThighCalf_Right.col2,
    );
    const coordinativeVariabilitySeriesThighCCalfT_Right = [
      {
        type: 'area',
        name: 'Thigh (c) / Calf (t)',
        data: coordinativeVariabilityDataThighCCalfT_Right,
        color: colorCT,
      },
    ];

    setCoordinativeVariabilityOverviewKnee_Right([...coordinativeVariabilitySeriesAllKnee_Right]);

    setCoordinativeVariabilitySeriesKnee_Right([
      ...coordinativeVariabilitySeriesAllKnee_Right,
      ...coordinativeVariabilitySeriesThighCalf_Right,
      ...coordinativeVariabilitySeriesThighCalfT_Right,
      ...coordinativeVariabilitySeriesThighCCalfT_Right,
    ]);

    // !! ========================================== Ankle ========================================== !! //
    const crpDataCalfFoot_Left = toGraphData(data?.crpAnkleLeft.crpCalfFoot_Left);
    const crpSeriesDataCalfFoot_Left = [
      { type: 'line', name: 'Calf (s) / Foot (s)', data: flattenData(crpDataCalfFoot_Left), color: colorSS },
    ];

    const crpDataCalfTFootC_Left = toGraphData(data?.crpAnkleLeft.crpCalfTFootC_Left);
    const crpSeriesDataCalfTFootC_Left = [
      { type: 'line', name: 'Calf (t) / Foot (c)', data: flattenData(crpDataCalfTFootC_Left), color: colorTC },
    ];

    setCrpSeriesCalfFoot_Left([...crpSeriesDataCalfTFootC_Left, ...crpSeriesDataCalfFoot_Left]);

    const coordinativeVariabilityDataAllAnkle_Left = toVariabilityData(
      data?.coordinativeVariability.coordinativeVariabilityDataAnkle_Left.col0,
    );
    const coordinativeVariabilitySeriesAllAnkle_Left = [
      { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllAnkle_Left, color: variabilityAll_Left },
    ];

    const coordinativeVariabilityDataCalfFoot_Left = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityCalfFoot_Left.col0,
    );
    const coordinativeVariabilitySeriesCalfFoot_Left = [
      {
        type: 'area',
        name: 'Calf (s) / Foot (s)',
        data: coordinativeVariabilityDataCalfFoot_Left,
        color: colorSS,
      },
    ];
    const coordinativeVariabilityDataCalfTFootC_Left = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityCalfFoot_Left.col1,
    );
    const coordinativeVariabilitySeriesCalfTFootC_Left = [
      {
        type: 'area',
        name: 'Calf (t) / Foot (c)',
        data: coordinativeVariabilityDataCalfTFootC_Left,
        color: colorTC,
      },
    ];

    setCoordinativeVariabilityOverviewAnkle_Left([...coordinativeVariabilitySeriesAllAnkle_Left]);

    setCoordinativeVariabilitySeriesAnkle_Left([
      ...coordinativeVariabilitySeriesAllAnkle_Left,
      ...coordinativeVariabilitySeriesCalfFoot_Left,
      ...coordinativeVariabilitySeriesCalfTFootC_Left,
    ]);

    const crpDataCalfFoot_Right = toGraphData(data?.crpAnkleRight.crpCalfFoot_Right);
    const crpSeriesDataCalfFoot_Right = [
      { type: 'line', name: 'Calf (s) / Foot (s)', data: flattenData(crpDataCalfFoot_Right), color: colorSS },
    ];

    const crpDataCalfTFootC_Right = toGraphData(data?.crpAnkleRight.crpCalfTFootC_Right);
    const crpSeriesDataCalfTFootC_Right = [
      { type: 'line', name: 'Calf (t) / Foot (c)', data: flattenData(crpDataCalfTFootC_Right), color: colorTC },
    ];

    setCrpSeriesCalfFoot_Right([...crpSeriesDataCalfTFootC_Right, ...crpSeriesDataCalfFoot_Right]);

    const coordinativeVariabilityDataAllAnkle_Right = toVariabilityData(
      data?.coordinativeVariability.coordinativeVariabilityDataAnkle_Right.col0,
    );
    const coordinativeVariabilitySeriesAllAnkle_Right = [
      {
        type: 'area',
        name: 'All planes',
        data: coordinativeVariabilityDataAllAnkle_Right,
        color: variabilityAll_Right,
      },
    ];

    const coordinativeVariabilityDataCalfFoot_Right = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityCalfFoot_Right.col0,
    );
    const coordinativeVariabilitySeriesCalfFoot_Right = [
      {
        type: 'area',
        name: 'Calf (s) / Foot (s)',
        data: coordinativeVariabilityDataCalfFoot_Right,
        color: colorSS,
      },
    ];
    const coordinativeVariabilityDataCalfTFootC_Right = toVariabilityData(
      data?.coordinativeVariabilitySquat.coordinativeVariabilityCalfFoot_Right.col1,
    );
    const coordinativeVariabilitySeriesCalfTFootC_Right = [
      {
        type: 'area',
        name: 'Calf (t) / Foot (c)',
        data: coordinativeVariabilityDataCalfTFootC_Right,
        color: colorTC,
      },
    ];

    setCoordinativeVariabilityOverviewAnkle_Right([...coordinativeVariabilitySeriesAllAnkle_Right]);

    setCoordinativeVariabilitySeriesAnkle_Right([
      ...coordinativeVariabilitySeriesAllAnkle_Right,
      ...coordinativeVariabilitySeriesCalfFoot_Right,
      ...coordinativeVariabilitySeriesCalfTFootC_Right,
    ]);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [data]);

  useEffect(() => {
    if (!loading) {
      setPelvisLegenda(
        createLegend(
          crpSeriesPelvisThigh_Left,
          coordinativeVariabilityOverviewHip_Left,
          coordinativeVariabilityOverviewHip_Right,
        ),
      );
      setKneeLegenda(
        createLegend(
          crpSeriesThighCalf_Left,
          coordinativeVariabilityOverviewKnee_Left,
          coordinativeVariabilityOverviewKnee_Right,
        ),
      );
      setAnkleLegenda(
        createLegend(
          crpSeriesCalfFoot_Left,
          coordinativeVariabilityOverviewAnkle_Left,
          coordinativeVariabilityOverviewAnkle_Right,
        ),
      );
    }
  }, [loading]);

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else if (loading) {
    return <IonLoading isOpen={loading} message={'Drawing graphs, one moment please...'} />;
  } else {
    return (
      <>
        {/* <IonPopover
          cssClass='legend_popover'
          event={showLegenda.event}
          isOpen={showLegenda.showLegenda}
          show-backdrop={false}
          onDidDismiss={() => setShowLegenda({ showLegenda: false, event: undefined, joint: '' })}
          mode='ios'>
          <IonList>{showLegenda.joint === 'Hip' && <VariabilityGraphLegenda legend={pelvisLegenda} />}</IonList>
          <IonList>{showLegenda.joint === 'Knee' && <VariabilityGraphLegenda legend={kneeLegenda} />}</IonList>
          <IonList>{showLegenda.joint === 'Ankle' && <VariabilityGraphLegenda legend={ankleLegenda} />}</IonList>
        </IonPopover> */}
        <IonModal
          isOpen={showMoreInfoModal.show}
          onDidDismiss={() => setShowMoreInfoModal({ show: false, joint: '' })}
          css-class='ORYX_Modal_Large'>
          {showMoreInfoModal.joint === 'Hip' && (
            <VariabilityModalContent
              crp_Left={crpSeriesPelvisThigh_Left}
              crp_Right={crpSeriesPelvisThigh_Right}
              coordinativeVariabilityData_Left={coordinativeVariabilitySeriesHip_Left}
              coordinativeVariabilityData_Right={coordinativeVariabilitySeriesHip_Right}
              setShowMoreInfoModal={setShowMoreInfoModal}
            />
          )}
          {showMoreInfoModal.joint === 'Knee' && (
            <VariabilityModalContent
              crp_Left={crpSeriesThighCalf_Left}
              crp_Right={crpSeriesThighCalf_Right}
              coordinativeVariabilityData_Left={coordinativeVariabilitySeriesKnee_Left}
              coordinativeVariabilityData_Right={coordinativeVariabilitySeriesKnee_Right}
              setShowMoreInfoModal={setShowMoreInfoModal}
            />
          )}
          {showMoreInfoModal.joint === 'Ankle' && (
            <VariabilityModalContent
              crp_Left={crpSeriesCalfFoot_Left}
              crp_Right={crpSeriesCalfFoot_Right}
              coordinativeVariabilityData_Left={coordinativeVariabilitySeriesAnkle_Left}
              coordinativeVariabilityData_Right={coordinativeVariabilitySeriesAnkle_Right}
              setShowMoreInfoModal={setShowMoreInfoModal}
            />
          )}
        </IonModal>

        <IonPage>
          <IonContent className='ion-padding' color='light'>
            <IonGrid fixed>
              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol
                        size='2'
                        className='ion-align-self-stretch'
                        onClick={() => setShowMoreInfoModal({ show: true, joint: 'Hip' })}>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='9' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle'>Hip</p>
                          </IonCol>
                          <IonCol size='3' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-center Vertical'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Hip' })}>
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center'>
                          <IonList>
                            <VariabilityGraphLegenda legend={pelvisLegenda} />
                          </IonList>
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                        <FunctionalMovementVariabilityGraph
                          crpData={crpSeriesPelvisThigh_Left}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewHip_Left}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                        <FunctionalMovementVariabilityGraph
                          crpData={crpSeriesPelvisThigh_Right}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewHip_Right}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    {/* <IonRow className='ion-justify-content-center ion-align-items-center '>
                        <IonCol size='11' className=''>
                          <p className='ORYX_GraphTitle'>Knee</p>
                        </IonCol>
                        <IonCol size='1' className='Test'>
                          <IonButtons className='ion-justify-content-center'>
                            <IonButton
                              slot='icon-only'
                              fill='clear'
                              className='attractorCard_Header_Buttons'
                              onClick={(e: any) => {
                                e.persist();

                                setShowLegenda({ showLegenda: true, event: e, joint: 'Knee' });
                              }}>
                              <IonIcon icon={informationCircleOutline} />
                            </IonButton>
                            <IonButton
                              slot='icon-only'
                              fill='clear'
                              className='attractorCard_Header_Buttons'
                              onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}>
                              <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                            </IonButton>
                          </IonButtons>
                        </IonCol>
                      </IonRow> */}
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol
                        size='2'
                        className='ion-align-self-stretch'
                        onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='9' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle'>Knee</p>
                          </IonCol>
                          <IonCol size='3' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-center Vertical'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}>
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center'>
                          <IonList>
                            <VariabilityGraphLegenda legend={kneeLegenda} />
                          </IonList>
                        </IonRow>
                        {/* <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                            <IonButtons className='ion-justify-content-center Vertical'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='attractorCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}>
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonRow> */}
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding'>
                        <FunctionalMovementVariabilityGraph
                          crpData={crpSeriesThighCalf_Left}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewKnee_Left}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding'>
                        <FunctionalMovementVariabilityGraph
                          crpData={crpSeriesThighCalf_Right}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewKnee_Right}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol
                        size='2'
                        className='ion-align-self-stretch ion-align-items-stretch'
                        onClick={() => setShowMoreInfoModal({ show: true, joint: 'Ankle' })}>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='9' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle'>Ankle</p>
                          </IonCol>
                          <IonCol size='3' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-center Vertical'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Ankle' })}>
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center'>
                          <IonList>
                            <VariabilityGraphLegenda legend={ankleLegenda} />
                          </IonList>
                        </IonRow>
                        {/* <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                            <IonButtons className='ion-justify-content-center Vertical'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='attractorCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Ankle' })}>
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonRow> */}
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding'>
                        <FunctionalMovementVariabilityGraph
                          crpData={crpSeriesCalfFoot_Left}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewAnkle_Left}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding'>
                        <FunctionalMovementVariabilityGraph
                          crpData={crpSeriesCalfFoot_Right}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewAnkle_Right}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </>
    );
  }
};
