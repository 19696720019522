/** Upload the csv file to Firebase Cloud Storage */
import firebase from 'firebase/app';
import 'firebase/storage';
import { useEffect, useState } from 'react';
import { myFirebase } from '../config/firebaseConfig';

export type UploadDataResponse = { metaData: firebase.storage.FullMetadata } | undefined;
export type ProgressResponse = { value: number } | undefined | null;
export type DataAsDataUrl = { dataUrl: string; format: string };
export type FileData = File | DataAsDataUrl;
export type reportOption = 'Walk' | 'Run' | 'Squat' | 'SLSquat' | 'undefined';
export type UploadSource = { user_id: string; tags: string; option: reportOption; file: FileData };

// the firebase reference to cloud storage
const storageRef = myFirebase.storage().ref();

/** Hook to upload files to the default Firebase Cloud Storage bucket
 * setUploadData to provide the file to upload;
 * dataResponse is the file metadata returned from the upload.
 */
export function useFirebaseUpload(): {
  dataResponse: UploadDataResponse;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  progress: ProgressResponse;
  setUploadData: (value: UploadSource) => void;
  // setUploadData: React.Dispatch<React.SetStateAction<UploadSource | undefined>>;
  clearError: () => void;
} {
  // the data from the file upload response
  const [dataResponse, setDataResponse] = useState<UploadDataResponse>();

  // sets properties (e.g. filename) on the file to be uploaded
  const [uploadData, setUploadData] = useState<UploadSource>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [progress, setProgress] = useState<ProgressResponse>(null);

  // Called to close the error Toast
  const clearError = () => {
    setIsError(false);
  };

  // this function will only be called when the properties in the dependency array
  // [uploadData] changes
  useEffect(() => {
    function uploadPath(user_id: string, filename: string): string {
      const timestamp_ms = new Date().getTime();
      const timestamp_sec = Math.trunc(timestamp_ms / 1000);
      return `users/${user_id}/${timestamp_sec}/${filename}`;
    }

    const setUp = ({ user_id, tags, option, file }: UploadSource): firebase.storage.UploadTask => {
      const metadata = {
        customMetadata: {
          tags: tags,
          option: option,
        },
      };

      if (file instanceof File) {
        const path = uploadPath(user_id, file.name);

        // Setting the firebase properties for the file upload
        // triggering the actual upload.
        const ref = storageRef.child(path);
        if (process.env.NODE_ENV !== 'production') console.log('uploading ref', ref);
        return ref.put(file, metadata);
      } else {
        const v = file as DataAsDataUrl;
        const path = uploadPath(user_id, v.format);

        // Setting the firebase properties for the file upload
        // triggering the actual upload.
        const ref = storageRef.child(path);
        return ref.putString(v.dataUrl, 'data_url', metadata);
      }
    };

    const upload = async (uploadData: UploadSource) => {
      setIsError(false);
      setIsLoading(true);

      setProgress({ value: 0 });

      // handle a file upload or a dataUrl upload
      const uploadTask = setUp(uploadData);

      // wrap the whole thing in a try catch block to update the error state
      try {
        // tracking the state of the upload to assist in updating the
        // application UI
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (progress) => {
            const value = progress.bytesTransferred / progress.totalBytes;
            if (process.env.NODE_ENV !== 'production') console.log('Upload is ' + value * 100 + '% done');
            setProgress({ value });
          },
          (error) => {
            setIsLoading(false);
            setErrorMessage(error.message);
          },
          () => {
            setIsError(false);
            setIsLoading(false);
            setDataResponse({
              metaData: uploadTask.snapshot.metadata,
            });
            setProgress(null);
          },
        );
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };

    uploadData && upload(uploadData);
  }, [uploadData]);

  return {
    dataResponse,
    isLoading,
    isError,
    errorMessage,
    progress,
    setUploadData,
    clearError,
  };
}
