import React from 'react';
import { IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonImg, IonCardSubtitle, IonIcon } from '@ionic/react';
import './modalindex.css';
import IBimageHipextensionTerminalStance1 from './assets/IBimageHipextensionTerminalStance1.png';
import IBimageHipextensionTerminalStance2 from './assets/IBimageHipextensionTerminalStance2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import '@ionic/react/css/ionic-swiper.css';

export const ModalHipExtensionTerminalStance: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Hip Extension</IonCardTitle>
          <IonCardSubtitle className='reportInfoModal_Subtitle'>Terminal Stance</IonCardSubtitle>
        </div>
      </IonCardHeader>

      <Swiper modules={[Pagination]} pagination={true} initialSlide={0} speed={400} direction='vertical'>
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageHipextensionTerminalStance1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  In dynamic walking the leg works like a pendulum. When it is extended to optimal length, it will swing
                  forward after a propulsive push off by ankle plantar flexion. With exception of some adductor activity
                  (which control the path of the swinging leg) this will hardly consume any energy.
                  <br></br>
                  <br></br>
                  Lengthening the leg is a movement pattern which emerges from joint coupling, accomplished by extending
                  the hip first, then the knee and finally the ankle. The sequence of these movements (proximal to
                  distal) is based on a fixed principle which depends on the position in which the muscles can generate
                  a sufficiently large moment relative to the joint to be moved.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageHipextensionTerminalStance2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Without proper hip extension, the knee will probably not extend properly as well. Finally it is not
                  urgent for the ankle to plantar flex, leading to a weak push-off.
                  <br></br>
                  <br></br>
                  As a result the trajectory of the pendulum will shorten, stride length reduces, velocity falls,
                  momentum is lost and balance is likely to decrease.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
