import { RearPendulumPointsOfInterests_Left, RunReportData, StridesData } from '../../../../model';
import {
  colorCS,
  colorCT,
  colorLeft,
  colorRight,
  colorSS,
  colorST,
  colorTC,
  variabilityAll_Left,
  variabilityAll_Right,
} from '../CustomGraphComponents';
import { uuid } from 'uuidv4';

export function toScatterData(xData: StridesData, yData: StridesData) {
  const numCycles = Object.keys(xData).length;
  const scatterData: { x: number; y: number }[][] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number }[] = [];

    xData[`col${i}`].forEach((xValue: any, index: string | number) => {
      const yValue = yData[`col${i}`][index];
      cycleData.push({ x: xValue, y: yValue });
    });

    scatterData.push(cycleData);
  }

  return scatterData;
}

export function combinePointsOfInterest(points: RearPendulumPointsOfInterests_Left, xIndex: number, yIndex: number) {
  const numCycles = Object.keys(points).length;
  const scatterData: { x: number; y: number }[] = [];

  for (let i = 0; i < numCycles; i++) {
    scatterData.push({
      x: points[`col${i}`][xIndex],
      y: points[`col${i}`][yIndex],
    });
  }

  return scatterData;
}

export function calculateMean(points: { x: number; y: number }[] = []) {
  const sum = points.reduce(
    (acc, point) => {
      return {
        x: acc.x + point.x,
        y: acc.y + point.y,
      };
    },
    { x: 0, y: 0 },
  );

  const mean = {
    x: sum.x / points.length,
    y: sum.y / points.length,
  };

  return mean;
}

// Calculate the mean and add the errordata to the mean
export function calculateErrorData(points: { x: number; y: number }[] = [], errordata: number) {
  const mean = calculateMean(points);
  // Add the errordata to the mean:
  const errorData = {
    x: mean.x,
    y: mean.y + errordata,
    errordata: errordata,
  };

  return errorData;
}

export function getfootplantCoordinates(
  data: { [key: string]: number[] },
  columnIndex: number,
): { x: number; y: number }[] {
  const xyValues = [];
  const numCycles = Object.keys(data).length;
  for (let i = 0; i < numCycles; i++) {
    xyValues.push({ x: data[`col${i}`][columnIndex], y: 0 });
  }
  return xyValues;
}

type GraphData = any;
/* Convert data from: (dict of colum ids -> array of values) to
 * an array of {x: value, colId: value} dict entries.
 */
export function toLineGraphData(data: any): GraphData {
  return data.col0.map((value: number, index: number) => {
    const entry: GraphData = {};
    entry.x = index;
    Object.keys(data)
      .sort()
      .forEach((colId: string) => {
        entry[colId] = data[colId][index];
      });
    return entry;
  });
}

// change the structure of the data from col[index] to {name: side, data: [{x:number, y:number}[]]}
/**
 * Function to create data series where the index is used as the x value. after the end of each column
 * a null value is returned. returns:
 * {
      x: number;
      y: number | null;
    }[][]
 */
export function toGraphData(data: StridesData | number[] | undefined) {
  if (!data) {
    return [];
  }
  if (Array.isArray(data)) {
    return data.map((xValue: number, index: number) => {
      return { x: index, y: xValue };
    });
  }
  const numCycles = Object.keys(data).length;
  const graphData: { x: number; y: number | null }[][] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number | null }[] = [];

    data[`col${i}`].forEach((xValue: any, index: number) => {
      if (index === data[`col${i}`].length - 1) {
        cycleData.push({ x: index, y: null });
      } else {
        cycleData.push({ x: index, y: xValue });
      }
    });

    graphData.push(cycleData);
  }

  return graphData;
}

/**
 * Function to flatten the data from the above function to create 1 dataseries (to show all left strides
 * in one color for example)
 */
export function flattenData(data: any[]) {
  return data.reduce((acc: any, curr: any) => {
    return [...acc, ...curr];
  }, []);
}

/**
 * Function to create tuples for scatterdata where the x and y values are in 1 column.
 * used in the scissors for example to plot the scissors dots. returns:
 * {
      x: number;
      y: number | null;
    }[][]
 */
export function toScissorsData(data: any) {
  if (process.env.NODE_ENV !== 'production') console.log('in scissors data', data);
  if (!data) {
    return [];
  }
  const numCycles = Object.keys(data).length;
  const graphData: { x: number; y: number | null }[][] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number | null }[] = [];

    cycleData.push({ x: data[`col${i}`][0], y: data[`col${i}`][1] });

    graphData.push(cycleData);
  }

  return graphData;
}

export function xyToScissorsData(xData: any, yData: any) {
  if (!xData) {
    return [];
  }
  const numCycles = Object.keys(xData).length;
  const graphData: { x: number; y: number | null }[][] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number | null }[] = [];

    cycleData.push({ x: xData[`col${i}`][0], y: yData[`col${i}`][1] });

    graphData.push(cycleData);
  }

  return graphData;
}

/**
 * Function to combine the data of two different joints/segments where the first parameter
 * becomes the yValue, and the second parameter becomes the xvalue for each strides. Returns an object in the form:
 * {
      type: string;
      name: string;
      data: {
          x: number;
          y: number;
      }[];
    }[]
 */
export function toJointCouplingData(kneeAngles: StridesData, hipAngles: StridesData) {
  const numCycles = Object.keys(kneeAngles).length;

  const graphData: { x: number; y: number }[][] = [];
  const serie: { type: string; name: string; data: { x: number; y: number }[] }[] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number }[] = [];

    kneeAngles[`col${i}`].forEach((yValue: any, index: number) => {
      const xValue = hipAngles[`col${i}`][index];
      cycleData.push({ x: xValue, y: yValue });
    });
    serie.push({ type: 'line', name: `stride ${i + 1}`, data: cycleData });
  }

  return serie;
}

/**
 * Function to which creates tuples of x and y of 1 column for the areaplot.
 * the xValue is the index, and the yValue is the value. returns
 * {
      x: number;
      y: number;
    }[]
 */
export function toVariabilityData(data: number[] | undefined) {
  if (!data) {
    return [];
  }
  const seriesData = data.map((value, index) => {
    return {
      x: index,
      y: value,
    };
  });
  return seriesData;
}

/**
 * Function to which creates tuples of x and y of each column (every stride)
 */
export function toLineGraphDataStrides(data: StridesData) {
  const numCycles = Object.keys(data).length;

  const graphData: { x: number; y: number }[][] = [];
  const serie: { name: string; data: { x: number; y: number }[] }[] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: { x: number; y: number }[] = [];

    data[`col${i}`].forEach((yValue: any, index: number) => {
      const xValue = index;
      cycleData.push({ x: xValue, y: yValue });
    });
    serie.push({ name: `stride ${i + 1}`, data: cycleData });
  }

  return serie;
}

export function calculateSymmetry(Left: any, Right: any, accuracy: number) {
  const result = Math.abs((Left - Right) / (0.5 * Left + Right)) * 100;
  return result.toFixed(accuracy);
}

export function getLeftRightSeriesData(leftData: StridesData | undefined, rightData: StridesData | undefined) {
  const left = toGraphData(leftData);
  const seriesLeft = [{ type: 'line', name: 'Left', data: flattenData(left), color: colorLeft }];
  const right = toGraphData(rightData);
  const seriesRight = [{ type: 'line', name: 'Right', data: flattenData(right), color: colorRight }];
  const series = [...seriesLeft, ...seriesRight];
  return series;
}

export function getLeftRightSerieData(leftData: StridesData | undefined, rightData: StridesData | undefined) {
  const left = toGraphData(leftData);
  const seriesLeft = [{ type: 'line', name: 'Left', data: left, color: colorLeft }];
  const right = toGraphData(rightData);
  const seriesRight = [{ type: 'line', name: 'Right', data: right, color: colorRight }];
  const series = [...seriesLeft, ...seriesRight];
  return series;
}

// Create legend from the names of the entries in the crp + coordinativeVariability Overview
export function createLegend(
  crpData: any,
  coordinativeVariabilityData_Left: any,
  coordinativeVariabilityData_Right: any,
): any[] {
  const crpLegend = crpData.map((entry: any) => {
    return {
      id: uuid(),
      name: entry.name,
      color: entry.color,
    };
  });

  const coordinativeVariabilityLegend_Left = coordinativeVariabilityData_Left.map((entry: any) => {
    return {
      id: uuid(),
      name: entry.name,
      color: entry.color,
      side: 'Left',
    };
  });

  const coordinativeVariabilityLegend_Right = coordinativeVariabilityData_Right.map((entry: any) => {
    return {
      id: uuid(),
      name: entry.name,
      color: entry.color,
      side: 'Right',
    };
  });

  return [...crpLegend, ...coordinativeVariabilityLegend_Left, ...coordinativeVariabilityLegend_Right];
}

// Create legend from the names of the entries in the crp + coordinativeVariability Overview
export function createCRPLegend(crpData: any): any[] {
  const crpLegend = crpData.map((entry: any) => {
    return {
      id: uuid(),
      name: entry.name,
      color: entry.color,
    };
  });

  return [...crpLegend];
}

// =========================================================== Variability Data functions ===========================================================
export function getPelvisVariabilityData_Left(data: RunReportData | undefined) {
  const crpDataPelvisThigh_Left = toGraphData(data?.crpHipLeft.crpPelvisThigh_Left);
  const crpSeriesDataPelvisThigh_Left = [
    { type: 'line', name: 'Pelvis (s) / Thigh (s)', data: flattenData(crpDataPelvisThigh_Left), color: colorSS },
  ];

  const meanCrpDataPelvisThigh_Left = toVariabilityData(data?.crpMean.meanCrpPelvisThigh.col6);
  const meanCrpSeriesDataPelvisThigh_Left = [
    { type: 'line', name: 'Pelvis (s) / Thigh (s)', data: meanCrpDataPelvisThigh_Left, color: colorSS },
  ];

  const crpDataPelvisThighT_Left = toGraphData(data?.crpHipLeft.crpPelvisThighT_Left);
  const crpSeriesDataPelvisThighT_Left = [
    { type: 'line', name: 'Pelvis (s) / Thigh (t)', data: flattenData(crpDataPelvisThighT_Left), color: colorST },
  ];

  const meanCrpDataPelvisThighT_Left = toVariabilityData(data?.crpMean.meanCrpPelvisThigh.col2);
  const meanCrpSeriesDataPelvisThighT_Left = [
    { type: 'line', name: 'Pelvis (s) / Thigh (t)', data: meanCrpDataPelvisThighT_Left, color: colorST },
  ];

  const crpDataPelvisCThighT_Left = toGraphData(data?.crpHipLeft.crpPelvisCThighT_Left);
  const crpSeriesDataPelvisCThighT_Left = [
    { type: 'line', name: 'Pelvis (c) / Thigh (t)', data: flattenData(crpDataPelvisCThighT_Left), color: colorCT },
  ];

  const meanCrpDataPelvisCThighT_Left = toVariabilityData(data?.crpMean.meanCrpPelvisThigh.col4);
  const meanCrpSeriesDataPelvisCThighT_Left = [
    { type: 'line', name: 'Pelvis (c) / Thigh (t)', data: meanCrpDataPelvisCThighT_Left, color: colorCT },
  ];

  const crpDataPelvisCThigh_Left = toGraphData(data?.crpHipLeft.crpPelvisCThigh_Left);
  const crpSeriesDataPelvisCThigh_Left = [
    { type: 'line', name: 'Pelvis (c) / Thigh (s)', data: flattenData(crpDataPelvisCThigh_Left), color: colorCS },
  ];

  const meanCrpDataPelvisCThigh_Left = toVariabilityData(data?.crpMean.meanCrpPelvisThigh.col0);
  const meanCrpSeriesDataPelvisCThigh_Left = [
    { type: 'line', name: 'Pelvis (c) / Thigh (s)', data: meanCrpDataPelvisCThigh_Left, color: colorCS },
  ];

  const coordinativeVariabilityDataAllHip_Left = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataHip_Left.col0,
  );

  const coordinativeVariabilitySeriesAllHip_Left = [
    { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllHip_Left, color: variabilityAll_Left },
  ];

  const coordinativeVariabilityDataPelvisThigh_Left = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityPelvisThigh_Left.col0,
  );

  const coordinativeVariabilitySeriesPelvisThigh_Left = [
    {
      type: 'area',
      name: 'Pelvis (s) / Thigh (s)',
      data: coordinativeVariabilityDataPelvisThigh_Left,
      color: colorSS,
    },
  ];

  const coordinativeVariabilityDataPelvisThighT_Left = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityPelvisThigh_Left.col2,
  );

  const coordinativeVariabilitySeriesPelvisThighT_Left = [
    {
      type: 'area',
      name: 'Pelvis (s) / Thigh (t)',
      data: coordinativeVariabilityDataPelvisThighT_Left,
      color: colorST,
    },
  ];

  const coordinativeVariabilityDataPelvisCThighT_Left = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityPelvisThigh_Left.col3,
  );

  const coordinativeVariabilitySeriesPelvisCThighT_Left = [
    {
      type: 'area',
      name: 'Pelvis (c) / Thigh (t)',
      data: coordinativeVariabilityDataPelvisCThighT_Left,
      color: colorCT,
    },
  ];

  const coordinativeVariabilityDataPelvisCThigh_Left = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityPelvisThigh_Left.col1,
  );

  const coordinativeVariabilitySeriesPelvisCThigh_Left = [
    {
      type: 'area',
      name: 'Pelvis (c) / Thigh (s)',
      data: coordinativeVariabilityDataPelvisCThigh_Left,
      color: colorCS,
    },
  ];

  const coordinativeVariabilityDataMeanHip_Left = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataHip_Left.col2,
  );
  const coordinativeVariabilitySeriesMeanHip_Left = [
    {
      type: 'area',
      name: 'All planes',
      data: coordinativeVariabilityDataMeanHip_Left,
      color: variabilityAll_Left,
    },
  ];
  return {
    crpSeriesDataPelvisThighT_Left,
    meanCrpSeriesDataPelvisThigh_Left,
    crpSeriesDataPelvisCThighT_Left,
    meanCrpSeriesDataPelvisThighT_Left,
    crpSeriesDataPelvisCThigh_Left,
    meanCrpSeriesDataPelvisCThighT_Left,
    crpSeriesDataPelvisThigh_Left,
    meanCrpSeriesDataPelvisCThigh_Left,
    coordinativeVariabilitySeriesAllHip_Left,
    coordinativeVariabilitySeriesMeanHip_Left,
    coordinativeVariabilitySeriesPelvisThigh_Left,
    coordinativeVariabilitySeriesPelvisThighT_Left,
    coordinativeVariabilitySeriesPelvisCThighT_Left,
    coordinativeVariabilitySeriesPelvisCThigh_Left,
  };
}

export function getPelvisVariabilityData_Right(data: RunReportData | undefined) {
  const crpDataPelvisThigh_Right = toGraphData(data?.crpHipRight.crpPelvisThigh_Right);
  const crpSeriesDataPelvisThigh_Right = [
    { type: 'line', name: 'Pelvis (s) / Thigh (s)', data: flattenData(crpDataPelvisThigh_Right), color: colorSS },
  ];

  const meanCrpDataPelvisThigh_Right = toVariabilityData(data?.crpMean.meanCrpPelvisThigh.col7);
  const meanCrpSeriesDataPelvisThigh_Right = [
    { type: 'line', name: 'Pelvis (s) / Thigh (s)', data: meanCrpDataPelvisThigh_Right, color: colorSS },
  ];

  const crpDataPelvisThighT_Right = toGraphData(data?.crpHipRight.crpPelvisThighT_Right);
  const crpSeriesDataPelvisThighT_Right = [
    { type: 'line', name: 'Pelvis (s) / Thigh (t)', data: flattenData(crpDataPelvisThighT_Right), color: colorST },
  ];

  const meanCrpDataPelvisThighT_Right = toVariabilityData(data?.crpMean.meanCrpPelvisThigh.col3);
  const meanCrpSeriesDataPelvisThighT_Right = [
    { type: 'line', name: 'Pelvis (s) / Thigh (t)', data: meanCrpDataPelvisThighT_Right, color: colorST },
  ];

  const crpDataPelvisCThighT_Right = toGraphData(data?.crpHipRight.crpPelvisCThighT_Right);
  const crpSeriesDataPelvisCThighT_Right = [
    { type: 'line', name: 'Pelvis (c) / Thigh (t)', data: flattenData(crpDataPelvisCThighT_Right), color: colorCT },
  ];

  const meanCrpDataPelvisCThighT_Right = toVariabilityData(data?.crpMean.meanCrpPelvisThigh.col5);
  const meanCrpSeriesDataPelvisCThighT_Right = [
    { type: 'line', name: 'Pelvis (c) / Thigh (t)', data: meanCrpDataPelvisCThighT_Right, color: colorCT },
  ];

  const crpDataPelvisCThigh_Right = toGraphData(data?.crpHipRight.crpPelvisCThigh_Right);
  const crpSeriesDataPelvisCThigh_Right = [
    { type: 'line', name: 'Pelvis (c) / Thigh (s)', data: flattenData(crpDataPelvisCThigh_Right), color: colorCS },
  ];

  const meanCrpDataPelvisCThigh_Right = toVariabilityData(data?.crpMean.meanCrpPelvisThigh.col1);
  const meanCrpSeriesDataPelvisCThigh_Right = [
    { type: 'line', name: 'Pelvis (c) / Thigh (s)', data: meanCrpDataPelvisCThigh_Right, color: colorCS },
  ];

  const coordinativeVariabilityDataAllHip_Right = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataHip_Right.col0,
  );
  const coordinativeVariabilitySeriesAllHip_Right = [
    { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllHip_Right, color: variabilityAll_Right },
  ];

  const coordinativeVariabilityDataMeanHip_Right = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataHip_Right.col2,
  );
  const coordinativeVariabilitySeriesMeanHip_Right = [
    {
      type: 'area',
      name: 'All planes',
      data: coordinativeVariabilityDataMeanHip_Right,
      color: variabilityAll_Right,
    },
  ];

  const coordinativeVariabilityDataPelvisThigh_Right = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityPelvisThigh_Right.col0,
  );

  const coordinativeVariabilitySeriesPelvisThigh_Right = [
    {
      type: 'area',
      name: 'Pelvis (s) / Thigh (s)',
      data: coordinativeVariabilityDataPelvisThigh_Right,
      color: colorSS,
    },
  ];

  const coordinativeVariabilityDataPelvisThighT_Right = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityPelvisThigh_Right.col2,
  );

  const coordinativeVariabilitySeriesPelvisThighT_Right = [
    {
      type: 'area',
      name: 'Pelvis (s) / Thigh (t)',
      data: coordinativeVariabilityDataPelvisThighT_Right,
      color: colorST,
    },
  ];

  const coordinativeVariabilityDataPelvisCThighT_Right = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityPelvisThigh_Right.col3,
  );

  const coordinativeVariabilitySeriesPelvisCThighT_Right = [
    {
      type: 'area',
      name: 'Pelvis (c) / Thigh (t)',
      data: coordinativeVariabilityDataPelvisCThighT_Right,
      color: colorCT,
    },
  ];

  const coordinativeVariabilityDataPelvisCThigh_Right = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityPelvisThigh_Right.col1,
  );

  const coordinativeVariabilitySeriesPelvisCThigh_Right = [
    {
      type: 'area',
      name: 'Pelvis (c) / Thigh (s)',
      data: coordinativeVariabilityDataPelvisCThigh_Right,
      color: colorCS,
    },
  ];

  return {
    crpSeriesDataPelvisThighT_Right,
    meanCrpSeriesDataPelvisThigh_Right,
    crpSeriesDataPelvisCThighT_Right,
    meanCrpSeriesDataPelvisThighT_Right,
    crpSeriesDataPelvisCThigh_Right,
    meanCrpSeriesDataPelvisCThighT_Right,
    crpSeriesDataPelvisThigh_Right,
    meanCrpSeriesDataPelvisCThigh_Right,
    coordinativeVariabilitySeriesAllHip_Right,
    coordinativeVariabilitySeriesMeanHip_Right,
    coordinativeVariabilitySeriesPelvisThigh_Right,
    coordinativeVariabilitySeriesPelvisThighT_Right,
    coordinativeVariabilitySeriesPelvisCThighT_Right,
    coordinativeVariabilitySeriesPelvisCThigh_Right,
  };
}

export function getKneeVariabilityData_Left(data: RunReportData | undefined) {
  const crpDataThighCalf_Left = toGraphData(data?.crpKneeLeft.crpThighCalf_Left);
  const crpSeriesDataThighCalf_Left = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (s)',
      data: flattenData(crpDataThighCalf_Left),
      color: colorSS,
    },
  ];

  const meanCrpDataThighCalf_Left = toVariabilityData(data?.crpMean.meanCrpThighCalf.col4);
  const meanCrpSeriesDataThighCalf_Left = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (s)',
      data: meanCrpDataThighCalf_Left,
      color: colorSS,
    },
  ];

  const crpDataThighCCalfT_Left = toGraphData(data?.crpKneeLeft.crpThighCCalfT_Left);
  const crpSeriesDataThighCCalfT_Left = [
    {
      type: 'line',
      name: 'Thigh (c) / Calf (t)',
      data: flattenData(crpDataThighCCalfT_Left),
      color: colorCT,
    },
  ];

  const meanCrpDataThighCCalfT_Left = toVariabilityData(data?.crpMean.meanCrpThighCalf.col2);
  const meanCrpSeriesDataThighCCalfT_Left = [
    {
      type: 'line',
      name: 'Thigh (c) / Calf (t)',
      data: meanCrpDataThighCCalfT_Left,
      color: colorCT,
    },
  ];

  const crpDataThighCalfT_Left = toGraphData(data?.crpKneeLeft.crpThighCalfT_Left);
  const crpSeriesDataThighCalfT_Left = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (t)',
      data: flattenData(crpDataThighCalfT_Left),
      color: colorST,
    },
  ];

  const meanCrpDataThighCalfT_Left = toVariabilityData(data?.crpMean.meanCrpThighCalf.col0);
  const meanCrpSeriesDataThighCalfT_Left = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (t)',
      data: meanCrpDataThighCalfT_Left,
      color: colorST,
    },
  ];

  const coordinativeVariabilityDataAllKnee_Left = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataKnee_Left.col0,
  );
  const coordinativeVariabilitySeriesAllKnee_Left = [
    { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllKnee_Left, color: variabilityAll_Left },
  ];

  const coordinativeVariabilityDataMeanKnee_Left = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataKnee_Left.col2,
  );
  const coordinativeVariabilitySeriesMeanKnee_Left = [
    {
      type: 'area',
      name: 'All planes',
      data: coordinativeVariabilityDataMeanKnee_Left,
      color: variabilityAll_Left,
    },
  ];

  const coordinativeVariabilityDataThighCalf_Left = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityThighCalf_Left.col0,
  );

  const coordinativeVariabilitySeriesThighCalf_Left = [
    {
      type: 'area',
      name: 'Thigh (s) / Calf (s)',
      data: coordinativeVariabilityDataThighCalf_Left,
      color: colorSS,
    },
  ];

  const coordinativeVariabilityDataThighCalfT_Left = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityThighCalf_Left.col1,
  );

  const coordinativeVariabilitySeriesThighCalfT_Left = [
    {
      type: 'area',
      name: 'Thigh (s) / Calf (t)',
      data: coordinativeVariabilityDataThighCalfT_Left,
      color: colorST,
    },
  ];

  const coordinativeVariabilityDataThighCCalfT_Left = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityThighCalf_Left.col2,
  );

  const coordinativeVariabilitySeriesThighCCalfT_Left = [
    {
      type: 'area',
      name: 'Thigh (c) / Calf (t)',
      data: coordinativeVariabilityDataThighCCalfT_Left,
      color: colorCT,
    },
  ];

  return {
    crpSeriesDataThighCalfT_Left,
    meanCrpSeriesDataThighCalf_Left,
    crpSeriesDataThighCCalfT_Left,
    meanCrpSeriesDataThighCCalfT_Left,
    crpSeriesDataThighCalf_Left,
    meanCrpSeriesDataThighCalfT_Left,
    coordinativeVariabilitySeriesAllKnee_Left,
    coordinativeVariabilitySeriesMeanKnee_Left,
    coordinativeVariabilitySeriesThighCalf_Left,
    coordinativeVariabilitySeriesThighCalfT_Left,
    coordinativeVariabilitySeriesThighCCalfT_Left,
  };
}

export function getKneeVariabilityData_Right(data: RunReportData | undefined) {
  const crpDataThighCalf_Right = toGraphData(data?.crpKneeRight.crpThighCalf_Right);
  const crpSeriesDataThighCalf_Right = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (s)',
      data: flattenData(crpDataThighCalf_Right),
      color: colorSS,
    },
  ];

  const meanCrpDataThighCalf_Right = toVariabilityData(data?.crpMean.meanCrpThighCalf.col5);
  const meanCrpSeriesDataThighCalf_Right = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (s)',
      data: meanCrpDataThighCalf_Right,
      color: colorSS,
    },
  ];

  const crpDataThighCCalfT_Right = toGraphData(data?.crpKneeRight.crpThighCCalfT_Right);
  const crpSeriesDataThighCCalfT_Right = [
    {
      type: 'line',
      name: 'Thigh (c) / Calf (t)',
      data: flattenData(crpDataThighCCalfT_Right),
      color: colorCT,
    },
  ];

  const meanCrpDataThighCCalfT_Right = toVariabilityData(data?.crpMean.meanCrpThighCalf.col3);
  const meanCrpSeriesDataThighCCalfT_Right = [
    {
      type: 'line',
      name: 'Thigh (c) / Calf (t)',
      data: meanCrpDataThighCCalfT_Right,
      color: colorCT,
    },
  ];

  const crpDataThighCalfT_Right = toGraphData(data?.crpKneeRight.crpThighCalfT_Right);
  const crpSeriesDataThighCalfT_Right = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (t)',
      data: flattenData(crpDataThighCalfT_Right),
      color: colorST,
    },
  ];

  const meanCrpDataThighCalfT_Right = toVariabilityData(data?.crpMean.meanCrpThighCalf.col1);
  const meanCrpSeriesDataThighCalfT_Right = [
    {
      type: 'line',
      name: 'Thigh (s) / Calf (t)',
      data: meanCrpDataThighCalfT_Right,
      color: colorST,
    },
  ];

  const coordinativeVariabilityDataAllKnee_Right = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataKnee_Right.col0,
  );
  const coordinativeVariabilitySeriesAllKnee_Right = [
    { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllKnee_Right, color: variabilityAll_Right },
  ];

  const coordinativeVariabilityDataMeanKnee_Right = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataKnee_Right.col2,
  );
  const coordinativeVariabilitySeriesMeanKnee_Right = [
    {
      type: 'area',
      name: 'All planes',
      data: coordinativeVariabilityDataMeanKnee_Right,
      color: variabilityAll_Right,
    },
  ];

  const coordinativeVariabilityDataThighCalf_Right = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityThighCalf_Right.col0,
  );

  const coordinativeVariabilitySeriesThighCalf_Right = [
    {
      type: 'area',
      name: 'Thigh (s) / Calf (s)',
      data: coordinativeVariabilityDataThighCalf_Right,
      color: colorSS,
    },
  ];

  const coordinativeVariabilityDataThighCalfT_Right = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityThighCalf_Right.col1,
  );

  const coordinativeVariabilitySeriesThighCalfT_Right = [
    {
      type: 'area',
      name: 'Thigh (s) / Calf (t)',
      data: coordinativeVariabilityDataThighCalfT_Right,
      color: colorST,
    },
  ];

  const coordinativeVariabilityDataThighCCalfT_Right = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityThighCalf_Right.col2,
  );

  const coordinativeVariabilitySeriesThighCCalfT_Right = [
    {
      type: 'area',
      name: 'Thigh (c) / Calf (t)',
      data: coordinativeVariabilityDataThighCCalfT_Right,
      color: colorCT,
    },
  ];
  return {
    crpSeriesDataThighCalfT_Right,
    meanCrpSeriesDataThighCalf_Right,
    crpSeriesDataThighCCalfT_Right,
    meanCrpSeriesDataThighCCalfT_Right,
    crpSeriesDataThighCalf_Right,
    meanCrpSeriesDataThighCalfT_Right,
    coordinativeVariabilitySeriesAllKnee_Right,
    coordinativeVariabilitySeriesMeanKnee_Right,
    coordinativeVariabilitySeriesThighCalf_Right,
    coordinativeVariabilitySeriesThighCalfT_Right,
    coordinativeVariabilitySeriesThighCCalfT_Right,
  };
}

export function getAnkleVariabilityData_Left(data: RunReportData | undefined) {
  const crpDataCalfFoot_Left = toGraphData(data?.crpAnkleLeft.crpCalfFoot_Left);
  const crpSeriesDataCalfFoot_Left = [
    {
      type: 'line',
      name: 'Calf (s) / Foot (s)',
      data: flattenData(crpDataCalfFoot_Left),
      color: colorSS,
    },
  ];

  const meanCrpDataCalfFoot_Left = toVariabilityData(data?.crpMean.meanCrpCalfFoot.col2);
  const meanCrpSeriesDataCalfFoot_Left = [
    {
      type: 'line',
      name: 'Calf (s) / Foot (s)',
      data: meanCrpDataCalfFoot_Left,
      color: colorSS,
    },
  ];

  const crpDataCalfTFootC_Left = toGraphData(data?.crpAnkleLeft.crpCalfTFootC_Left);
  const crpSeriesDataCalfTFootC_Left = [
    {
      type: 'line',
      name: 'Calf (t) / Foot (c)',
      data: flattenData(crpDataCalfTFootC_Left),
      color: colorTC,
    },
  ];

  const meanCrpDataCalfTFootC_Left = toVariabilityData(data?.crpMean.meanCrpCalfFoot.col0);
  const meanCrpSeriesDataCalfTFootC_Left = [
    {
      type: 'line',
      name: 'Calf (t) / Foot (c)',
      data: meanCrpDataCalfTFootC_Left,
      color: colorTC,
    },
  ];

  const coordinativeVariabilityDataAllAnkle_Left = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataAnkle_Left.col0,
  );
  const coordinativeVariabilitySeriesAllAnkle_Left = [
    { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllAnkle_Left, color: variabilityAll_Left },
  ];

  const coordinativeVariabilityDataMeanAnkle_Left = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataAnkle_Left.col2,
  );
  const coordinativeVariabilitySeriesMeanAnkle_Left = [
    {
      type: 'area',
      name: 'All planes',
      data: coordinativeVariabilityDataMeanAnkle_Left,
      color: variabilityAll_Left,
    },
  ];

  const coordinativeVariabilityDataCalfFoot_Left = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityCalfFoot_Left.col0,
  );

  const coordinativeVariabilitySeriesCalfFoot_Left = [
    {
      type: 'area',
      name: 'Calf (s) / Foot (s)',
      data: coordinativeVariabilityDataCalfFoot_Left,
      color: colorSS,
    },
  ];

  const coordinativeVariabilityDataCalfTFootC_Left = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityCalfFoot_Left.col1,
  );

  const coordinativeVariabilitySeriesCalfTFootC_Left = [
    {
      type: 'area',
      name: 'Calf (t) / Foot (c)',
      data: coordinativeVariabilityDataCalfTFootC_Left,
      color: colorTC,
    },
  ];

  return {
    crpSeriesDataCalfTFootC_Left,
    meanCrpSeriesDataCalfFoot_Left,
    crpSeriesDataCalfFoot_Left,
    meanCrpSeriesDataCalfTFootC_Left,
    coordinativeVariabilitySeriesAllAnkle_Left,
    coordinativeVariabilitySeriesMeanAnkle_Left,
    coordinativeVariabilitySeriesCalfFoot_Left,
    coordinativeVariabilitySeriesCalfTFootC_Left,
  };
}

export function getAnkleVariabilityData_Right(data: RunReportData | undefined) {
  const crpDataCalfFoot_Right = toGraphData(data?.crpAnkleRight.crpCalfFoot_Right);
  const crpSeriesDataCalfFoot_Right = [
    {
      type: 'line',
      name: 'Calf (s) / Foot (s)',
      data: flattenData(crpDataCalfFoot_Right),
      color: colorSS,
    },
  ];

  const meanCrpDataCalfFoot_Right = toVariabilityData(data?.crpMean.meanCrpCalfFoot.col3);
  const meanCrpSeriesDataCalfFoot_Right = [
    {
      type: 'line',
      name: 'Calf (s) / Foot (s)',
      data: meanCrpDataCalfFoot_Right,
      color: colorSS,
    },
  ];

  const crpDataCalfTFootC_Right = toGraphData(data?.crpAnkleRight.crpCalfTFootC_Right);
  const crpSeriesDataCalfTFootC_Right = [
    {
      type: 'line',
      name: 'Calf (t) / Foot (c)',
      data: flattenData(crpDataCalfTFootC_Right),
      color: colorTC,
    },
  ];

  const meanCrpDataCalfTFootC_Right = toVariabilityData(data?.crpMean.meanCrpCalfFoot.col1);
  const meanCrpSeriesDataCalfTFootC_Right = [
    {
      type: 'line',
      name: 'Calf (t) / Foot (c)',
      data: meanCrpDataCalfTFootC_Right,
      color: colorTC,
    },
  ];

  const coordinativeVariabilityDataAllAnkle_Right = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataAnkle_Right.col0,
  );
  const coordinativeVariabilitySeriesAllAnkle_Right = [
    { type: 'area', name: 'All planes', data: coordinativeVariabilityDataAllAnkle_Right, color: variabilityAll_Right },
  ];

  const coordinativeVariabilityDataMeanAnkle_Right = toVariabilityData(
    data?.coordinativeVariability.coordinativeVariabilityDataAnkle_Right.col2,
  );
  const coordinativeVariabilitySeriesMeanAnkle_Right = [
    {
      type: 'area',
      name: 'All planes',
      data: coordinativeVariabilityDataMeanAnkle_Right,
      color: variabilityAll_Right,
    },
  ];

  const coordinativeVariabilityDataCalfFoot_Right = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityCalfFoot_Right.col0,
  );

  const coordinativeVariabilitySeriesCalfFoot_Right = [
    {
      type: 'area',
      name: 'Calf (s) / Foot (s)',
      data: coordinativeVariabilityDataCalfFoot_Right,
      color: colorSS,
    },
  ];

  const coordinativeVariabilityDataCalfTFootC_Right = toVariabilityData(
    data?.coordinativeVariabilityPlanes.coordinativeVariabilityCalfFoot_Right.col1,
  );

  const coordinativeVariabilitySeriesCalfTFootC_Right = [
    {
      type: 'area',
      name: 'Calf (t) / Foot (c)',
      data: coordinativeVariabilityDataCalfTFootC_Right,
      color: colorTC,
    },
  ];

  return {
    crpSeriesDataCalfTFootC_Right,
    meanCrpSeriesDataCalfFoot_Right,
    crpSeriesDataCalfFoot_Right,
    meanCrpSeriesDataCalfTFootC_Right,
    coordinativeVariabilitySeriesAllAnkle_Right,
    coordinativeVariabilitySeriesMeanAnkle_Right,
    coordinativeVariabilitySeriesCalfFoot_Right,
    coordinativeVariabilitySeriesCalfTFootC_Right,
  };
}
