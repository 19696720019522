/** Report detail page, holding tabs for analysis, kinematics, and overview */
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
} from '@ionic/react';
import { airplaneOutline, analyticsOutline, bodyOutline, informationCircleOutline } from 'ionicons/icons';
import * as React from 'react';
import { useParams } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { ORYXHeader } from '../../components/menuItems/Header';
import { useReportContext } from '../../components/reportContext';
import { ReportInfoId, ReportType } from '../../model';
import { OverviewReportTab } from './OverviewReportTab';
import { RunAnalysisReportTab } from './RunReport/RunAnalysisReportTab';
import { RunKinematicsReportTab } from './RunReport/RunKinematicsReportTab';
import { WalkAnalysisReportTab } from './WalkReport/WalkAnalysisReportTab';
import { WalkKinematicsReportTab } from './WalkReport/WalkKinematicsReportTab';
import { SquatAnalysisReportTab } from './SquatsReport/SquatsAnalysisReportTab';
import squatter from '../../assets/icons/Squat.png';
import { useEffect, useState } from 'react';
import ORYX_KinematicsIcon from '../../assets/icons/ORYX_KinematicsIcon.svg';
import ORYX_ReportStatusIcon from '../../assets/icons/ORYX_ReportStatusIcon.svg';
import ORYX_InformationIcon from '../../assets/icons/ORYX_InformationIcon.svg';

interface ReportTab {
  url: string;
  title: string;
  status: string;
  icon: string;
}

const Tabs: ReportTab[] = [
  {
    title: 'Analysis',
    url: `analysis`,
    status: 'active',
    icon: ORYX_ReportStatusIcon,
  },
  {
    title: 'Kinematics',
    url: 'kinematics',
    status: 'active',
    icon: ORYX_KinematicsIcon,
  },
  {
    title: 'Overview',
    url: 'overview',
    status: 'active',
    icon: ORYX_InformationIcon,
  },
];

interface ParamTypes {
  id: ReportInfoId;
}
/** Report detail page
 * Loading the report data for the given userId and reportInfoId in the url.
 * Switch the tabs from Walk to Run depending on reportType in the loaded report data.
 * @param reportInfoId - the reportInfoId from the url
 */
export const ReportDetailPage = () => {
  const { id } = useParams<ParamTypes>();
  const [activeRunTab, setActiveRunTab] = useState('Analysis');
  const { reportInfoDict, reportDict, isLoading, isError, clearError, getReportData, dataReady } = useReportContext();
  // if (process.env.NODE_ENV !== 'production') console.log('reportInfoDict', reportInfoDict);
  const reportInfo = reportInfoDict[id];
  const report = reportInfo && reportInfo.reportId ? reportDict[reportInfo.reportId] : undefined;
  // if (process.env.NODE_ENV !== 'production') console.log('report', report);
  const isRun = reportInfo?.reportType === ReportType.RUN;

  // Set custom actions on the tabs
  const handleTabWillChange = (e: any) => {
    setActiveRunTab(e.detail.tab);
  };

  return (
    <IonPage>
      <ORYXHeader backTarget='/reports' loading={!!isLoading} />
      <IonContent className='ion-padding' id='main' color='light'>
        {report && (
          <IonTabs onIonTabsWillChange={handleTabWillChange}>
            <IonRouterOutlet id='main'>
              <Route
                path='/reports/:id/analysis'
                render={(props) => {
                  return isRun ? (
                    <RunAnalysisReportTab {...props} report={report} />
                  ) : (
                    <WalkAnalysisReportTab {...props} report={report} />
                  );
                }}
                exact={true}
              />
              <Route
                path='/reports/:id/kinematics'
                render={(props) => {
                  return isRun ? (
                    <RunKinematicsReportTab {...props} report={report} />
                  ) : (
                    <WalkKinematicsReportTab {...props} report={report} />
                  );
                }}
                exact={true}
              />
              <Route
                path='/reports/:id/overview'
                render={(props) => <OverviewReportTab {...props} report={report} />}
                exact={true}
              />

              <Route path={`/reports/${id}`} render={() => <Redirect to={`/reports/${id}/analysis`} />} exact={true} />
            </IonRouterOutlet>

            <IonTabBar slot='bottom'>
              {Tabs.map((tab, _) => {
                let tabClassNames = '';
                if (tab.status === 'disabled') {
                  tabClassNames = 'disabled';
                } else if (location.pathname === tab.url) {
                  tabClassNames = 'ion-tab-button_selected';
                }
                return (
                  <IonTabButton
                    mode='ios'
                    tab={tab.title}
                    href={`/reports/${id}/${tab.url}`}
                    className={activeRunTab === tab.title ? 'ion-tab-button_selected' : ''}
                    key={tab.title}>
                    <IonIcon icon={tab.icon} />
                    <IonLabel>{tab.title}</IonLabel>
                  </IonTabButton>
                );
              })}
            </IonTabBar>
          </IonTabs>
        )}
      </IonContent>

      <IonToast
        isOpen={isError ? true : false}
        onDidDismiss={() => clearError()}
        message={isError && isError.message}
        color='danger'
        position='bottom'
        buttons={[
          {
            text: 'Close',
            role: 'cancel',
          },
        ]}
      />
    </IonPage>
  );
};
