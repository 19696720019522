import {
  IonCardHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardSubtitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import ORYX_MagnifyingGlassIcon from '../../assets/icons/ORYX_MagnifyingGlassIcon.svg';

interface ORYXCardHeaderProps {
  title?: string;
  subtitle?: string;
  info?: boolean;
  zoom?: boolean;
  setShowInfo?: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
    }>
  >;
  setShowZoom?: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
    }>
  >;
  children?: React.ReactNode;
}

export const ORYXSmallCardHeader = ({
  title,
  info,
  subtitle,
  setShowInfo,
  zoom,
  setShowZoom,
  children,
}: ORYXCardHeaderProps) => {
  return (
    <IonCardHeader className='attractorCard_Header' style={{ height: '35px' }}>
      <IonGrid fixed className='ion-no-padding '>
        <IonRow className='ion-no-padding ion-align-items-center' style={{ paddingTop: '5px' }}>
          <IonCol size='8' className=''>
            <IonCardTitle className='attractorCard_Title'>{title}</IonCardTitle>
          </IonCol>
          {!children && (
            <IonCol size='4' className='ion-justify-content-center ion-padding-end'>
              <IonButtons className='ion-justify-content-end'>
                {zoom && setShowZoom && (
                  <IonButton
                    slot='icon-only'
                    fill='clear'
                    className='attractorCard_Header_Buttons'
                    onClick={() => {
                      console.log('click!');
                      setShowZoom({ show: true });
                    }}>
                    <IonIcon icon={ORYX_MagnifyingGlassIcon} size='small' />
                  </IonButton>
                )}
                {info && setShowInfo && (
                  <IonButton
                    slot='icon-only'
                    fill='clear'
                    className='attractorCard_Header_Buttons'
                    onClick={() => setShowInfo({ show: true })}>
                    <IonIcon icon={informationCircleOutline} size='small' />
                  </IonButton>
                )}
              </IonButtons>
            </IonCol>
          )}
          {children && (
            <IonCol size='4' className='ion-justify-content-center ion-no-padding Test'>
              {children}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonCardHeader>
  );
};
