import Walking from '../../../assets/icons/Walking.png';
import Running from '../../../assets/icons/Running.png';
import Squats from '../../../assets/icons/Squat.png';
import SingleLegSquat from '../../../assets/icons/SingleLegSquat.png';

interface ORYXAnalysis {
  url: string;
  iosIcon: string;
  title: string;
  id: number;
  description: string;
  component: any;
}

export const ORYXAnalyses: ORYXAnalysis[] = [
  {
    id: 1,
    title: 'Walk',
    url: '/docs/ORYX_Analysis/Walk',
    iosIcon: Walking,
    description: 'lorem ipsum..',
    component: null,
  },
  {
    id: 2,
    title: 'Run',
    url: '/docs/ORYX_Analysis/Run',
    iosIcon: Running,
    description: 'description',
    component: null,
  },
  {
    id: 1,
    title: 'Double leg Squat',
    url: '/docs/ORYX_Analysis/Squat',
    iosIcon: Squats,
    description: 'description',
    component: null,
  },
  {
    id: 1,
    title: 'Single Leg Squat',
    url: '/docs/ORYX_Analysis/SingleLegSquat',
    iosIcon: SingleLegSquat,
    description: 'lorem ipsum',
    component: null,
  },
];
