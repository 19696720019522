// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'dotenv/config';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import dotenv from 'dotenv';

// Load environment variables from .env file
dotenv.config();

// Config only for demo!
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBtrrnXKYzSTJUCXaIdwfz90HPJucsgfm8',
  authDomain: 'oryxgo-demo.firebaseapp.com',
  projectId: 'oryxgo-demo',
  storageBucket: 'oryxgo-demo.appspot.com',
  messagingSenderId: '641415023645',
  appId: '1:641415023645:web:e77de9cacdb5c1a2f50af4',
};

// Ensure that you do not initialize twice.
if (!firebase.apps.length) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Initializing Firebase', firebaseConfig);
    console.log('NODE_ENV', process.env.NODE_ENV);
  }
  firebase.initializeApp(firebaseConfig);
}

// Initialize Analytics and get a reference to the service
export const analytics = firebase.analytics();
export const myFirebase = firebase;
