/** Report detail page, holding tabs for analysis, kinematics, and overview */
import { IonContent, IonLoading, IonPage } from '@ionic/react';

import * as React from 'react';
import { useParams } from 'react-router';

import { ORYXHeader } from '../../components/menuItems/Header';
import { useReportContext } from '../../components/reportContext';
import { ReportInfoId, ReportType } from '../../model';

import { useEffect } from 'react';
import { RunTabBar, SingleLegSquatTabBar, WalkTabBar, SquatTabBar } from './components/Tabs';

interface ParamTypes {
  id: ReportInfoId;
}
/** Report detail page
 * Loading the report data for the given userId and reportInfoId in the url.
 * Switch the tabs from Walk to Run depending on reportType in the loaded report data.
 * @param reportInfoId - the reportInfoId from the url
 */
export const ReportDetailPageV2 = () => {
  const { id } = useParams<ParamTypes>();
  const { getReportData, dataReady, data } = useReportContext();
  // Fetch the report data

  useEffect(() => {
    const reportData = getReportData(id);

    // Cleanup the data when the component unmounts
    return () => {
      reportData?.unsubscribe();
      const dataReady = false;
    };
  }, [id]);

  const walkReport = data?.reportId.reportType === ReportType.WALK;
  const runReport = data?.reportId.reportType === ReportType.RUN;
  const squatReport = data?.reportId.reportType === ReportType.SQUAT;
  const singleLegSquatReport = data?.reportId.reportType === ReportType.SLSQUAT;

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') console.log('reportData', data);
  }, [data]);

  return (
    <IonPage>
      <ORYXHeader backTarget='/reports' loading={!dataReady} />
      <IonContent className='ion-padding' id='main' color='light'>
        {!dataReady && !data && (
          <IonLoading cssClass='my-custom-class' isOpen={!dataReady} message={'Fetching data please wait...'} />
        )}

        {walkReport && <WalkTabBar id={id} data={data} />}

        {runReport && <RunTabBar id={id} data={data} />}

        {squatReport && <SquatTabBar id={id} data={data} />}

        {singleLegSquatReport && <SingleLegSquatTabBar id={id} data={data} />}
      </IonContent>
    </IonPage>
  );
};
