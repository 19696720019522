/** The Run Kinematics report tab */
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { RunReportData } from '../../../model';
import { toAttractorLabels } from '../components/Fields';
import { transpose } from '../../../hooks/useReports/transform';
import { ModalPositiveRunningMidstance } from '../components/ModalsRun';
import ankleStiffness from '../../../assets/avatars/run/analysis/AnkleStiffness.png';
import footPlant from '../../../assets/avatars/run/analysis/FootPlant.png';
import hipLock from '../../../assets/avatars/run/analysis/HipLock.png';
import kneeAngleMidswing from '../../../assets/avatars/run/analysis/KneeAngleMidswing.png';
import kneeExtensionPreswing from '../../../assets/avatars/run/analysis/KneeExtensionPreswing.png';
import kneeFlexionMidstance from '../../../assets/avatars/run/analysis/kneeFlexionMidstance.png';
import positiveRunningMidstance from '../../../assets/avatars/run/analysis/PositiveRunningMidstance.png';
import positiveRunningToeOff from '../../../assets/avatars/run/analysis/PositiveRunningToeOff.png';
import projectionFootplant from '../../../assets/avatars/run/analysis/ProjectionFootplant.png';
import scissors from '../../../assets/avatars/run/analysis/Scissors.png';
import swingLegRecovery from '../../../assets/avatars/run/analysis/SwingLegRecovery.png';
import swingLegRetraction from '../../../assets/avatars/run/analysis/SwingLegRetraction.png';
import { AttractorCard } from '../components/Cards';

type AttractorsReportProps = RouteComponentProps & {
  report: RunReportData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunAttractorsReportTab = ({ report }: AttractorsReportProps) => {
  const data = report as RunReportData;

  const attractorData = transpose(data?.reportId.attractors);
  const attractorLabels = toAttractorLabels(data?.reportId.attractors);

  if (!data) {
    return <IonText>Data not found.</IonText>;
  }

  // get the name of the person
  //   const indexToSlice = report.filename.indexOf('-');
  //   const name = report.filename.slice(0, indexToSlice - 1);

  return (
    <IonPage>
      <IonContent className='ion-padding' color='light'>
        {data && (
          <IonGrid fixed>
            <IonRow className='ion-no-padding'>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Ankle stiffness'
                  subtitle='Terminal stance'
                  info={'Ankle Stiffness'}
                  img={ankleStiffness}
                  labels={attractorLabels[1]}
                  attractorRow={attractorData[1]}
                  type='Run'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee extension'
                  subtitle='Terminal stance'
                  info={'Knee extension at Terminal stance'}
                  img={kneeExtensionPreswing}
                  labels={attractorLabels[2]}
                  attractorRow={attractorData[2]}
                  type='Run'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip lock'
                  subtitle='Terminal stance'
                  info={'Hip lock'}
                  img={hipLock}
                  labels={attractorLabels[3]}
                  attractorRow={attractorData[3]}
                  type='Run'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Positive running'
                  subtitle='Toe-off'
                  info={'Positive running at toe-off'}
                  img={positiveRunningToeOff}
                  labels={attractorLabels[0]}
                  attractorRow={attractorData[0]}
                  type='Run'
                />
              </IonCol>
            </IonRow>

            <IonRow className='ion-no-padding'>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Swing leg recovery'
                  subtitle='Initial swing'
                  info={'Swing leg recovery'}
                  img={swingLegRecovery}
                  labels={attractorLabels[5]}
                  attractorRow={attractorData[5]}
                  type='Run'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midswing'
                  info={'Knee flexion at midswing'}
                  img={kneeAngleMidswing}
                  labels={attractorLabels[6]}
                  attractorRow={attractorData[6]}
                  type='Run'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Swing leg retraction'
                  subtitle='Terminal Swing'
                  info={'Swing leg retraction'}
                  img={swingLegRetraction}
                  labels={attractorLabels[9]}
                  attractorRow={attractorData[9]}
                  type='Run'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Scissors motion'
                  subtitle='Terminal swing'
                  info={'Scissors'}
                  img={scissors}
                  labels={attractorLabels[10]}
                  attractorRow={attractorData[10]}
                  type='Run'
                />
              </IonCol>
            </IonRow>

            <IonRow className='ion-no-padding'>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Footplant from above'
                  subtitle='Midstance'
                  info={'Foot plant from above'}
                  img={footPlant}
                  labels={attractorLabels[11]}
                  attractorRow={attractorData[11]}
                  type='Run'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Projection footplant'
                  subtitle='Midstance'
                  info={'Projection footplant'}
                  img={projectionFootplant}
                  labels={attractorLabels[8]}
                  attractorRow={attractorData[8]}
                  type='Run'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midstance'
                  info={'Knee flexion at midstance'}
                  img={kneeFlexionMidstance}
                  labels={attractorLabels[14]}
                  attractorRow={attractorData[14]}
                  type='Run'
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Positive running'
                  subtitle='Midstance'
                  info={'Positive running at midstance'}
                  img={positiveRunningMidstance}
                  labels={attractorLabels[7]}
                  attractorRow={attractorData[7]}
                  type='Run'
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};
