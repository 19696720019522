import 'firebase/firestore';
import { setCache, getCache } from '../../utilities/CacheController';
import { useEffect, useMemo, useState } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import {
  FirebaseTimestamp,
  GatewayInstance,
  GatewaysCollection,
  HardwareCollection,
  HardwareInstance,
  ORYXGOCollection,
  ORYXGOInstance,
  SensorInstance,
  SensorsCollection,
  UserId,
} from '../../model';
import { myFirebase } from '../../config/firebaseConfig';

const db = myFirebase.firestore();
const storage = myFirebase.storage();

type UserStruct = {
  organization_id: string;
  role: string;
  firstName: string;
  lastName: string;
  dateCreated: FirebaseTimestamp;
  status: string;
};

type UseHardwareHook = {
  ORYXGOCollection: ORYXGOCollection;
  isLoading: boolean;
  isError: Error | undefined;
  errorMessage: string;
  clearError: () => void;
  getAvailableORYXGOSystems: (userId: UserId) => Promise<void>;
  getAssignmentsFile: (systemUID: string) => Promise<string>;
};

const HARDWARE_CACHE_KEY = 'hardware_cache';

export const useHardware = (userId: UserId): UseHardwareHook => {
  const [isError, setIsError] = useState<Error | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ORYXGOCollection, setORYXGOCollection] = useState<ORYXGOCollection>({});

  // Called to close the error Toast
  const clearError = () => {
    setIsError(undefined);
  };

  // get the assignments file from the storage bucket
  // the system UID is passed as a parameter
  const getAssignmentsFile = async (systemUID: string) => {
    console.log('getAssignmentsFile: ', systemUID);
    const storageRef = storage.ref(`sensor_assignments/${systemUID}/assignments.dat`);
    const assignmentsFile = await storageRef.getDownloadURL();
    return assignmentsFile;
  };

  async function getAvailableORYXGOSystems(userId: UserId) {
    const userDocRef = db.collection('users').doc(userId);
    // const [userDoc, userDocLoading, userDocError] = useDocumentData<UserStruct>(userDocRef);

    const userDoc = await userDocRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        setIsError(new Error('No User Document Found'));
        return null;
      }
    });

    // Get the organization ID from the user document
    const organizationId = userDoc?.organization_id;

    // Get the ORYXGO collection from the organization ID
    const OrganizationORYXGOCollectionRef = db.collection('organizations').doc(organizationId).collection('ORYXGO');

    // Get a list of ORYXGOs from the ORYXGO collection

    const fetchORYXGOList = async () => {
      try {
        const querySnapshot = await OrganizationORYXGOCollectionRef.get();
        // const ORYXGOList = querySnapshot.docs.map((doc) => doc.data());
        // Assuming your HardwareInstance data has an 'id' field, you can add it to each object like this:
        const ORYXGOList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return ORYXGOList;
      } catch (error) {
        setIsError(new Error('Error getting ORYXGO list'));
        return [];
      }
    };

    const ORYXGOList = await fetchORYXGOList();

    // transform the ORYXGO list into a dictionary
    const createORYXGODict = async () => {
      if (ORYXGOList == null) {
        setIsError(new Error('ORYXGOList is null'));
        return {};
      }
      const dict: any = {};
      for (const ORYXGO of ORYXGOList) {
        dict[ORYXGO.id] = ORYXGO;
      }
      return dict;
    };

    const ORYXGODict = await createORYXGODict();

    // For eacht ORYXGO in the ORYXGO dict, get the ORYXGO document from the ORYXGO collection and also retrieve the rest of the information
    const ORYXGOCollectionRef = db.collection('oryxGOSystems');

    // for each ID in the ORYXGODict get the ORYX GO document from the ORYX GO collection
    const createORYXGODocsList = async () => {
      if (ORYXGODict == null) {
        setIsError(new Error('ORYXGODict is null'));
        return {};
      }
      const ORYXGODocsList: ORYXGOCollection = {};
      for (const ORYXGOId in ORYXGODict) {
        const ORYXGODocRef = ORYXGOCollectionRef.doc(ORYXGOId);
        const ORYXGODoc = ORYXGODocRef.get()
          .then(async (doc) => {
            if (doc.exists) {
              const ORYXGODocData = doc.data() as ORYXGOInstance;
              const results = await getORYXGOData(ORYXGODocData)
                .then((data) => {
                  return data;
                })
                .catch((error) => {
                  console.log('Error getting ORYXGO data: ', error);
                  setIsError(error);
                  setErrorMessage(error.message);
                });

              ORYXGODocsList[ORYXGOId] = results;
            }
          })
          .catch((error) => {
            setIsError(error);
            setErrorMessage(error.message);
          });
      }
      return ORYXGODocsList;
    };

    const ORYXGODocsList = await createORYXGODocsList();

    setORYXGOCollection(ORYXGODocsList);
    setIsLoading(false);
  }

  // // Get the ORYXGO documents from the ORYXGO collection
  // const [ORYXGODoc, ORYXGODocLoading, ORYXGODocError] = useCollectionData<HardwareInstance>(hardwareCollectionRef, {
  //   idField: 'id',
  // });
  // console.log('ORYXGO Doc: ', ORYXGODoc);

  // const buildDocTree = async () => {
  //   //Attempt to load cache first, and proceed to pulling from firebase if not present
  //   const cachedValue = await getCache<HardwareCollection>(HARDWARE_CACHE_KEY);

  //   if (cachedValue != null) {
  //     setData(cachedValue);
  //     setDataReady(true);
  //     return;
  //   }

  //   const userDocRef = db.collection('users').doc(userId);
  //   const userDocs = await userDocRef.get();
  //   const userData = userDocs.data() as UserStruct;
  //   const hwCollectionRef = db.collection('organizations').doc(userData.organization_id).collection('hardware');
  //   const hardwareDocs = await hwCollectionRef.get();

  //   // Represents root
  //   const hardwareCollection: HardwareCollection = {};

  //   // For each /users/{userId}/hardware/{hardwareId}
  //   for (const hwi of hardwareDocs.docs) {
  //     const hardwareInstanceData: HardwareInstance = hwi.data() as HardwareInstance;

  //     //Because we're only trying to get usable hardware out of the databse, check if hardware is of type 'router'
  //     if (hardwareInstanceData.type != 'router') {
  //       return;
  //     }

  //     const gatewayCollectionRef = hwCollectionRef.doc(hwi.id).collection('gateways');
  //     const gatewayDocs = await gatewayCollectionRef.get();

  //     const gatewayCollectionData: GatewaysCollection = {};

  //     // For each /users/{userId}/hardware/{hardwareId}/gateways/{gatewayId}
  //     for (const gwi of gatewayDocs.docs) {
  //       const gatewayInstanceData = gwi.data() as GatewayInstance;

  //       const sensorCollectionRef = gatewayCollectionRef.doc(gwi.id).collection('sensors');
  //       const sensorDocs = await sensorCollectionRef.get();

  //       const sensorCollectionData: SensorsCollection = {};

  //       // For each /users/{userId}/hardware/hardwareId/gateways/{gatewayId}/sensors/{serialNumber}
  //       for (const si of sensorDocs.docs) {
  //         const sensorInstanceData = si.data() as SensorInstance;

  //         sensorCollectionData[si.id] = {
  //           ...sensorInstanceData,
  //         };
  //       }

  //       gatewayCollectionData[gwi.id] = {
  //         ...gatewayInstanceData,
  //         sensors: sensorCollectionData,
  //       };
  //     }

  //     hardwareCollection[hwi.id] = {
  //       ...hardwareInstanceData,
  //       gateways: gatewayCollectionData,
  //     };
  //   }

  //   setData(hardwareCollection);
  //   setDataReady(true);
  //   await setCache<HardwareCollection>(hardwareCollection, HARDWARE_CACHE_KEY);
  // };

  // // useMemo(buildDocTree, [userDocs]);

  // // onMount, build the doctree (this happens once per 'reload or refresh')
  // useEffect(() => {
  //   buildDocTree();
  // }, []);

  const hook: UseHardwareHook = {
    ORYXGOCollection: ORYXGOCollection,
    isLoading: isLoading,
    isError: isError,
    errorMessage: errorMessage,
    clearError: clearError,
    getAvailableORYXGOSystems: getAvailableORYXGOSystems,
    getAssignmentsFile: getAssignmentsFile,
  };
  return useMemo(
    () => hook,
    [ORYXGOCollection, isLoading, isError, errorMessage, clearError, getAvailableORYXGOSystems, getAssignmentsFile],
  );
};

async function getORYXGOData(docData: ORYXGOInstance) {
  // extract the base information from the docData (dateCreated, lastUpdated, status)
  const baseData = {
    dateCreated: docData!.dateCreated,
    lastUpdated: docData!.lastUpdated,
    status: docData!.status,
    assignments: docData!.assignments,
  };

  const promises = [];
  const sensors: any = {};
  const collectionMapping: any = {
    gateway: 'Gateways',
    iPad: 'iPads',
    sensors: 'sensors',
    dataConnection: 'dataConnections',
  };
  for (const collection in collectionMapping) {
    const collectionName = collectionMapping[collection];

    if (collection === 'gateway') {
      const gatewayRef = db.collection(collectionName).doc(docData!.gateway);
      const gatewayDoc = await gatewayRef.get();
      promises.push({ gateway: gatewayDoc.data() });
    } else if (collection === 'iPad') {
      const iPadRef = db.collection(collectionName).doc(docData!.iPad);
      const iPadDoc = await iPadRef.get();
      promises.push({ iPad: iPadDoc.data() });
    } else if (collection === 'sensors') {
      // docData.sensors has the following structure:
      // sensors: [
      //   'sensor serialNumber 1',
      //   'sensor serialNumber 2',
      //   'sensor serialNumber 3',
      //     ]
      //   For each sensor serial number, get the sensor document from the sensors collection
      for (const sensor of docData!.sensors) {
        const sensorRef = db.collection(collectionName).doc(sensor);
        const sensorDoc = await sensorRef.get();

        // create a dictionary of sensors
        sensors[sensor] = sensorDoc.data();
      }
      promises.push({ sensors: sensors });
    } else if (collection === 'dataConnection') {
      const dataConnectionRef = db.collection(collectionName).doc(docData!.dataConnection);
      const dataConnectionDoc = await dataConnectionRef.get();
      promises.push({ dataConnection: dataConnectionDoc.data() });
    }
  }
  // Push the base data into the promises array
  promises.push(baseData);

  // Wait for all promises to resolve
  // const results = await Promise.all(promises);
  const results = Object.assign({}, ...promises);
  return results;
}
