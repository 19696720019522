/** Overview report tab showing report metadata */
import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonToast,
  useIonAlert,
} from '@ionic/react';
import { addCircleOutline, chevronDownCircleOutline, closeCircleOutline } from 'ionicons/icons';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { FirebaseTimestamp, ReportType } from '../../model';

import { ORYXCardHeader } from '../../components/componentHeaders';
import { useEffect, useState } from 'react';

import { useReportContext } from '../../components/reportContext';
import { ErrorToast } from '../../components/ErrorToast';
import ORYX_EditIcon from '../../assets/icons/ORYX_EditIcon.svg';

type OverviewReportProps = RouteComponentProps & {
  report: any;
  id: string;
};
export const getActivity = (reportType: ReportType | undefined) => {
  switch (reportType) {
    case 1:
      return 'Walk';
    case 2:
      return 'Run';
    case 3:
      return 'Squat';
    case 4:
      return 'Single Leg Squat';
    default:
      return 'Error';
  }
};

function convertDate(timestamp: FirebaseTimestamp) {
  const datestring = timestamp.toDate().toLocaleString();
  return datestring;
}

/** Overview report tab showing report metadata
 * @param report - The full report data, including reportInfo data
 */
export const OverviewReportTab = ({ report, id }: OverviewReportProps) => {
  const data = report;
  console.log('OverviewReportTab: data', data);
  const [presentAlert] = useIonAlert();
  const [isTouched, setIsTouched] = useState(false);
  const [addTags, setAddTags] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    editTags,
    isCompleted,
    isError,
    clearError,
    tags,
    fileListener,
    reportName,
    editReportName,
    notes,
    addReportNotes,
    editReportNotes,
  } = useReportContext();
  const [editName, setEditName] = useState(false);
  const [loadingReportName, setLoadingReportName] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState<{ show: boolean; note: number | undefined; index: number }>({
    show: false,
    note: undefined,
    index: 0,
  });

  // if (process.env.NODE_ENV === 'development') console.log('OverviewReportTab: data', data);
  const reportType = data.reportId.reportType;
  const activity = getActivity(reportType);

  const uploadDate = new Date(data?.fileId?.uploadTimestamp * 1000);
  const uploadDateString = uploadDate?.toLocaleString();

  const markTouched = () => {
    setIsTouched(true);
  };

  useEffect(() => {
    if (report) {
      fileListener(id);
    }
  }, [report]);

  // #region Tags
  const handleInputTags = (ev: Event) => {
    // If no comma is found, use the string as a single tag in the array
    // if a comma is found, split the string into an array of tags
    const input = (ev.target as HTMLInputElement).value;
    if (input.includes(', ')) {
      localStorage.setItem('tags', JSON.stringify([input]));
    } else {
      localStorage.setItem('tags', JSON.stringify([input]));
    }
  };

  // function triggered when user leaves the Tag input field
  const handleAddTags = () => {
    setLoading(true);
    const tagsLocalStorage = localStorage.getItem('tags');
    if (tagsLocalStorage) {
      // create array from the local storage string
      const tagsArray = JSON.parse(tagsLocalStorage!);
      const tagsToUpload = tagsArray[0].split(', ');
      setTimeout(() => {
        editTags(id, tagsToUpload);
        setAddTags(false);
        setIsTouched(false);
        localStorage.removeItem('tags');
        setLoading(false);
      }, 1000);
    }
  };

  // handle tags deletion
  const handleTagDelete = (index: number) => {
    // remove the tag from the array and update the db
    // if there is only 1 tag in the array, replace the entry with a blank string
    if (tags.length === 1) {
      editTags(id, ['']);
    }
    // if there are more than 1 tags in the array, remove the tag at the index
    else {
      const newTags = tags.filter((tag, i) => i !== index);
      editTags(id, newTags);
    }
  };

  // #endregion

  // #region Report Name
  const handleInputReportName = (ev: Event) => {
    const input = (ev.target as HTMLInputElement).value;
    localStorage.setItem('reportName', input);
  };

  const handleAddReportName = () => {
    setLoadingReportName(true);
    const reportNameLocalStorage = localStorage.getItem('reportName');
    if (reportNameLocalStorage) {
      setTimeout(() => {
        editReportName(id, reportNameLocalStorage);
        localStorage.removeItem('reportName');
        setLoadingReportName(false);
        setEditName(false);
      }, 1000);
    }
  };

  // #endregion

  // #region Note

  // #endregion
  useEffect(() => {
    console.log('db Tags', tags);
    console.log('db reportName', reportName);
  }, [tags, reportName]);

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonToast
          isOpen={isCompleted}
          message={'File updated successfully!'}
          position='bottom'
          color='success'
          duration={2500}
        />

        <IonAlert
          trigger='add-note'
          isOpen={addNote}
          header='Please enter your note'
          className='informativeAlert'
          onDidDismiss={() => setAddNote(false)}
          buttons={[
            { text: 'Cancel', role: 'cancel', cssClass: 'destructiveAlertButton' },
            { text: 'Save', handler: (alertData) => addReportNotes(id, alertData.note) },
          ]}
          inputs={[
            {
              name: 'note',
              type: 'textarea',
              placeholder: 'Add note here...',
            },
          ]}
        />

        <IonAlert
          isOpen={editNote.show}
          header='Please edit your note'
          className='informativeAlert'
          buttons={[
            { text: 'Cancel', role: 'cancel', cssClass: 'destructiveAlertButton' },
            { text: 'Save', handler: (alertData) => editReportNotes(id, alertData.note, editNote.index) },
          ]}
          inputs={[
            {
              name: 'note',
              type: 'textarea',
              placeholder: `${editNote.note}`,
            },
          ]}
        />

        <IonPage>
          <IonContent className='ion-padding' color='light'>
            <IonGrid className='' style={{ minHeight: '100%' }}>
              <IonRow className='border_Bottom'>
                <IonCol size='12'>
                  <IonText className='ion-text-center'>
                    <h4>Report overview</h4>
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className=''>
                <IonCol size='6'>
                  {/* file Info */}
                  <IonCard style={{ height: '100%' }}>
                    <ORYXCardHeader title={'File info'} />
                    <IonCardContent className='reportcardContent'>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='4' className=''>
                          <strong>Upload date: </strong>
                        </IonCol>
                        <IonCol size='8' className=''>
                          {uploadDateString}
                        </IonCol>
                      </IonRow>

                      <IonRow className='ion-align-items-center'>
                        <IonCol size='4'>
                          <strong>Filename: </strong>
                        </IonCol>
                        <IonCol size='8'>{data.fileId.filename}</IonCol>
                      </IonRow>

                      <IonRow className='ion-align-items-center'>
                        <IonCol size='4'>
                          <strong>Activity: </strong>
                        </IonCol>
                        <IonCol size='8'>{activity}</IonCol>
                      </IonRow>

                      <IonRow className='ion-align-items-center'>
                        <IonCol size='4'>
                          <strong>Reportname: </strong>
                        </IonCol>
                        {!editName ? (
                          <>
                            <IonCol size='7'>{reportName}</IonCol>
                            <IonCol size='1' className='ion-text-center ion-align-items-center'>
                              <IonIcon
                                icon={ORYX_EditIcon}
                                style={{ display: 'block', margin: 'auto' }}
                                color='primary'
                                onClick={() => setEditName(true)}
                              />
                            </IonCol>{' '}
                          </>
                        ) : (
                          <>
                            <IonCol size='7'>
                              <IonInput
                                className='create_ListInput'
                                placeholder='Enter (new) reportname'
                                type='text'
                                spellCheck={false}
                                autocapitalize='on'
                                onIonInput={(e) => {
                                  handleInputReportName(e);
                                }}
                                onIonBlur={() => handleAddReportName()}
                                onIonFocus={() => markTouched()}
                                fill='outline'
                                clearInput={true}
                                mode='ios'
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleAddReportName();
                                    setEditName(false);
                                  }
                                }}></IonInput>
                            </IonCol>
                            {loadingReportName && (
                              <IonCol size='1' className='ion-text-center ion-align-items-center Test'>
                                <IonSpinner name='bubbles' className='' color='primary' />
                              </IonCol>
                            )}
                          </>
                        )}
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </IonCol>

                <IonCol size='6'>
                  {/* Tags */}
                  <IonCard style={{ height: '100%' }}>
                    <ORYXCardHeader title={'Tags'} />
                    <IonCardContent className='reportcardContent'>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='12' className='ion-text-center'>
                          {tags[0] != '' ? (
                            <>
                              {tags.map((tag: any, index: any) => (
                                <IonChip key={tag}>
                                  {tag}
                                  <IonIcon
                                    icon={closeCircleOutline}
                                    color='warning'
                                    onClick={() => handleTagDelete(index)}></IonIcon>
                                </IonChip>
                              ))}
                            </>
                          ) : (
                            <>
                              <IonItem className='' lines='none'>
                                <IonLabel className=''>No tags found</IonLabel>
                                <IonButton icon-only slot='end' fill='clear' onClick={() => setAddTags(true)}>
                                  <IonIcon src={addCircleOutline} slot='end' />
                                </IonButton>
                              </IonItem>
                            </>
                          )}
                        </IonCol>
                      </IonRow>
                      <IonList lines='none'>
                        {addTags && (
                          <>
                            <IonItem className={`create_ListItem ${isTouched && 'ion-focused'}`} lines='none'>
                              {/* <IonLabel className='create_Label'>Tags</IonLabel> */}
                              <IonInput
                                className='create_ListInput'
                                label='Tags'
                                type='text'
                                spellCheck={false}
                                autocapitalize='off'
                                onIonInput={(e) => {
                                  handleInputTags(e);
                                }}
                                onIonBlur={() => handleAddTags()}
                                onIonFocus={() => markTouched()}
                                helperText='Seperate multiple tags by a comma'
                                fill='outline'
                                clearInput={true}
                                mode='ios'
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') handleAddTags();
                                }}
                              />
                            </IonItem>
                            <IonRow className='ion-justify-content-between ion-align-items-center'>
                              <IonCol size='11' className='ion-padding-horizontal'>
                                {/* <IonButton onClick={handleAddTags} expand='block' fill='outline' color='primary' /> */}
                              </IonCol>

                              <IonCol size='1' className='ion-no-padding ion-text-center'>
                                {loading ? (
                                  <IonSpinner name='bubbles' className='' color='primary' />
                                ) : (
                                  <IonIcon src={closeCircleOutline} color='warning' onClick={() => setAddTags(false)} />
                                )}
                              </IonCol>
                            </IonRow>
                          </>
                        )}
                      </IonList>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow className='ion-margin-bottom'>
                <IonCol size='12'>
                  {/* Notes */}
                  <IonCard style={{ height: '100%', marginBottom: '20px' }}>
                    <ORYXCardHeader title={'Notes'}>
                      <IonButton icon-only fill='clear' onClick={() => setAddNote(true)} id='add-note'>
                        <IonIcon src={addCircleOutline} />
                      </IonButton>
                    </ORYXCardHeader>
                    <IonCardContent className='reportcardContent'>
                      <IonRow>
                        {/* <IonCol size='12'>
                          {notes[0] == '' ? (
                            <IonText>No notes found</IonText>
                          ) : (
                            <>
                              <IonAccordionGroup mode='md' expand='inset'>
                                {notes.map((note: any, index: any) => (
                                  <IonAccordion key={index} toggleIcon={chevronDownCircleOutline}>
                                    <IonItem slot='header' className='ion_accordion_item'>
                                      <IonText>{convertDate(note.createdOn)}</IonText>
                                    </IonItem>
                                    <IonRow className='ion-padding ion-align-items-center' slot='content'>
                                      <IonCol size='11'>{note.note}</IonCol>
                                      <IonCol size='1' className='ion-no-padding ion-text-center'>
                                        <IonIcon
                                          icon={ORYX_EditIcon}
                                          style={{ display: 'block', margin: 'auto' }}
                                          color='primary'
                                          onClick={() => setEditNote({ show: true, note: note.note, index: index })}
                                        />
                                      </IonCol>
                                    </IonRow>
                                  </IonAccordion>
                                ))}
                              </IonAccordionGroup>
                            </>
                          )}
                        </IonCol> */}
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
            {/* <IonList lines='none'>
    <IonItem>
      <IonIcon icon={calendarOutline} slot='start' />
      <IonLabel>{data.uploadTimestamp.toLocaleString()}</IonLabel>
    </IonItem>

    <IonItem>
      <IonIcon icon={cloudUploadOutline} slot='start' />
      <IonLabel>{report.filename}</IonLabel>
    </IonItem>

    {report.tags.length > 0 && (
      <IonItem>
        <IonIcon icon={pricetagsOutline} slot='start' />
        {report.tags.map((tag) => (
          <IonChip key={tag}>{tag}</IonChip>
        ))}
      </IonItem>
    )}

    {activity && (
      <IonItem>
        <IonIcon icon={bodyOutline} slot='start' />
        <IonLabel>Activity: {activity}</IonLabel>
      </IonItem>
    )}
  </IonList> */}
          </IonContent>
          <ErrorToast
            message={'Something went wrong, please try again. If this error persist please contact support'}
            clearError={() => clearError()}
          />
        </IonPage>
      </>
    );
  }
};
