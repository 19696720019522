import React from 'react';
import { IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonImg, IonIcon, IonCardSubtitle } from '@ionic/react';
import './modalindex.css';
import IBimageKneeanglemidstance1 from './assets/IBimageKneeanglemidstance1.png';
import IBimageKneeanglemidstance2 from './assets/IBimageKneeanglemidstance2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import '@ionic/react/css/ionic-swiper.css';

export const ModalKneeFlexionMidstance: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Knee flexion</IonCardTitle>
          <IonCardSubtitle className='reportInfoModal_Subtitle'>Midstance</IonCardSubtitle>
        </div>
      </IonCardHeader>

      <Swiper modules={[Pagination]} pagination={true} initialSlide={0} speed={400} direction='vertical'>
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageKneeanglemidstance1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  During running, the COM will perform a wave movement, with its highest point at the middle of the
                  floating phase and lowest at midstance.
                  <br></br>
                  <br></br>
                  Due to the lowering COM after landing, the body must absorb massive ground reaction forces. Accurate
                  response to this sudden loading requires sufficient leg stiffness (also see: swing leg retraction).
                  <br></br>
                  <br></br>
                  The kinetic energy released is converted into elastic energy as much as possible, which is being
                  stored in elastic components such as tendons and fascia for re-use after midstance.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageKneeanglemidstance2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  The knee is a key hub for transport of energy from proximal to distal, in which the muscle effort arms
                  change along with the knee angle and hence also the joint torque. Therefore the knee angle at
                  midstance is an important parameter to check economic running.
                  <br></br>
                  <br></br>
                  The right (maximum) knee angle in stance phase is critical for running at high speed; slightly larger
                  angles lead to a deeper ‘sit’, resulting in loss of performance.
                  <br></br>
                  <br></br>
                  Lack of stiffness typically forces the knee to ‘collapse’. This results in larger knee angles which
                  disable the possibility to store elastic energy.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
