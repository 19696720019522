/** Bar at the bottom of a page to show errors */
import { IonToast } from '@ionic/react';
import React from 'react';

type ErrorToastProps = {
  message: string | undefined;
  clearError: () => void;
};

export const ErrorToast = ({ message, clearError }: ErrorToastProps) => (
  <IonToast
    isOpen={!!message}
    onDidDismiss={() => clearError()}
    message={message}
    color='danger'
    position='bottom'
    buttons={[
      {
        text: 'Close',
        role: 'cancel',
        handler: () => {
          if (process.env.NODE_ENV !== 'production') console.log('Cancel clicked');
        },
      },
    ]}
  />
);
