/** Load (and keep listening) all report entries for a given user from the DB */
import firebase from 'firebase';
import 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useCollectionData, useDocument, useDocumentData } from 'react-firebase-hooks/firestore';
import { ReportData, ReportDict, ReportId, ReportInfo, ReportInfoDict, UserId } from '../../model';
import { myFirebase } from '../../config/firebaseConfig';

const db = myFirebase.firestore();

type ReportDataDict = {
  [id in ReportId]: ReportData;
};

export type UseReportsHook = {
  reportDict: ReportDict;
  isLoading: boolean;
  isError: Error | undefined;
  dataReady: boolean;
  clearError: () => void;
  doDelete: (reportId: string) => Promise<void>;
  getReportData: (reportId: string) => Promise<any>;
  editTags: (reportId: string, tags: string[]) => Promise<void>;
  editReportName: (reportId: string, reportName: string) => Promise<void>;
  addReportNotes: (reportId: string, note: string) => Promise<void>;
  editReportNotes: (reportId: string, notes: string, index: number) => Promise<void>;
  isCompleted: boolean;
  fileListener: (reportId: string) => Promise<void>;
  tags: string[];
  reportName: string;
  notes: string[];
  data: any;
};

export const useReports = (userId: UserId, reportInfoDict: ReportInfoDict): UseReportsHook => {
  const userRef = db.collection('users').doc(userId);
  const reportsRef = userRef.collection('reports');
  const [reportDocs, loading, error] = useCollectionData<ReportData>(reportsRef, { idField: 'id' });
  const [isError, setIsError] = useState<Error | undefined>();
  const [data, setData] = useState();
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [reportName, setReportName] = useState<string>('');
  const [notes, setNotes] = useState<string[]>([]);

  async function doDelete(reportId: string): Promise<void> {
    const reportInfoDelete = userRef.collection('reportInfo').doc(reportId);
    reportInfoDelete
      .update({ status: 'deleted' })
      .then((_doc) => {
        if (process.env.NODE_ENV !== 'production') console.log('status changed to deleted!');
        const mutation_history = [
          {
            editedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedFields: 'status',
            type: 'update',
          },
        ];
        reportInfoDelete
          .update({ mutation_history })
          .then(() => {
            if (process.env.NODE_ENV !== 'production') console.log('mutation history updated!');
          })
          .catch((error) => {
            setIsError(error);
            if (process.env.NODE_ENV !== 'production') console.log(error);
          });
      })
      .catch((error) => {
        setIsError(error);
        if (process.env.NODE_ENV !== 'production') console.log(error);
      });
  }

  async function kinematicsFileListener(reportId: string): Promise<void> {
    const reportInfoRef = userRef.collection('reportInfo').doc(reportId);
    const reportInfoDoc = await reportInfoRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // if (process.env.NODE_ENV !== 'production') console.log('Document data:', doc.data());
          return doc.data();
        } else {
          setIsError(new Error('No such document!'));
        }
      })
      .catch((error) => {
        setIsError(error);
      });
    // listen for changes to the tags on the fileId of the reportInfo Doc and return them
    const fileRef = userRef.collection('kinematicsFiles').doc(reportInfoDoc!.fileId);
    fileRef.onSnapshot(
      (snapshot) => {
        console.log('Current data: ', snapshot.data());
        if (snapshot.exists) {
          const data = snapshot.data();
          if (data) {
            setTags(data.tags);
            setReportName(data.reportName);
            setNotes(data.notes);
          }
        } else {
          setIsError(new Error('No such document!'));
        }
      },
      (error) => {
        setIsError(error);
      },
    );
  }

  // Edit the tags on the kinematics file
  async function editTags(reportId: string, tags: string[]): Promise<void> {
    const reportInfoRef = userRef.collection('reportInfo').doc(reportId);
    // get the fileId from the reportInfo collection
    const reportInfoDoc = await reportInfoRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // if (process.env.NODE_ENV !== 'production') console.log('Document data:', doc.data());
          // Change the 'lastUpdated', and 'updatedFields' fields
          const mutation_history = [
            {
              editedOn: firebase.firestore.Timestamp.fromDate(new Date()),
              updatedFields: 'Tags',
              type: 'update',
            },
          ];
          reportInfoRef
            .update({ _mutationHistory: firebase.firestore.FieldValue.arrayUnion(mutation_history) })
            .then(() => {
              if (process.env.NODE_ENV !== 'production') console.log('mutation history updated!');
            })
            .catch((error) => {
              setIsError(error);
              if (process.env.NODE_ENV !== 'production') console.log(error);
            });

          return doc.data();
        } else {
          setIsError(new Error('No such document!'));
        }
      })
      .catch((error) => {
        setIsError(error);
      });

    if (reportInfoDoc) {
      const fileId: string = reportInfoDoc.fileId;

      const fileRef = userRef.collection('kinematicsFiles').doc(fileId);
      // update the tags on the kinematics file
      fileRef
        .update({ tags: tags })
        .then(() => {
          if (process.env.NODE_ENV !== 'production') console.log('tags updated!');
          setIsCompleted(true);
        })
        .catch((error) => {
          setIsError(error);
          if (process.env.NODE_ENV !== 'production') console.log(error);
        });
    }
  }

  // Edit the reportName on the kinematics file
  async function editReportName(reportId: string, reportName: string): Promise<void> {
    console.log('editReportName');
    const reportInfoRef = userRef.collection('reportInfo').doc(reportId);
    // get the fileId from the reportInfo collection
    const reportInfoDoc = await reportInfoRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // if (process.env.NODE_ENV !== 'production') console.log('Document data:', doc.data());
          // Change the 'lastUpdated', and 'updatedFields' fields
          const mutation_history = {
            editedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedFields: 'reportName',
            type: 'update',
          };
          reportInfoRef
            .update({ _mutationHistory: firebase.firestore.FieldValue.arrayUnion(mutation_history) })
            .then(() => {
              if (process.env.NODE_ENV !== 'production') console.log('mutation history updated!');
            })
            .catch((error) => {
              setIsError(error);
              if (process.env.NODE_ENV !== 'production') console.log(error);
            });

          return doc.data();
        } else {
          setIsError(new Error('No such document!'));
          if (process.env.NODE_ENV !== 'production') console.log(error);
        }
      })
      .catch((error) => {
        setIsError(error);
        if (process.env.NODE_ENV !== 'production') console.log(error);
      });

    if (reportInfoDoc) {
      const fileId: string = reportInfoDoc.fileId;

      const fileRef = userRef.collection('kinematicsFiles').doc(fileId);
      // update the tags on the kinematics file
      fileRef
        .update({ reportName: reportName })
        .then(() => {
          if (process.env.NODE_ENV !== 'production') console.log('reportName updated!');
          setIsCompleted(true);
        })
        .catch((error) => {
          setIsError(error);
          if (process.env.NODE_ENV !== 'production') console.log(error);
        });
    }
  }

  // Edit the notes on the kinematics file
  async function addReportNotes(reportId: string, note: string): Promise<void> {
    if (process.env.NODE_ENV !== 'production') console.log('addNote');
    const reportInfoRef = userRef.collection('reportInfo').doc(reportId);
    // get the fileId from the reportInfo collection
    const reportInfoDoc = await reportInfoRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // if (process.env.NODE_ENV !== 'production') console.log('Document data:', doc.data());
          // Change the 'lastUpdated', and 'updatedFields' fields
          const mutation_history = {
            editedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedFields: 'Notes',
            type: 'add',
          };
          reportInfoRef
            .update({ _mutationHistory: firebase.firestore.FieldValue.arrayUnion(mutation_history) })
            .then(() => {
              if (process.env.NODE_ENV !== 'production') console.log('mutation history updated!');
            })
            .catch((error) => {
              setIsError(error);
              if (process.env.NODE_ENV !== 'production') console.log(error);
            });

          return doc.data();
        } else {
          setIsError(new Error('No such document!'));
          if (process.env.NODE_ENV !== 'production') console.log(error);
        }
      })
      .catch((error) => {
        setIsError(error);
        if (process.env.NODE_ENV !== 'production') console.log(error);
      });

    if (reportInfoDoc) {
      const fileId: string = reportInfoDoc.fileId;

      const fileRef = userRef.collection('kinematicsFiles').doc(fileId);
      // update the note on the kinematics file
      const noteObject = {
        createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
        note: note,
      };
      fileRef
        .update({ notes: firebase.firestore.FieldValue.arrayUnion(noteObject) })
        .then(() => {
          if (process.env.NODE_ENV !== 'production') console.log('reportName updated!');
          setIsCompleted(true);
        })
        .catch((error) => {
          setIsError(error);
          if (process.env.NODE_ENV !== 'production') console.log(error);
        });
    }
  }

  // Edit the notes on the kinematics file
  async function editReportNotes(reportId: string, note: string, index: number): Promise<void> {
    if (process.env.NODE_ENV !== 'production') console.log('editNote');
    const reportInfoRef = userRef.collection('reportInfo').doc(reportId);
    // get the fileId from the reportInfo collection
    const reportInfoDoc = await reportInfoRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // if (process.env.NODE_ENV !== 'production') console.log('Document data:', doc.data());
          // Change the 'lastUpdated', and 'updatedFields' fields
          const mutation_history = {
            editedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedFields: 'Notes',
            type: 'update',
          };
          reportInfoRef
            .update({ _mutationHistory: firebase.firestore.FieldValue.arrayUnion(mutation_history) })
            .then(() => {
              if (process.env.NODE_ENV !== 'production') console.log('mutation history updated!');
            })
            .catch((error) => {
              setIsError(error);
              if (process.env.NODE_ENV !== 'production') console.log(error);
            });

          return doc.data();
        } else {
          setIsError(new Error('No such document!'));
          if (process.env.NODE_ENV !== 'production') console.log(error);
        }
      })
      .catch((error) => {
        setIsError(error);
        if (process.env.NODE_ENV !== 'production') console.log(error);
      });

    if (reportInfoDoc) {
      const fileId: string = reportInfoDoc.fileId;

      const fileRef = userRef.collection('kinematicsFiles').doc(fileId);
      // update the note on the kinematics file
      // TODO get the createdOn date from the note first, then create the Object and then update the note with the arrayUnion
      fileRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const notes = doc.data()?.notes;
            if (notes) {
              const noteObject = {
                createdOn: notes[index].createdOn,
                note: note,
              };
              // fileRef
              //   .update({ notes[index]: firebase.firestore.FieldValue.arrayUnion(noteObject) })
              //   .then(() => {
              //     if (process.env.NODE_ENV !== 'production') console.log('note updated!');
              //     setIsCompleted(true);
              //   })
              //   .catch((error) => {
              //     setIsError(error);
              //     if (process.env.NODE_ENV !== 'production') console.log(error);
              //   });
            }
          }
        })
        .catch((error) => {
          setIsError(error);
          if (process.env.NODE_ENV !== 'production') console.log(error);
        });
    }
  }

  async function getReportData(reportInfoId: string): Promise<void> {
    setDataReady(false);
    setData(undefined);
    // Get the reportId from the reportInfo collection
    const reportInfoRef = userRef.collection('reportInfo').doc(reportInfoId);
    const reportInfo = await reportInfoRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // if (process.env.NODE_ENV !== 'production') console.log('Document data:', doc.data());
          return doc.data();
        } else {
          setIsError(new Error('No such document!'));
        }
      })
      .catch((error) => {
        setIsError(error);
      });

    if (reportInfo) {
      const reportType: number = reportInfo.reportType;

      const results = await getData(reportType, reportInfo, userRef)
        .then((results) => {
          return results;
        })
        .catch((error) => {
          setIsError(error);
        });
      setData(results);
      setDataReady(true);
    }
  }

  // Called to close the error Toast
  const clearError = () => {
    setIsError(undefined);
  };

  if (error) {
    setIsError(error);
  }

  const convertReports = (): ReportDict => {
    if (!reportDocs) {
      return {};
    }
    const reportDataDict: ReportDataDict = reportDocs.reduce(
      (reportDataDict: ReportDataDict, reportData: ReportData) => {
        reportDataDict[reportData.id] = reportData;
        return reportDataDict;
      },
      {},
    );
    return Object.values(reportInfoDict).reduce((reportDict: ReportDict, reportInfo: ReportInfo) => {
      const reportId = reportInfo.reportId;
      if (!reportId) {
        return reportDict;
      }

      const reportData = reportDataDict[reportId];
      if (!reportData) {
        return reportDict;
      }
      reportDict[reportId] = {
        ...reportInfo,
        data: reportData,
      };
      return reportDict;
    }, {});
  };

  const reportDict = useMemo(convertReports, [userId, reportInfoDict, reportDocs]);
  const hook: UseReportsHook = {
    reportDict: reportDict,
    isLoading: loading,
    isError: isError,
    clearError: clearError,
    doDelete: doDelete,
    getReportData: getReportData,
    dataReady: dataReady,
    editTags: editTags,
    editReportName: editReportName,
    addReportNotes: addReportNotes,
    editReportNotes: editReportNotes,
    isCompleted: isCompleted,
    fileListener: kinematicsFileListener,
    tags: tags,
    notes: notes,
    reportName: reportName,
    data: data,
  };
  return useMemo(
    () => hook,
    [reportDict, loading, isError, dataReady, data, isCompleted, kinematicsFileListener, tags],
  );
};

async function getData(
  reportType: number,
  reportInfo: firebase.firestore.DocumentData,
  userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
) {
  const promises = [];
  const collectionMappings: any = {
    1: {
      reportId: 'reports',
      stridesLeft: 'strideData_Left',
      stridesCoronalLeft: 'strideData_Left_Coronal',
      stridesTransversalLeft: 'strideData_Left_Transversal',
      stridesRight: 'strideData_Right',
      stridesCoronalRight: 'strideData_Right_Coronal',
      stridesTransversalRight: 'strideData_Right_Transversal',
      coordinativeVariability: 'coordinativeVariabilityData',
      coordinativeVariabilityPlanes: 'coordinativeVariabilityData_Planes',
      crpHipLeft: 'crpData_Hip_Left',
      crpHipRight: 'crpData_Hip_Right',
      crpKneeLeft: 'crpData_Knee_Left',
      crpKneeRight: 'crpData_Knee_Right',
      crpAnkleLeft: 'crpData_Ankle_Left',
      crpAnkleRight: 'crpData_Ankle_Right',
      crpMean: 'crpData_Mean',
      fileId: 'fileId',
    },
    2: {
      reportId: 'reports',
      stridesLeft: 'strideData_Left',
      stridesCoronalLeft: 'strideData_Left_Coronal',
      stridesTransversalLeft: 'strideData_Left_Transversal',
      stridesRight: 'strideData_Right',
      stridesCoronalRight: 'strideData_Right_Coronal',
      stridesTransversalRight: 'strideData_Right_Transversal',
      coordinativeVariability: 'coordinativeVariabilityData',
      coordinativeVariabilityStance: 'coordinativeVariabilityData_Stance',
      coordinativeVariabilityPlanes: 'coordinativeVariabilityData_Planes',
      crpHipLeft: 'crpData_Hip_Left',
      crpHipRight: 'crpData_Hip_Right',
      crpKneeLeft: 'crpData_Knee_Left',
      crpKneeRight: 'crpData_Knee_Right',
      crpAnkleLeft: 'crpData_Ankle_Left',
      crpAnkleRight: 'crpData_Ankle_Right',
      crpMean: 'crpData_Mean',
      fileId: 'fileId',
    },
    3: {
      reportId: 'reports',
      stridesLeft: 'strideData_Left',
      stridesCoronalLeft: 'strideData_Left_Coronal',
      stridesTransversalLeft: 'strideData_Left_Transversal',
      stridesRight: 'strideData_Right',
      stridesCoronalRight: 'strideData_Right_Coronal',
      stridesTransversalRight: 'strideData_Right_Transversal',
      coordinativeVariability: 'coordinativeVariabilityData',
      coordinativeVariabilitySquat: 'coordinativeVariabilityData_Squat',
      crpHipLeft: 'crpData_Hip_Left',
      crpHipRight: 'crpData_Hip_Right',
      crpKneeLeft: 'crpData_Knee_Left',
      crpKneeRight: 'crpData_Knee_Right',
      crpAnkleLeft: 'crpData_Ankle_Left',
      crpAnkleRight: 'crpData_Ankle_Right',
      fileId: 'fileId',
    },
    4: {
      reportId: 'reports',
      stridesLeft: 'strideData_Left',
      stridesCoronalLeft: 'strideData_Left_Coronal',
      stridesTransversalLeft: 'strideData_Left_Transversal',
      stridesRight: 'strideData_Right',
      stridesCoronalRight: 'strideData_Right_Coronal',
      stridesTransversalRight: 'strideData_Right_Transversal',
      coordinativeVariability: 'coordinativeVariabilityData',
      coordinativeVariabilitySquat: 'coordinativeVariabilityData_Squat',
      crpHipLeft: 'crpData_Hip_Left',
      crpHipRight: 'crpData_Hip_Right',
      crpKneeLeft: 'crpData_Knee_Left',
      crpKneeRight: 'crpData_Knee_Right',
      crpAnkleLeft: 'crpData_Ankle_Left',
      crpAnkleRight: 'crpData_Ankle_Right',
      fileId: 'fileId',
    },
  };
  const collectionKeys = Object.keys(collectionMappings[reportType]);

  for (const [key, value] of Object.entries(reportInfo)) {
    if (collectionKeys.includes(key)) {
      if (key === 'fileId') {
        const collectionRef = userRef.collection('kinematicsFiles');
        const snapshot = await collectionRef.doc(reportInfo[key]).get();
        promises.push({ [key]: snapshot.data() });
        continue;
      } else {
        const collectionName = collectionMappings[reportType][key];
        const collectionRef = userRef.collection(collectionName);
        const snapshot = await collectionRef.doc(reportInfo[key]).get();
        promises.push({ [key]: snapshot.data() });
      }
    }
  }

  const results = Object.assign({}, ...promises);
  return results;
}
