import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { useState } from 'react';
import { ORYXSmallCardHeader } from '../../../../components/componentHeaders';

type StanceCoordinativeVariabilityGraphProps = {
  data: any;
  title: string;
  subtitle: string;

  colors: string[];
  ICAnnotation: any;
  MStAnnotation: any;
  HOAnnotation: any;
  TOAnnotation: any;
  zoom: boolean;
  info: boolean;
  zoomModalContent?: React.ReactNode;
  infoModalContent?: React.ReactNode;
};

export const StanceCoordinativeVariabilityGraph = ({
  data,
  title,
  subtitle,
  ICAnnotation,
  MStAnnotation,
  HOAnnotation,
  TOAnnotation,
  colors,
  zoom,
  info,
  zoomModalContent,
  infoModalContent,
}: StanceCoordinativeVariabilityGraphProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = React.useState('small');

  React.useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        style: {
          fontSize: '8px',
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      max: 360,
      tickAmount: 4,
      labels: {
        formatter: function (value: number) {
          const percentage = (value / 360) * 100;
          return percentage.toFixed(0) + ' %';
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    // title: {
    //   text: title,
    //   align: 'center',
    //   style: {
    //     fontSize: '14px',
    //     fontWeight: 'bold',
    //     fontFamily: 'Montserrat',
    //     color: '#2066FA',
    //   },
    // },
    subtitle: {
      text: subtitle,
      align: 'center',
      style: {
        fontSize: '8px',
        fontFamily: 'Montserrat',
      },
    },
    stroke: {
      width: [1, 1],
      dashArray: [0, 0, 0],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      offsetY: -10,
    },
    colors: colors,
    annotations: {
      xaxis: [
        {
          x: 0,
          borderColor: '#c2c2c2',
          label: {
            text: 'TSw',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: ICAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: ICAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: MStAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: MStAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: HOAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: HOAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: TOAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: TOAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
  };
  const [showZoomModal, setShowZoomModal] = useState({
    show: false,
  });
  const [showInfoModal, setShowInfoModal] = useState({
    show: false,
  });

  return (
    <IonCard className='ORYX_GraphCard'>
      <ORYXSmallCardHeader
        title={title}
        subtitle={subtitle}
        info={false}
        zoom={false}
        setShowZoom={setShowZoomModal}
        setShowInfo={setShowInfoModal}
      />

      <IonCardContent className='' style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
        <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Chart options={options} series={data} type='line' width='100%' height={windowSize == 'small' ? 125 : 175} />
        </div>
      </IonCardContent>
    </IonCard>
  );
};
