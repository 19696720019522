/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonLoading,
  IonToggle,
  IonCardContent,
  IonCardHeader,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { Report, RunReportData, SquatReportData, StridesData, WalkReportData } from '../../../model';
import { toGraphData, flattenData, toVariabilityData } from '../components/DataFunctions';
import { colorLeft, colorRight } from '../components/CustomGraphComponents';

import '../components/Graphs/graphCard.css';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { FunctionalMovementKinematicsGraph } from '../components/Graphs';
import { useEffect, useMemo, useState } from 'react';
import { toMatrix, transpose } from '../../../hooks/useReports';
import { ORYXCardHeader, ORYXSmallCardHeader } from '../../../components/componentHeaders';

type KinematicsReportProps = RouteComponentProps & {
  report: SquatReportData;
};

function getLeftRightSeriesData(leftData: StridesData | undefined, rightData: StridesData | undefined) {
  const left = toGraphData(leftData);
  const seriesLeft = [{ type: 'line', name: 'Left', data: flattenData(left), color: colorLeft }];
  const right = toGraphData(rightData);
  const seriesRight = [{ type: 'line', name: 'Right', data: flattenData(right), color: colorRight }];
  const series = [...seriesLeft, ...seriesRight];
  return series;
}

function getLeftRightSerieData(
  leftData: StridesData | number[] | undefined,
  rightData: StridesData | number[] | undefined,
) {
  const left = toGraphData(leftData);
  const seriesLeft = [{ type: 'line', name: 'Left', data: [left], color: colorLeft }];
  const right = toGraphData(rightData);
  const seriesRight = [{ type: 'line', name: 'Right', data: [right], color: colorRight }];
  const series = [...seriesLeft, ...seriesRight];
  return series;
}

const Labels = [
  ['No', 'Yes'], // Early Heel raise,
  ['Ok', 'Fair', 'Poor'], // Hip Flexion
  ['Ok', 'Fair', 'Poor', 'No exorotation'], // Hip Exorotation
  ['Ok', 'Fair', 'Poor', 'No endorotation'], // Hip Endorotation
  ['Ok', 'Fair', 'Poor', 'No abduction'], // Hip Abduction
  ['Ok', 'Fair', 'Poor', 'No adduction'], // Hip Adduction
  ['Ok', 'Fair', 'Poor', 'No Varus/Valgus'], // Varus Valgus
];

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const SingleLegSquatsKinematicsReportTab = ({ report }: KinematicsReportProps) => {
  const data = report as SquatReportData | undefined;
  const romData: number[][] = transpose(data?.reportId.rom_Squats);
  const qualityData: number[][] = transpose(data?.reportId.keyPerfomanceIndicators_SLS);
  function Label(labels: string[], kpi: number) {
    return labels[kpi - 1];
  }

  const classNames = ['backgroundGreen', 'backgroundOrange', 'backgroundRed'];
  const color = (status: number) => {
    return classNames[status];
  };
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const [seriesPelvis_Sagittal, setSeriesPelvis_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesPelvis_Coronal, setSeriesPelvis_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesPelvis_Transversal, setSeriesPelvis_Transversal] = useState<ApexOptions['series']>([]);
  const [seriesHip_Sagittal, setSeriesHip_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesHip_Coronal, setSeriesHip_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesHip_Transversal, setSeriesHip_Transversal] = useState<ApexOptions['series']>([]);
  const [seriesKnee_Sagittal, setSeriesKnee_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesKnee_Coronal, setSeriesKnee_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesKnee_Transversal, setSeriesKnee_Transversal] = useState<ApexOptions['series']>([]);
  const [seriesAnkle_Sagittal, setSeriesAnkle_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesAnkle_Coronal, setSeriesAnkle_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesAnkle_Transversal, setSeriesAnkle_Transversal] = useState<ApexOptions['series']>([]);

  useEffect(() => {
    const allStridesLocalStorage_Item = localStorage.getItem('showAllStridesKinematics');
    if (process.env.NODE_ENV !== 'production') console.log('Use effect triggered: ', allStridesLocalStorage_Item);
    if (allStridesLocalStorage_Item === 'true') {
      setShowAllStrides(true);
      setSeriesPelvis_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftPelvis, data?.stridesRight.rightPelvis));
      setSeriesPelvis_Coronal(
        getLeftRightSeriesData(
          data?.stridesCoronalLeft.leftPelvis_Coronal,
          data?.stridesCoronalRight.rightPelvis_Coronal,
        ),
      );
      setSeriesPelvis_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftPelvis_Transversal,
          data?.stridesTransversalRight.rightPelvis_Transversal,
        ),
      );

      setSeriesHip_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftHip, data?.stridesRight.rightHip));
      setSeriesHip_Coronal(
        getLeftRightSeriesData(data?.stridesCoronalLeft.leftHip_Coronal, data?.stridesCoronalRight.rightHip_Coronal),
      );
      setSeriesHip_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftHip_Transversal,
          data?.stridesTransversalRight.rightHip_Transversal,
        ),
      );

      setSeriesKnee_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftKnee, data?.stridesRight.rightKnee));
      setSeriesKnee_Coronal(
        getLeftRightSeriesData(data?.stridesCoronalLeft.leftKnee_Coronal, data?.stridesCoronalRight.rightKnee_Coronal),
      );
      setSeriesKnee_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftKnee_Transversal,
          data?.stridesTransversalRight.rightKnee_Transversal,
        ),
      );

      setSeriesAnkle_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftAnkle, data?.stridesRight.rightAnkle));
      setSeriesAnkle_Coronal(
        getLeftRightSeriesData(
          data?.stridesCoronalLeft.leftAnkle_Coronal,
          data?.stridesCoronalRight.rightAnkle_Coronal,
        ),
      );
      setSeriesAnkle_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftAnkle_Transversal,
          data?.stridesTransversalRight.rightAnkle_Transversal,
        ),
      );
    } else {
      localStorage.setItem('showAllStridesKinematics', 'false');
      setShowAllStrides(false);
      setSeriesPelvis_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col0), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col1), color: colorRight },
      ]);
      setSeriesPelvis_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col0),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col1),
          color: colorRight,
        },
      ]);
      setSeriesPelvis_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col0),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col1),
          color: colorRight,
        },
      ]);

      setSeriesHip_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col2), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col3), color: colorRight },
      ]);
      setSeriesHip_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col2),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col3),
          color: colorRight,
        },
      ]);
      setSeriesHip_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col2),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col3),
          color: colorRight,
        },
      ]);

      setSeriesKnee_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col6), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col7), color: colorRight },
      ]);
      setSeriesKnee_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col6),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col7),
          color: colorRight,
        },
      ]);
      setSeriesKnee_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col6),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col7),
          color: colorRight,
        },
      ]);

      setSeriesAnkle_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col10), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col11), color: colorRight },
      ]);
      setSeriesAnkle_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col10),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col11),
          color: colorRight,
        },
      ]);
      setSeriesAnkle_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col10),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col11),
          color: colorRight,
        },
      ]);
    }
  }, [data, showAllStrides]);

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesKinematics', !showAllStrides ? 'true' : 'false');
  };

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            {/* Pelvis */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXCardHeader title={'Pelvis'} zoom={false} info={true}>
                    <IonRow className='ion-no-padding ion-justify-content-center'>
                      <IonText className='attractorCard_Title'>
                        <small>Show all movements</small>
                      </IonText>
                    </IonRow>
                    <IonRow className='ion-no-padding ion-justify-content-center'>
                      <IonToggle
                        mode='ios'
                        className='icon-custom'
                        color='primary'
                        checked={showAllStrides}
                        onIonChange={(e) => {
                          toggleShowAllStrides();
                        }}></IonToggle>
                    </IonRow>
                  </ORYXCardHeader>

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesPelvis_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesPelvis_Coronal}
                          title={'Frontal'}
                          subtitle={'Drop (-) / Hike (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesPelvis_Transversal}
                          title={'Transversal'}
                          subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Hip */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Hip'} />

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesHip_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesHip_Coronal}
                          title={'Frontal'}
                          subtitle={'Abduction (-) / Adduction (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesHip_Transversal}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Knee */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Knee'} />

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesKnee_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesKnee_Coronal}
                          title={'Frontal'}
                          subtitle={'Valgus (-) / Varus (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesKnee_Transversal}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Ankle */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Ankle'} />

                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesAnkle_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesAnkle_Coronal}
                          title={'Frontal'}
                          subtitle={'Eversion (-) / Inversion (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <FunctionalMovementKinematicsGraph
                          data={seriesAnkle_Transversal}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Tables */}
            <IonRow class='ion-no-padding ion-justify-content-center'>
              <IonCol size='8'>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Range of motion'} />

                  <IonCardContent>
                    <IonRow className='ion-padding-vertical full_height ion-align-items-start'>
                      <IonCol size='12' className='ion-no-padding'>
                        <table className='ORYX_ROMTable_Large'>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Left</th>
                              <th>Right</th>
                              <th>Symmetry</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='ORYX_Table_Head'>
                              <td> Pelvis</td>
                              <td>
                                {romData[0][0].toFixed(1) + '°'}
                                <br /> ± {romData[0][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {romData[0][2].toFixed(1) + '°'}
                                <br /> ± {romData[0][3].toFixed(1) + '°'}
                              </td>
                              <td>{romData[0][4].toFixed(0) + '%'}</td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td> Hip</td>
                              <td>
                                {romData[1][0].toFixed(1) + '°'}
                                <br /> ± {romData[1][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {romData[1][2].toFixed(1) + '°'}
                                <br /> ± {romData[1][3].toFixed(1) + '°'}
                              </td>
                              <td>{romData[1][4].toFixed(0) + '%'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>External rotation</td>
                              <td>{Label(Labels[2], data?.reportId.keyPerfomanceIndicators_SLS.col0[2])}</td>
                              <td>{Label(Labels[2], data?.reportId.keyPerfomanceIndicators_SLS.col1[2])}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>Internal rotation</td>
                              <td className='ion-text-center'>
                                {Label(Labels[3], data?.reportId.keyPerfomanceIndicators_SLS.col0[3])}
                              </td>
                              <td>{Label(Labels[3], data?.reportId.keyPerfomanceIndicators_SLS.col1[3])}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>Abduction</td>
                              <td>{Label(Labels[4], data?.reportId.keyPerfomanceIndicators_SLS.col0[4])}</td>
                              <td>{Label(Labels[4], data?.reportId.keyPerfomanceIndicators_SLS.col1[4])}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>Adduction</td>
                              <td className='ion-text-center'>
                                {Label(Labels[5], data?.reportId.keyPerfomanceIndicators_SLS.col0[3])}
                              </td>
                              <td>{Label(Labels[5], data?.reportId.keyPerfomanceIndicators_SLS.col1[3])}</td>
                              <td></td>
                            </tr>

                            <tr className='ORYX_Table_Head'>
                              <td>Knee</td>
                              <td>
                                {romData[2][0].toFixed(1) + '°'}
                                <br /> ± {romData[2][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {romData[2][2].toFixed(1) + '°'}
                                <br /> ± {romData[2][3].toFixed(1) + '°'}
                              </td>
                              <td>{romData[2][4].toFixed(0) + '%'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent No_Border_Bottom'>Varus / Valgus</td>
                              <td className='No_Border_Bottom'>
                                {Label(Labels[6], data?.reportId.keyPerfomanceIndicators_SLS.col0[6])}
                              </td>
                              <td className='No_Border_Bottom'>
                                {Label(Labels[6], data?.reportId.keyPerfomanceIndicators_SLS.col1[6])}
                              </td>
                              <td></td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Ankle</td>
                              <td>
                                {romData[3][0].toFixed(1) + '°'}
                                <br /> ± {romData[3][1].toFixed(1) + '°'}
                              </td>
                              <td>
                                {romData[3][2].toFixed(1) + '°'}
                                <br /> ± {romData[3][3].toFixed(1) + '°'}
                              </td>
                              <td>{romData[3][4].toFixed(0) + '%'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Large_Indent'>Early heel raise</td>
                              <td>{Label(Labels[0], data?.reportId.keyPerfomanceIndicators_SLS.col0[0])}</td>
                              <td>{Label(Labels[0], data?.reportId.keyPerfomanceIndicators_SLS.col1[0])}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
