import React, { useEffect, useState } from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonButton,
  IonBadge,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonSpinner,
  IonRippleEffect,
  IonButtons,
} from '@ionic/react';

import { chevronDownOutline, chevronUpOutline, closeCircleOutline, informationCircleOutline } from 'ionicons/icons';
import { uuid } from 'uuidv4';
import { FunctionalMovementVariabilityJointGraph } from '../Graphs';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

type VariabilityModalContentProps = {
  crp_Left: any;
  crp_Right: any;
  coordinativeVariabilityData_Left: any;
  coordinativeVariabilityData_Right: any;
  setShowMoreInfoModal: any;
  highlights_Left?: any;
  highlights_Right?: any;
  annotations?: boolean;
  annotations_Left?: any;
  annotations_Right?: any;
};

function createLegend(crpData: any): any[] {
  const crpLegend = crpData.map((entry: any) => {
    return {
      id: uuid(),
      name: entry.name,
      color: entry.color,
    };
  });

  const allStridesEntry = [
    {
      id: uuid(),
      name: 'All planes',
      color: '#4d4f5c',
    },
  ];

  return [...crpLegend, ...allStridesEntry];
}

export const VariabilityModalContent = ({
  crp_Left,
  crp_Right,
  coordinativeVariabilityData_Left,
  coordinativeVariabilityData_Right,
  setShowMoreInfoModal,
  highlights_Left,
  highlights_Right,
  annotations,
  annotations_Left,
  annotations_Right,
}: VariabilityModalContentProps) => {
  const [loading, setLoading] = useState(true);
  const [legend, setLegend] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState('All planes');
  const [showCRPData_Left, setShowCRPData_Left] = useState<any[]>([]);
  const [showCRPData_Right, setShowCRPData_Right] = useState<any[]>([]);
  const [showCoordinativeVariabilityData_Left, setShowCoordinativeVariabilityData_Left] = useState<any[]>([]);
  const [showCoordinativeVariabilityData_Right, setShowCoordinativeVariabilityData_Right] = useState<any[]>([]);
  const [options_Left, setOptions_Left] = useState<ApexOptions>({});
  const [optionsVariability_Left, setOptionsVariability_Left] = useState<ApexOptions>({});
  const [options_Right, setOptions_Right] = useState<ApexOptions>({});
  const [optionsVariability_Right, setOptionsVariability_Right] = useState<ApexOptions>({});

  // Tab change set the active tab and the data for the graphs.
  const tabChange = (name: string) => {
    localStorage.setItem('activeTab', name);
    setActiveTab(name);
    if (name === 'All planes') {
      setShowCRPData_Left(crp_Left);
      setShowCRPData_Right(crp_Right);
      setShowCoordinativeVariabilityData_Left([
        coordinativeVariabilityData_Left.find((element: any) => element.name === 'All planes'),
      ]);
      setShowCoordinativeVariabilityData_Right([
        coordinativeVariabilityData_Right.find((element: any) => element.name === 'All planes'),
      ]);
    } else {
      setShowCRPData_Left([crp_Left.find((element: any) => element.name === name)]);
      setShowCRPData_Right([crp_Right.find((element: any) => element.name === name)]);
      setShowCoordinativeVariabilityData_Left([
        coordinativeVariabilityData_Left.find((element: any) => element.name === name),
      ]);
      setShowCoordinativeVariabilityData_Right([
        coordinativeVariabilityData_Right.find((element: any) => element.name === name),
      ]);
    }
  };

  useEffect(() => {
    const activeTab = localStorage.getItem('activeTab');
    if (activeTab) {
      setActiveTab(activeTab);
    } else {
      localStorage.setItem('activeTab', 'All planes');
      setActiveTab('All planes');
    }
  });

  useEffect(() => {
    setLegend(createLegend(crp_Left));
    setShowCRPData_Left(crp_Left);
    setShowCRPData_Right(crp_Right);
    setShowCoordinativeVariabilityData_Left([
      coordinativeVariabilityData_Left.find((element: any) => element.name === 'All planes'),
    ]);
    setShowCoordinativeVariabilityData_Right([
      coordinativeVariabilityData_Right.find((element: any) => element.name === 'All planes'),
    ]);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [crp_Left]);

  useEffect(() => {
    if (annotations) {
      setOptions_Left({
        chart: {
          type: 'line',
          background: '#ffffff',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          opacity: fillOpacity,
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: 'numeric',
          labels: {
            show: false,
            style: {
              fontSize: '8px',
            },
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          min: -200,
          max: 200,
          tickAmount: 5,
          labels: {
            formatter: function (value: number) {
              return value.toFixed(0);
            },
            style: {
              fontSize: '8px',
            },
          },
        },
        subtitle: {
          text: 'Continouos Relative Phase',
          align: 'center',
          offsetY: 5,
          style: {
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },

        stroke: {
          width: [1],
          dashArray: [0],
        },
        tooltip: {
          enabled: false,
        },
        annotations: {
          xaxis: [
            {
              x: annotations_Left[1],
              borderColor: '#c2c2c2',
              label: {
                text: 'TO',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[2],
              borderColor: '#c2c2c2',
              label: {
                text: 'MSw',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[5],
              borderColor: '#c2c2c2',
              label: {
                text: 'TSw',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[3],
              borderColor: '#c2c2c2',
              label: {
                text: 'IC',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[4],
              borderColor: '#c2c2c2',
              label: {
                text: 'MSt',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[0],
              borderColor: '#c2c2c2',
              label: {
                text: 'HO',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: highlights_Left.x,
              x2: highlights_Left.x2,
              borderColor: '#c2c2c2',
              fillColor: '#ABF263',
            },
          ],
        },
      });
      setOptionsVariability_Left({
        chart: {
          background: '#ffffff',
          events: {
            legendClick: function (chartContext, seriesIndex, config) {
              if (process.env.NODE_ENV !== 'production') console.log('legendClick', seriesIndex);
            },
          },
          type: 'line',
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },

        xaxis: {
          type: 'numeric',
          labels: {
            show: false,
            style: {
              fontSize: '8px',
            },
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        yaxis: {
          min: 0,
          max: 360,
          tickAmount: 5,
          labels: {
            formatter: function (value: number) {
              const percentage = (value / 360) * 100;
              return percentage.toFixed(0) + ' %';
            },
            style: {
              fontSize: '8px',
            },
          },
        },
        subtitle: {
          text: 'Coordinative Variability',
          align: 'center',
          offsetY: 5,
          floating: true,
          style: {
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },

        stroke: {
          width: [1, 1],
          dashArray: [0, 4, 0],
        },
        tooltip: {
          enabled: false,
        },
        annotations: {
          xaxis: [
            {
              x: annotations_Left[1],
              borderColor: '#c2c2c2',
              label: {
                text: 'TO',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[2],
              borderColor: '#c2c2c2',
              label: {
                text: 'MSw',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[5],
              borderColor: '#c2c2c2',
              label: {
                text: 'TSw',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[3],
              borderColor: '#c2c2c2',
              label: {
                text: 'IC',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[4],
              borderColor: '#c2c2c2',
              label: {
                text: 'MSt',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Left[0],
              borderColor: '#c2c2c2',
              label: {
                text: 'HO',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: highlights_Left.x,
              x2: highlights_Left.x2,
              borderColor: '#c2c2c2',
              fillColor: '#ABF263',
            },
          ],
        },
      });
      setOptions_Right({
        chart: {
          type: 'line',
          background: '#ffffff',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          opacity: fillOpacity,
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: 'numeric',
          labels: {
            show: false,
            style: {
              fontSize: '8px',
            },
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          min: -200,
          max: 200,
          tickAmount: 5,
          labels: {
            formatter: function (value: number) {
              return value.toFixed(0);
            },
            style: {
              fontSize: '8px',
            },
          },
        },
        subtitle: {
          text: 'Continouos Relative Phase',
          align: 'center',
          offsetY: 5,
          style: {
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },

        stroke: {
          width: [1],
          dashArray: [0],
        },
        tooltip: {
          enabled: false,
        },
        annotations: {
          xaxis: [
            {
              x: annotations_Right[1],
              borderColor: '#c2c2c2',
              label: {
                text: 'TO',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[2],
              borderColor: '#c2c2c2',
              label: {
                text: 'MSw',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[5],
              borderColor: '#c2c2c2',
              label: {
                text: 'TSw',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[3],
              borderColor: '#c2c2c2',
              label: {
                text: 'IC',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[4],
              borderColor: '#c2c2c2',
              label: {
                text: 'MSt',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[0],
              borderColor: '#c2c2c2',
              label: {
                text: 'HO',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: highlights_Right.x,
              x2: highlights_Right.x2,
              borderColor: '#c2c2c2',
              fillColor: '#ABF263',
            },
          ],
        },
      });
      setOptionsVariability_Right({
        chart: {
          background: '#ffffff',
          events: {
            legendClick: function (chartContext, seriesIndex, config) {
              if (process.env.NODE_ENV !== 'production') console.log('legendClick', seriesIndex);
            },
          },
          type: 'line',
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },

        xaxis: {
          type: 'numeric',
          labels: {
            show: false,
            style: {
              fontSize: '8px',
            },
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        yaxis: {
          min: 0,
          max: 360,
          tickAmount: 5,
          labels: {
            formatter: function (value: number) {
              const percentage = (value / 360) * 100;
              return percentage.toFixed(0) + ' %';
            },
            style: {
              fontSize: '8px',
            },
          },
        },
        subtitle: {
          text: 'Coordinative Variability',
          align: 'center',
          offsetY: 5,
          floating: true,
          style: {
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },

        stroke: {
          width: [1, 1],
          dashArray: [0, 4, 0],
        },
        tooltip: {
          enabled: false,
        },
        annotations: {
          xaxis: [
            {
              x: annotations_Right[1],
              borderColor: '#c2c2c2',
              label: {
                text: 'TO',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[2],
              borderColor: '#c2c2c2',
              label: {
                text: 'MSw',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[5],
              borderColor: '#c2c2c2',
              label: {
                text: 'TSw',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[3],
              borderColor: '#c2c2c2',
              label: {
                text: 'IC',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[4],
              borderColor: '#c2c2c2',
              label: {
                text: 'MSt',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: annotations_Right[0],
              borderColor: '#c2c2c2',
              label: {
                text: 'HO',
                borderColor: 'transparent',
                position: 'bottom',
                orientation: 'horizontal',
                offsetY: 10,
                style: {
                  background: 'transparent',
                  fontSize: '8px',
                  fontFamily: 'Montserrat',
                },
              },
            },
            {
              x: highlights_Right.x,
              x2: highlights_Right.x2,
              borderColor: '#c2c2c2',
              fillColor: '#ABF263',
            },
          ],
        },
      });
    } else {
      setOptions_Left({
        chart: {
          type: 'line',
          background: '#ffffff',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          opacity: fillOpacity,
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: 'numeric',
          labels: {
            show: false,
            style: {
              fontSize: '8px',
            },
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          min: -200,
          max: 200,
          tickAmount: 5,
          labels: {
            formatter: function (value: number) {
              return value.toFixed(0);
            },
            style: {
              fontSize: '8px',
            },
          },
        },
        subtitle: {
          text: 'Continouos Relative Phase',
          align: 'center',
          offsetY: 5,
          style: {
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },

        stroke: {
          width: [1],
          dashArray: [0],
        },
        tooltip: {
          enabled: false,
        },
      });
      setOptionsVariability_Left({
        chart: {
          background: '#ffffff',
          events: {
            legendClick: function (chartContext, seriesIndex, config) {
              if (process.env.NODE_ENV !== 'production') console.log('legendClick', seriesIndex);
            },
          },
          type: 'line',
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },

        xaxis: {
          type: 'numeric',
          labels: {
            show: false,
            style: {
              fontSize: '8px',
            },
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        yaxis: {
          min: 0,
          max: 360,
          tickAmount: 5,
          labels: {
            formatter: function (value: number) {
              const percentage = (value / 360) * 100;
              return percentage.toFixed(0) + ' %';
            },
            style: {
              fontSize: '8px',
            },
          },
        },
        subtitle: {
          text: 'Coordinative Variability',
          align: 'center',
          offsetY: 5,
          floating: true,
          style: {
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },

        stroke: {
          width: [1, 1],
          dashArray: [0, 4, 0],
        },
        tooltip: {
          enabled: false,
        },
      });
      setOptions_Right({
        chart: {
          type: 'line',
          background: '#ffffff',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          opacity: fillOpacity,
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: 'numeric',
          labels: {
            show: false,
            style: {
              fontSize: '8px',
            },
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          min: -200,
          max: 200,
          tickAmount: 5,
          labels: {
            formatter: function (value: number) {
              return value.toFixed(0);
            },
            style: {
              fontSize: '8px',
            },
          },
        },
        subtitle: {
          text: 'Continouos Relative Phase',
          align: 'center',
          offsetY: 5,
          style: {
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },

        stroke: {
          width: [1],
          dashArray: [0],
        },
        tooltip: {
          enabled: false,
        },
      });
      setOptionsVariability_Right({
        chart: {
          background: '#ffffff',
          events: {
            legendClick: function (chartContext, seriesIndex, config) {
              if (process.env.NODE_ENV !== 'production') console.log('legendClick', seriesIndex);
            },
          },
          type: 'line',
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },

        xaxis: {
          type: 'numeric',
          labels: {
            show: false,
            style: {
              fontSize: '8px',
            },
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        yaxis: {
          min: 0,
          max: 360,
          tickAmount: 5,
          labels: {
            formatter: function (value: number) {
              const percentage = (value / 360) * 100;
              return percentage.toFixed(0) + ' %';
            },
            style: {
              fontSize: '8px',
            },
          },
        },
        subtitle: {
          text: 'Coordinative Variability',
          align: 'center',
          offsetY: 5,
          floating: true,
          style: {
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },

        stroke: {
          width: [1, 1],
          dashArray: [0, 4, 0],
        },
        tooltip: {
          enabled: false,
        },
      });
    }
  }, [annotations]);

  const fillOpacity: number[] = [];
  for (const value of Object.values(crp_Left)) {
    const Value = value as unknown as any;
    const name = Value.name;
    if (name === 'Calf (s) / Foot (s)' || name === 'Thigh (s) / Calf (s)' || name === 'Pelvis (s) / Thigh (s)') {
      fillOpacity.push(1);
    } else {
      fillOpacity.push(0.3);
    }
  }

  const cleanUp = () => {
    localStorage.setItem('activeTab', 'All planes');
    setShowMoreInfoModal({ show: false, joint: '' });
  };

  return (
    <>
      <IonGrid fixed className='Modalcontent-container'>
        <IonRow className='variabilityLegend_Row'>
          <IonCol size='11' className=''>
            <div className='variabilityLegend'>
              {legend.map((element, _) => {
                const color = element.color;
                const iconStyle = {
                  backgroundColor: color,
                };

                return (
                  <div
                    key={element.id}
                    className={
                      activeTab === element.name ? 'variabilityLegend_Entry_Selected' : 'variabilityLegend_Entry'
                    }
                    onClick={() => {
                      tabChange(element.name);
                    }}>
                    <div className='legend_dot' style={iconStyle} />
                    <p className='legend_name'>{element.name}</p>
                  </div>
                );
              })}
            </div>
          </IonCol>
          <IonCol size='1' className='ion-align-items-center'>
            <IonButtons className='ion-justify-content-center ion-align-self-center' style={{ height: '100%' }}>
              <IonButton
                slot='icon-only'
                fill='clear'
                className='ORYX_ModalButton_Close_Header'
                onClick={() => cleanUp()}>
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonButtons>
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-center ion-align-items-stretch ion-padding-vertical ion-margin-vertical'>
          {loading && <IonSpinner name='bubbles' className='Modal_Spinner' color='primary' />}
          {!loading && (
            <>
              <IonCol size='6' className=' '>
                <IonRow className='variabilityModalGraphRow'>
                  <IonCol size='12' className='ion-no-padding'>
                    <div className='variabilityJointGraphModal_Wrapper'>
                      <Chart
                        options={options_Left}
                        series={showCRPData_Left}
                        type='line'
                        height={'100%'}
                        width={'100%'}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-no-padding variabilityModalGraphRow'>
                  <IonCol size='12' className='ion-no-padding'>
                    <div className='variabilityJointGraphModal_Wrapper'>
                      <Chart
                        options={optionsVariability_Left}
                        series={showCoordinativeVariabilityData_Left}
                        type='line'
                        height={'100%'}
                        width={'100%'}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                {/* <FunctionalMovementVariabilityJointGraph
      crpData={crp_Left}
      coordinativeVariabilityData={coordinativeVariabilityData_Left}
    /> */}
              </IonCol>
              <IonCol size='6' className='ion-no-padding'>
                <IonRow className='variabilityModalGraphRow'>
                  <IonCol size='12' className='ion-no-padding'>
                    <div className='variabilityJointGraphModal_Wrapper'>
                      <Chart
                        options={options_Right}
                        series={showCRPData_Right}
                        type='line'
                        height={'100%'}
                        width={'100%'}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-no-padding variabilityModalGraphRow'>
                  <IonCol size='12' className='ion-no-padding'>
                    <div className='variabilityJointGraphModal_Wrapper'>
                      <Chart
                        options={optionsVariability_Right}
                        series={showCoordinativeVariabilityData_Right}
                        type='line'
                        height={'100%'}
                        width={'100%'}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                {/* <FunctionalMovementVariabilityJointGraph
      crpData={crp_Right}
      coordinativeVariabilityData={coordinativeVariabilityData_Right}
    /> */}
              </IonCol>
            </>
          )}
        </IonRow>
      </IonGrid>
      {/* <IonButton slot='icon-only' onClick={() => cleanUp()} fill='clear' className='ORYX_ModalButton_Close'>
        <IonIcon icon={closeCircleOutline} />
      </IonButton> */}
    </>
  );
};
