/** Component to show report metadata in a card */
import {
  IonActionSheet,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSpinner,
  IonToast,
} from '@ionic/react';
import {
  calendarOutline,
  closeOutline,
  cloudDownloadOutline,
  eyeOutline,
  menuOutline,
  trashOutline,
  warningOutline,
} from 'ionicons/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useReportContext } from '../../components/reportContext';
import { ReportInfo, ReportStatus, ReportType } from '../../model';
import Runner from '../../assets/icons/Running.png';
import Walker from '../../assets/icons/Walking.png';
import squatter from '../../assets/icons/Squat.png';
import slsquatter from '../../assets/icons/SingleLegSquat.png';
import './ReportCard.css';

// Custom Icons
import VersionOneBadgeIcon from '../../assets/icons/VersionOneBadgeIcon.svg';
import VersionTwoBadgeIcon from '../../assets/icons/VersionTwoBadgeIcon.svg';

export interface IReportCardProps {
  reportInfo: ReportInfo;
}

export const getActivity = (reportType: ReportType | undefined) => {
  switch (reportType) {
    case 1:
      return 'Walk';
    case 2:
      return 'Run';
    case 3:
      return 'Squat';
    case 4:
      return 'Single Leg Squat';
    default:
      return 'Error';
  }
};

/** Component to show report metadata in a card
 * @param reportInfo - Shows a spinner if the reportInfo status is "pending", hence still processing in the cloud
 */
export const ReportCard = ({ reportInfo }: IReportCardProps) => {
  const reportType = reportInfo.reportType;
  const success =
    (reportInfo.status === ReportStatus.SUCCESS && reportInfo.reportType === ReportType.RUN) ||
    (reportInfo.status === ReportStatus.SUCCESS && reportInfo.reportType === ReportType.WALK) ||
    (reportInfo.status === ReportStatus.SUCCESS && reportInfo.reportType === ReportType.SQUAT) ||
    (reportInfo.status === ReportStatus.SUCCESS && reportInfo.reportType === ReportType.SLSQUAT);
  const error =
    reportInfo.status === ReportStatus.ERROR ||
    reportInfo.reportType === ReportType.ERROR ||
    reportInfo.reportType === ReportType.KNEEL ||
    reportInfo.reportType === ReportType.KNEER;
  const pending = reportInfo.status === ReportStatus.PENDING;
  const activity = getActivity(reportType);
  const cardClassName = error ? 'cardError' : 'reportCard';
  // const statusLabel = reportInfo.status.charAt(0).toUpperCase() + reportInfo.status.slice(1);
  const areasOfConcern = reportInfo.areasOfConcern
    ? reportInfo.areasOfConcern.col0[0] + reportInfo.areasOfConcern.col1[0]
    : 0;
  const history = useHistory();
  const { doDelete } = useReportContext();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const reportRUN = reportInfo.reportType === ReportType.RUN;
  const reportWALK = reportInfo.reportType === ReportType.WALK;
  const reportSQUAT = reportInfo.reportType === ReportType.SQUAT;
  const reportSLSQUAT = reportInfo.reportType === ReportType.SLSQUAT;
  const [showErrorDoDelete, setShowErrorDoDelete] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [majorVersion, setMajorVersion] = useState(0);

  useEffect(() => {
    if (reportInfo.status === ReportStatus.SUCCESS) {
      setMajorVersion(reportInfo.version.col0);
    }
  }, [reportInfo.status]);

  // get the name of the person
  const indexToSlice = reportInfo.filename.lastIndexOf('-');
  const name = reportInfo.filename.slice(0, indexToSlice);

  return (
    <>
      <IonToast
        isOpen={showErrorDoDelete}
        onDidDismiss={() => setShowErrorDoDelete(false)}
        message={`No report id for given report ${reportInfo.filename}, with reportInfo reportID ${reportInfo.reportId}`}
        duration={2000}
      />
      {/* Download modal */}
      <IonModal isOpen={downloadModal} className='download_Modal' onDidDismiss={() => setDownloadModal(false)}>
        {/* <PDFDownloadLink document={<TestDocument report={report} reportInfo={reportInfo} />} fileName={`${name}.pdf`}>
          {({ blob, url, loading, error }) =>
            loading ? (
              <div className='sk-fading-circle'>
                <div className='sk-circle1 sk-circle'></div>
                <div className='sk-circle2 sk-circle'></div>
                <div className='sk-circle3 sk-circle'></div>
                <div className='sk-circle4 sk-circle'></div>
                <div className='sk-circle5 sk-circle'></div>
                <div className='sk-circle6 sk-circle'></div>
                <div className='sk-circle7 sk-circle'></div>
                <div className='sk-circle8 sk-circle'></div>
                <div className='sk-circle9 sk-circle'></div>
                <div className='sk-circle10 sk-circle'></div>
                <div className='sk-circle11 sk-circle'></div>
                <div className='sk-circle12 sk-circle'></div>
              </div>
            ) : (
              <div
                className='download_Modal_Download'
                onClick={() =>
                  setTimeout(() => {
                    setDownloadModal(false);
                  }, 1500)
                }>
                <IonIcon src={cloudDownloadOutline} className='download_Modal_DownloadIcon' />
                <p className='download_Modal_DownloadLink'>Click to download</p>
              </div>
            )
          }
        </PDFDownloadLink> */}
      </IonModal>
      <IonCard
        // routerLink={success ? `/reports/${reportInfo.id}` : '#'}
        // routerDirection='forward'
        className={cardClassName}
        button
        style={{ height: '100%' }}>
        <IonCardHeader mode='md' className='attractorCard_Header'>
          <IonGrid fixed className='ion-no-padding'>
            <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
              <IonCol size='10' className=''>
                <IonCardTitle className='attractorCard_Title'>
                  {reportInfo.reportName ? reportInfo.reportName : name}
                </IonCardTitle>
                {error && (
                  <IonCardSubtitle className='attractorCard_Subtitle' color='danger'>
                    Sorry, we couldn&#39;t process your report. Please contact support.
                  </IonCardSubtitle>
                )}
              </IonCol>

              <IonCol size='2' className='ion-justify-content-center'>
                {pending ? (
                  <div className=''>
                    <IonSpinner color='primary' name='bubbles' />
                  </div>
                ) : (
                  <IonButtons className='ion-justify-content-center'>
                    <IonButton
                      slot='icon-only'
                      fill='clear'
                      className='attractorCard_Header_Buttons'
                      onClick={() => setShowActionSheet(true)}>
                      <IonIcon icon={menuOutline} />
                    </IonButton>
                  </IonButtons>
                )}
                {(reportRUN && majorVersion != 2) || (reportWALK && majorVersion != 2) ? (
                  <IonActionSheet
                    isOpen={showActionSheet}
                    onDidDismiss={() => setShowActionSheet(false)}
                    mode='ios'
                    buttons={[
                      {
                        text: 'Open Report',
                        icon: eyeOutline,
                        handler: () => {
                          if (majorVersion == 1) {
                            history.push(`/reports/${reportInfo.id}`);
                          } else {
                            history.push(`/reportsV2/${reportInfo.id}`);
                          }
                        },
                      },
                      {
                        text: 'Generate PDF',
                        icon: cloudDownloadOutline,
                        handler: () => {
                          setDownloadModal(true);
                        },
                      },
                      {
                        text: 'Delete',
                        role: 'destructive',
                        icon: trashOutline,
                        handler: () => {
                          if (reportInfo.id) {
                            doDelete(reportInfo.id);
                          } else {
                            setShowErrorDoDelete(true);
                            if (process.env.NODE_ENV !== 'production')
                              console.log(
                                `No report id for given report ${reportInfo.filename}, with reportInfo reportID ${reportInfo.reportId}`,
                              );
                          }
                        },
                      },

                      {
                        text: 'Cancel',
                        icon: closeOutline,
                        role: 'cancel',
                        handler: () => {
                          if (process.env.NODE_ENV !== 'production') console.log('Cancel clicked');
                        },
                      },
                    ]}></IonActionSheet>
                ) : (
                  <IonActionSheet
                    isOpen={showActionSheet}
                    onDidDismiss={() => setShowActionSheet(false)}
                    mode='ios'
                    buttons={[
                      {
                        text: 'Open Report',
                        icon: eyeOutline,
                        handler: () => {
                          if (majorVersion == 1) {
                            history.push(`/reports/${reportInfo.id}`);
                          } else {
                            history.push(`/reportsV2/${reportInfo.id}`);
                          }
                        },
                      },
                      {
                        text: 'Delete',
                        role: 'destructive',
                        icon: trashOutline,
                        handler: () => {
                          if (reportInfo.id) {
                            doDelete(reportInfo.id);
                          } else {
                            setShowErrorDoDelete(true);
                            if (process.env.NODE_ENV !== 'production')
                              console.log(
                                `No report id for given report ${reportInfo.filename}, with reportInfo reportID ${reportInfo.reportId}`,
                              );
                          }
                        },
                      },

                      {
                        text: 'Cancel',
                        icon: closeOutline,
                        role: 'cancel',
                        handler: () => {
                          if (process.env.NODE_ENV !== 'production') console.log('Cancel clicked');
                        },
                      },
                    ]}></IonActionSheet>
                )}
                {/* <PDFDownloadLink
                  document={<TestDocument report={report} reportInfo={reportInfo} />}
                  fileName={`${name}.pdf`}>
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                </PDFDownloadLink> */}
                {/* </>
            )} */}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>

        <IonCardContent
          className='reportcardContent'
          onClick={() => {
            if (majorVersion == 1) {
              history.push(`/reports/${reportInfo.id}`);
            } else {
              history.push(`/reportsV2/${reportInfo.id}`);
            }
          }}>
          <IonList lines='none'>
            <IonItem>
              <IonIcon icon={calendarOutline} slot='start' />
              <IonLabel>{reportInfo.uploadTimestamp.toLocaleString()}</IonLabel>
            </IonItem>

            {reportRUN && (
              <IonItem>
                <IonAvatar slot='start'>
                  <img src={Runner} alt='' />
                </IonAvatar>
                <IonLabel>Activity: {activity}</IonLabel>
              </IonItem>
            )}

            {reportWALK && (
              <IonItem>
                <IonAvatar slot='start'>
                  <img src={Walker} alt='' />
                </IonAvatar>
                <IonLabel>Activity: {activity}</IonLabel>
              </IonItem>
            )}

            {reportSQUAT && (
              <IonItem>
                <IonAvatar slot='start'>
                  <img src={squatter} alt='' />
                </IonAvatar>
                <IonLabel>Activity: {activity}</IonLabel>
              </IonItem>
            )}

            {reportSLSQUAT && (
              <IonItem>
                <IonAvatar slot='start'>
                  <img src={slsquatter} alt='' />
                </IonAvatar>
                <IonLabel>Activity: {activity}</IonLabel>
              </IonItem>
            )}

            {areasOfConcern >= 0 && success && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                {/* <IonLabel>Areas of Concern: {areasOfConcern}</IonLabel> */}
                <IonLabel>{areasOfConcern} Area(s) of concern</IonLabel>
              </IonItem>
            )}

            {reportInfo.reportType === ReportType.KNEEL && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>Left Thigh and Calf Sensors seem to be swapped.</IonLabel>
              </IonItem>
            )}

            {reportInfo.reportType === ReportType.KNEER && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>Right Thigh and Calf Sensors seem to be swapped.</IonLabel>
              </IonItem>
            )}

            {reportInfo.reportType === ReportType.ERROR && (
              <IonItem>
                <IonIcon icon={warningOutline} slot='start' />
                <IonLabel>Data quality is poor.</IonLabel>
              </IonItem>
            )}
          </IonList>
          {reportInfo.tags[0] != '' && (
            <IonRow className='ion-justify-content-center'>
              <IonCol size='12' className='ion-text-center'>
                <>
                  {reportInfo.tags.map((tag) => (
                    <IonChip key={tag}>{tag}</IonChip>
                  ))}
                </>
              </IonCol>
            </IonRow>
          )}
        </IonCardContent>
      </IonCard>
    </>
  );
};
