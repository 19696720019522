import React from 'react';
import { IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react';
import './modalindex.css';
import IBimageMobilityPelvis from './assets/IBimageMobilityPelvis.png';
import IBimageMobilityHip from './assets/IBimageMobilityHip.png';
import IBimageMobilityKnee from './assets/IBimageMobilityKnee.png';
import IBimageMobilityAnkle from './assets/IBimageMobilityAnkle.png';
import { chevronDownOutline } from 'ionicons/icons';

// Slides
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import '@ionic/react/css/ionic-swiper.css';

export const ModalMobilityStatus: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Mobility status</IonCardTitle>
        </div>
      </IonCardHeader>

      <Swiper modules={[Pagination]} pagination={true} initialSlide={0} speed={400} direction='vertical'>
        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol>
                <div>
                  The way we move while walking is remarkably similar at a self-selected speed. The joints of the lower
                  limbs all move within a certain bandwidth when healthy. When a joint its range of motion (ROM) starts
                  to decrease or increase, this typically indicates the existence of problems in or around that area.
                  <br></br>
                  <br></br>
                  If a joint moves outside the typical healthy bandwidth, the flag will turn from green to yellow or
                  red. The meaning of this colors:
                  <br></br>
                  <ul>
                    <li>Green: within healthy limits</li>
                    <li>Yellow: caution</li>
                    <li>Red: reason for concern</li>
                  </ul>
                  <br></br>
                  <br></br>
                  For more information on the exact healthy bandwidths, please check the information buttons of each
                  joint in Kinematics screen (at the bottom of your screen).
                  <br></br>
                  <br></br>
                  N.B.: It is tempting to consider gait healthy in case all flags are green. However, please remember
                  that the function of these flags is solely as a quick indicator! In many cases, small deviations can
                  cause big problems while the ROM of all joints can be perfectly fine.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='5' size-xl='5'>
                <div>
                  <IonImg src={IBimageMobilityPelvis} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <h1>Pelvis</h1>
                <div>
                  Because many muscles are attached to the pelvis, which can produce force in numerous directions, it
                  works as a giant lever. Muscles can affect greatly each other by causing subtle pelvic movements. In
                  other words, small changes can result in large differences elsewhere. From that perspective the pelvic
                  area might be the most important link within the chain. Therefor, its movement should be analyzed and
                  trained with great attention.
                  <br></br>
                  <br></br>
                  In the sagittal plane, the pelvis is typically maintained in anterior pelvic tilt throughout the full
                  gait cycle. The total excursion of this movement is relatively small, approximately 5–7 degrees. To
                  much ROM (typically 10&deg; or more) indicates poor pelvic stability, typically due to poor abdominal
                  and or hamstring strength. A low ROM ( &lt; 4&deg;) is typically caused by (or a combination of) poor
                  mobility, tight hamstrings, weak back muscles. People showing a low pelvic ROM often report lower back
                  pains.
                  <br></br>
                  <br></br>
                  In the frontal plane, ROM is between 6 – 10&deg; at self selected speed. As frontal plane movements
                  are fluctuations for slight movement adjustments, ROM should not be too low. Too much ROM indicates
                  stability issues (also see pelvic obliquity and hip abduction).
                  <br></br>
                  <br></br>
                  In transverse plane, anterior and posterior rotation should not be much more than 4&deg;, limiting the
                  combined ROM to approximately 8 – 10&deg;.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='5' size-xl='5'>
                <div>
                  <IonImg src={IBimageMobilityHip} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <h1>Hip</h1>
                <div>
                  The hip is characterized by its many degrees of freedom. Its mobility is enormous (120&deg; of
                  flexion, 13-15&deg; extension, 20&deg; adduction, 40-60&deg; abduction, 13-40&deg; internal rotation
                  and 36-60&deg; external rotation). Mobility in gait however is restricted.
                  <br></br>
                  <br></br>
                  Maximum hip extension in the sagittal plane is 13-15&deg;. Maximum hip flexion prior to Initial
                  Contact is 25-30&deg;, totaling hip ROM to approximately 40&deg;.
                  <br></br>
                  <br></br>
                  In the frontal plane, abduction should be limited to 15&deg;. Maximum abduction is reached at
                  midstance. The hip should be in approximately 10&deg; adduction at initial contact and 5&deg; at
                  initial swing.
                  <br></br>
                  <br></br>
                  The sum of internal and external rotation largely depends on pelvis rotation but is typically does not
                  exceed 10-15&deg;, with its peak at the end of pre-swing.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='5' size-xl='5'>
                <div>
                  <IonImg src={IBimageMobilityKnee} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <h1>Knee</h1>
                <div>
                  When the knee is bend, is can conduct other movements than only flexion and extension. The lower leg
                  can rotate both medial and lateral, causing rotations within the knee (max 30&deg; medial and 45&deg;
                  lateral rotation when the knee is bend 90&deg;). When extending the knee, these movements will be
                  restricted; due to the architecture of the femoral epicondyle the ligaments will be tensioned,
                  fixating the knee joint in extended position.
                  <br></br>
                  <br></br>
                  In the swing phase, the knee should bend 60&deg; or more (see also: knee flexion midswing). In stance
                  phase the should move within a bandwidth of 12-27&deg; (see also: knee flexion midstance).
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='5' size-xl='5'>
                <div>
                  <IonImg src={IBimageMobilityAnkle} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <h1>Ankle</h1>
                <div>
                  The ankle (or tibiotalar joint) constitutes the junction of the lower leg and foot, which can mainly
                  be distinguished in the talocrural joint and subtalar joint.
                  <br></br>
                  <br></br>
                  The talocrural can not only hinge, but also roll and slide. The pivot point is therefor not fixed but
                  is approximately at the height of the malleolus. It enables dorsal and plantar flexion. In gait the
                  ankle reaches typically 10-15&deg; of dorsal flexion and 15-20&deg; of plantar flexion.
                  <br></br>
                  <br></br>
                  The subtalar joint (also talocalcaneal joint) allows inversion and eversion of the foot. In gait the
                  ROM of this motion is typically 15&deg;.
                  <br></br>
                  <br></br>
                  Due to the complex architecture of the foot, it is very difficult to measure inversion and eversion
                  adequately. The shape of the foot can alter due to pronation and supination, which influences the
                  measurement of the ankle slightly. No current technology can separate subtalar and tibiotalar
                  movement. Therefore, analysis of eversion and inversion should be treated very carefully.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
