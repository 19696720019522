import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonModal, IonRow } from '@ionic/react';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { ORYXSmallCardHeader } from '../../../../components/componentHeaders';

type ScissorsGraphCardProps = {
  series: any;
  title: string;
  x: string;
  x2: string;
  colors: string[];
  subtitle?: string;
  zoom: boolean;
  info: boolean;
  zoomModalContent?: React.ReactNode;
  infoModalContent?: React.ReactNode;
};

export const ScissorsGraphCard = ({
  series,
  title,
  x,
  x2,
  colors,
  subtitle,
  zoomModalContent,
  zoom,
  info,
  infoModalContent,
}: ScissorsGraphCardProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');

  const [showZoomModal, setShowZoomModal] = useState({
    show: false,
  });
  const [showInfoModal, setShowInfoModal] = useState({
    show: false,
  });

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    // title: {
    //   text: title,
    //   align: 'center',
    //   style: {
    //     fontSize: '14px',
    //     fontWeight: 'bold',
    //     fontFamily: 'Montserrat',
    //     color: '#2066FA',
    //   },
    // },

    stroke: {
      width: [1, 1],
      dashArray: [4, 0],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      offsetY: 10,
    },
    colors: colors,
    annotations: {
      xaxis: [
        {
          x: x,
          x2: x2,
          borderColor: '#00E396',
          fillColor: '#ABF263',
        },
      ],
    },
    markers: {
      size: [0, 0, 2],
      strokeWidth: 0,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <>
      <IonModal
        isOpen={showZoomModal.show}
        className='ORYX_Modal_Large'
        onDidDismiss={() => setShowZoomModal({ show: false })}>
        {zoomModalContent}
      </IonModal>
      <IonModal
        isOpen={showInfoModal.show}
        className='ORYX_Modal_Large'
        onDidDismiss={() => setShowInfoModal({ show: false })}>
        {infoModalContent}
      </IonModal>
      <IonCard className='ORYX_GraphCard'>
        <ORYXSmallCardHeader
          title={title}
          subtitle={subtitle}
          info={false}
          zoom={false}
          setShowZoom={setShowZoomModal}
          setShowInfo={setShowInfoModal}
        />
        <IonCardContent className='' style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
          <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Chart
              options={options}
              series={series}
              type='line'
              width='100%'
              height={windowSize == 'small' ? 125 : 175}
            />
          </div>
        </IonCardContent>
      </IonCard>
    </>
  );
};
